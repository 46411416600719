import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiService } from "../../services/apiService";
import { Helmet } from "react-helmet";

const BlogPost = () => {
  const { heading } = useParams();
  const [blogPost, setBlogPost] = useState(null);

  const fetchBlogPost = async () => {
    try {
      const decodedHeading = decodeURIComponent(heading); // Decode first
      const safeHeading = encodeURIComponent(decodedHeading); // Re-encode for safety
      const response = await ApiService.getOneBlog(safeHeading);
      const fetchedBlog = response.data.data;

      setBlogPost(fetchedBlog);
    } catch (error) {
      console.error("Failed to fetch the blog post", error);
    }
  };

  const someContentText = blogPost?.content.slice(0, 150);

  useEffect(() => {
    fetchBlogPost();
  }, [heading]);

  if (!blogPost) {
    return <p>Loading...</p>;
  }

  const anchorStyle = {
    color: "blue",
    textDecoration: "underline",
  };

  const styledContent = blogPost.content.replace(
    /<a /g,
    `<a style="${Object.keys(anchorStyle)
      .map((key) => `${key}: ${anchorStyle[key]};`)
      .join(" ")}" target="_blank"`
  );

  return (
    <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-2 pb-6 font-FiraSans">
      <Helmet>
        <title>{blogPost.heading}</title>
        <meta name="description" content={someContentText} />
      </Helmet>

      <h1 className="font-bold text-center text-4xl lg:text-2xl md:text-2xl sm:text-xl xs:text-xl">
        {blogPost.heading}
      </h1>
      <div className="mt-4">
        <p
          className="font-FiraSans"
          dangerouslySetInnerHTML={{ __html: styledContent }}
        />
      </div>
    </div>
  );
};

export default BlogPost;
