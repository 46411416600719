import React from "react";
import { FiPlus, FiMinus } from "react-icons/fi";

const accordion = ({ title, content, isOpen, onToggle }) => {
  return (
    <div className="mb-2">
      <button
        onClick={onToggle}
        className="w-full px-4 sm:px-2 xs:px-2 py-2 font-FiraSans text-start font-medium rounded-t-md sm:font-medium xs:font-medium flex justify-between items-center text-[#f7f7f7] bg-[#6688d6]"
      >
        <h6 className="text-lg">{title}</h6>
        {isOpen ? <FiMinus size={24} /> : <FiPlus size={24} />}
      </button>
      {isOpen && (
        <div className="text-base px-4 py-3 font-FiraSans font-medium flex gap-2 text-[#f7f7f7] bg-[#577cd3]">
          {content}
        </div>
      )}
    </div>
  );
};

export default accordion;
