import DataTable from "react-data-table-component";

const Table = ({
  column,
  data,
  title = "Order History",
  showPagination = true,
  ...props
}) => {
  const customStyles = {
    table: {
      style: {
        borderRadius: "5px",
      },
    },
    header: {
      style: {
        minHeight: "56px",
        backgroundColor: "#FCBB45",
        color: "#001F68",
        textAlign: "center",
      },
    },
    head: {
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
    },
    headCells: {
      style: {
        borderWidth: "1px",
        borderColor: "#ddd",
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
    },
    cells: {
      style: {
        borderWidth: "1px",
        borderColor: "#ddd",
      },
    },
    pagination: {
      style: {
        display: "flex",
        justifyContent: "center",
        padding: "10px 0",
      },
    },
  };

  return (
    <DataTable
      customStyles={customStyles}
      columns={column}
      data={data}
      title={title}
      responsive={true}
      pagination={showPagination}
      paginationPerPage={15}
      paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50]}
      {...props}
    />
  );
};

export default Table;
