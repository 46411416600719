import React, { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { ApiService } from "../../../../services/apiService";

const NoticeDisplay = () => {
  const [notices, setNotices] = useState([]);
  const [closeNotice, setCloseNotice] = useState({});
  const [loading, setLoading] = useState(true);

  // Fetch notices from the backend
  const fetchNotices = async () => {
    try {
      const response = await ApiService.dashboardNotice();
      // Filter notices where is_active is true
      const activeNotices = response.data.data.filter(
        (notice) => notice.is_active === true
      );
      setNotices(activeNotices);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching notices:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotices();
  }, []);

  // Auto close notice after 30 seconds
  useEffect(() => {
    notices.forEach((_, index) => {
      if (!closeNotice[index]) {
        const timer = setTimeout(() => {
          setCloseNotice((prev) => ({ ...prev, [index]: true }));
        }, 30000); // 30 seconds

        // Cleanup the timer when component unmounts or when notice closes
        return () => clearTimeout(timer);
      }
    });
  }, [notices, closeNotice]);

  // Close the notice manually using the "X" button
  const handleClose = (index) => {
    setCloseNotice((prev) => ({ ...prev, [index]: true }));
  };

  // Check if any notices are still open
  const hasOpenNotices = () => {
    return notices.some((_, index) => !closeNotice[index]);
  };

  // Render notice content with anchor styles
  const renderNoticeContent = (content, index) => {
    const anchorStyle = {
      color: "blue",
      textDecoration: "underline",
    };

    // Apply inline styling to anchor tags in the content
    const styledContent = content.replace(
      /<a /g,
      `<a style="${Object.keys(anchorStyle)
        .map((key) => `${key}: ${anchorStyle[key]};`)
        .join(" ")}" target="_blank"`
    );

    // If the notice is closed, return null to hide it
    if (closeNotice[index]) return null;

    return (
      <div key={index} className="relative flex flex-col items-center">
        {/* Close button (X) */}
        <button
          className="absolute top-0 right-0 text-red-700 mb-4"
          onClick={() => handleClose(index)}
        >
          <FiX />
        </button>

        {/* Notice content */}
        <p
          className="font-FiraSans p-2"
          dangerouslySetInnerHTML={{ __html: styledContent }}
        />
      </div>
    );
  };

  return (
    <>
      {/* Conditionally render the container only if there are open notices */}
      {!loading && hasOpenNotices() && (
        <div className="bg-white p-4 shadow-md max-h-fit pt-2 rounded-sm pb-6 font-FiraSans">
          {notices.map((notice, index) =>
            renderNoticeContent(notice.notice, index)
          )}
        </div>
      )}
    </>
  );
};

export default NoticeDisplay;
