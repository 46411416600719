import React, { useEffect } from "react";
import Button from "../../components/shared/Button";
import TwoRowForm from "../../components/shared/TwoRowForm";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import {
  ErrorModal,
  InfoModal,
  SuccessModal,
} from "../../components/shared/ToastModals";
import { useOutletContext } from "react-router-dom";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";

const FbPostLikes = () => {
  const dispatch = useDispatch();
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    link: [],
    quantity: [],
    likeType: "",
    service_type: window.location.pathname,
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { link, quantity, likeType } = data;
  const onChange = (e) => {
    const updated = { [e.target.name]: [e.target.value] };
    setData({ ...data, ...updated });
    const value = { ...data, ...updated };
    const { quantity } = value;
    setQuantity(quantity);
  };
  const onChangeTwo = (e) => {
    const updated = { [e.target.name]: e.target.value };
    setData({ ...data, ...updated });
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    if (quantity < 100) {
      setInfoModalVisible(true);
      setMessage(responseMessages.minimumQuantityOf100);
      return;
    }
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(data));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setData({
          service_type: window.location.pathname,
          quantity: [],
          link: [],
          likeType: "",
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };

  return (
    <>
      <div className=" font-Firasans py-8 sm:py-4 xs:py-2 px-20 lg:px-6 md:px-4 sm:px-3 xs:px-2 flex flex-col gap-6 sm:gap-3 xs:gap-2 ">
        {/**header*/}
        <div className="xs:text-lg sm:text-lg md:text-xl text-3xl text-center">
          Want More Likes?
        </div>
        <h2 className="font-bold xs:text-[1.5rem] sm:text-[2rem] md:text-[2.5rem] text-[3rem] text-center ">
          Add Facebook Post Likes
        </h2>
        {/*info Banner*/}
        <div className="px-16 lg:px-4 md:px-2 sm:px-2 xs:px-1">
          <div className="p-3 bg-[#c7e5f3] text-[#31708f] border-2 border-[#9acfea] rounded ">
            <p>Please. Add Full Post Link!</p>
            {/*<p>
              Example :
              <span>
                {" "}
                https://www.facebook.com/rihanna/photos/a.207477.1009/1015/?type=1&theater
              </span>
            </p>*/}
            <p>The Order will start up to 24 hours</p>
          </div>
        </div>
        {/**form section */}
        <div className="w-full">
          <TwoRowForm
            labelOne="Facebook post Url"
            placeholderOne="Enter the URL to your Facebook post"
            onChangeOne={onChange}
            valueOne={link}
            nameOne="link"
            labelTwo="Likes"
            placeholderTwo="Enter the amount of likes you want(250 minimum)"
            onChangeTwo={onChange}
            valueTwo={quantity}
            nameTwo="quantity"
          />
          <div className="flex flex-col px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1">
            <label className="font-FiraSans text-lg">Likes Type:</label>
            <select
              id="likeType"
              name="likeType"
              onChange={onChangeTwo}
              value={likeType}
              className="py-2 px-2 font-FiraSans outline-blue-800 shadow-md ring-transparent border-2 border-gray-400 rounded-md"
            >
              <option>Select type of like</option>
              <option value="regular likes">Regular Likes</option>
              <option value="emoji love">Emoji Love</option>
              <option value="emoji smile">Emoji Smile</option>
              <option value="emoji wow">Emoji Wow</option>
            </select>
          </div>
        </div>
        <div className="text-center">
          <Button
            title={loading ? "Sending..." : "Add likes"}
            size="md"
            onClick={onSubmit}
            disabled={
              !isAuthenticated || loading || !likeType || !quantity || !link
            }
          />
          {/* Success Modal */}
          <SuccessModal
            visible={successModalVisible}
            onClose={() => setSuccessModalVisible(false)}
            message={message}
          />
          {/* Error Modal */}
          <ErrorModal
            visible={errorModalVisible}
            onClose={() => setErrorModalVisible(false)}
            message={message}
          />{" "}
          {/* Info Modal */}
          <InfoModal
            visible={infoModalVisible}
            onClose={() => setInfoModalVisible(false)}
            message={message}
          />
        </div>
      </div>
    </>
  );
};

export default FbPostLikes;
