import React, { useEffect, useState } from "react";
import Button from "../../components/shared/Button";
import { Link, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import {
  clearMedia,
  loadInstagramMedia,
} from "../../store/Slice/instagramServiceSlice";
import { ToastContainer, toast } from "react-toastify";
import FormAndButtonTwo from "../../components/shared/FormAndButtonTwo";
import {
  ErrorModal,
  InfoModal,
  SuccessModal,
} from "../../components/shared/ToastModals";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";

const IgLikesFromFollowers = () => {
  const dispatch = useDispatch();
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { media } = useSelector((state) => state.instagramService);
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    link: [],
    quantity: [],
    service_type: window.location.pathname,
  });
  useEffect(() => {
    return () => {
      dispatch(clearMedia());
    };
  }, [dispatch]);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const { quantity } = orderDetails;

  const onChange = (e) => {
    const updated = { [e.target.name]: [e.target.value] };
    setOrderDetails({ ...orderDetails, ...updated });
    const value = { ...orderDetails, ...updated };
    const { quantity } = value;
    setQuantity(quantity);
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onClick = () => {
    dispatch(loadInstagramMedia(username));
  };

  const usernameOnChange = (e) => {
    setUsername(e.target.value);
  };

  const onMediaClick = (mediaLink) => {
    setOrderDetails({ ...orderDetails, link: [mediaLink] });
  };

  const onSubmit = async (e) => {
    console.log(orderDetails);
    e.preventDefault();
    if (!media) {
      toast.info("Please load media first.");
      return;
    }
    if (quantity < 100) {
      setInfoModalVisible(true);
      setMessage(responseMessages.minimumQuantityOf100);
      return;
    }
    try {
      setIsLoading(true);
      const response = await dispatch(orderSubmission(orderDetails));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        await dispatch(clearMedia());
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        await dispatch(updateUserDetails());
        setUsername("");
        setOrderDetails({
          link: [],
          quantity: [],
          service_type: window.location.pathname,
        });
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setQuantity(0);
      setIsLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };

  return (
    <>
      <div className=" flex flex-col gap-[1rem] lg:gap-[2rem] xl:gap-[2.5rem] mt-4 mb-4 px-20 lg:px-6 md:px-4 sm:px-3 xs:px-2 py-6 sm:py-4 xs:py-2">
        <div className="flex flex-col items-center">
          {" "}
          {/**header starts */}
          <div className="text-black text-center xs:text-base sm:text-lg md:text-xl text-2xl">
            Want More Real Likes From Your Followers?
          </div>
          <h2 className="text-black text-center font-bold xs:text-[1rem] sm:text-[1.5rem] md:text-[1.7rem] text-[2.5rem] ">
            Add Instagram Real Likes From Your Followers
          </h2>
        </div>
        {/*header end here*/}

        {/*Banner starts here */}
        <div className="p-3 bg-[#c7e5f3] text-[#31708f] border-2 border-[#9acfea] rounded w-full ">
          <p className="sm:text-sm xs:text-xs pb-4 ">
            <span className="font-bold">
              A special service that does not exist anywhere else
            </span>
            <br /> 100% super real Instagram likes from accounts that have
            followed you
          </p>

          <p className="sm:text-sm xs:text-xs pb-4">
            {" "}
            <span className="font-bold"> Very important:</span> only if you
            ordered real followers from us - the comments will be from those
            followers
            <br /> To order real followers click here :
            <Link to="/real...." className="text-blue-800 font-semibold">
              {" "}
              Real USA Followers{" "}
            </Link>
            <br /> The minimum is 50, Maximum is 10000
            <br /> Live chat is available 24x7 for assistance
          </p>
        </div>

        {/*form section */}
        <FormAndButtonTwo
          placeholder="Enter your Instagram Username"
          label="Instagram Username"
          onChange={usernameOnChange}
          value={username}
          onClick={onClick}
          mediaUrl={media}
          username={username}
          onMediaClick={onMediaClick} // Pass onMediaClick callback
        />

        <div className="flex flex-col px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-2">
          <label className="font-FiraSans text-lg">Package:</label>
          <select
            id="quantity"
            name="quantity"
            onChange={onChange}
            value={quantity}
            className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 rounded-md"
          >
            <option value="" disabled selected>
              Select number of like from your followers
            </option>
            <option value="100">100 real likes</option>
            <option value="150">150 real likes</option>
            <option value="200">200 real likes</option>
            <option value="250">250 real likes</option>
            <option value="300">300 real likes</option>
            <option value="500">500 real likes</option>
            <option value="750">750 real likes</option>
            <option value="1000">1000 real likes</option>
          </select>
        </div>

        <div className=" text-center px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 mt-5">
          <Button
            title={
              isLoading
                ? "Adding real followers like..."
                : "Add Real Likes From Your Followers"
            }
            size="md"
            disabled={isLoading || !isAuthenticated}
            onClick={onSubmit}
          />
        </div>
        {/* Success Modal */}
        <SuccessModal
          visible={successModalVisible}
          onClose={() => setSuccessModalVisible(false)}
          message={message}
        />
        {/* Error Modal */}
        <ErrorModal
          visible={errorModalVisible}
          onClose={() => setErrorModalVisible(false)}
          message={message}
        />
        {/* Info Modal */}
        <InfoModal
          visible={infoModalVisible}
          onClose={() => setInfoModalVisible(false)}
          message={message}
        />
        <ToastContainer />
      </div>
    </>
  );
};

export default IgLikesFromFollowers;
