import React from "react";
import { Menu, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";

export const renderDropdownItems = (items, clickedItem) => {
  return items.map((item) => (
    <Menu.Item key={item.path} onClick={clickedItem} style={{ color: "white" }}>
      <Link to={item.path}>{item.name}</Link>
    </Menu.Item>
  ));
};

export const createDropdown = (category, clickedItem) => {
  const dropdownMenu = (
    <Menu
      onClick={clickedItem}
      style={{
        backgroundColor: "#fc8a24",
        position: "relative",
      }}
    >
      {renderDropdownItems(category.items, clickedItem)}
    </Menu>
  );

  return (
    <Dropdown overlay={dropdownMenu} trigger={["hover"]} placement="bottomLeft">
      <div className={`group font-FiraSans`}>
        <div className="flex items-center cursor-pointer p-1 hover:bg-[#fc8a24]">
          <p className="" style={{ whiteSpace: "nowrap" }}>
            {category.categoryName}
          </p>
          <IoMdArrowDropdown />
        </div>
      </div>
    </Dropdown>
  );
};
