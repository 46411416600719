import React, { useEffect } from "react";
import Button from "../../components/shared/Button";
import { useState } from "react";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMedia,
  loadInstagramMedia,
} from "../../store/Slice/instagramServiceSlice";
import { useOutletContext } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormAndButtonTwo from "../../components/shared/FormAndButtonTwo";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import { ErrorModal, SuccessModal } from "../../components/shared/ToastModals";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";
const IgReelsOrganicComments = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { media } = useSelector((state) => state.instagramService);
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [orderDetails, setOrderDetails] = useState({
    link: [],
    quantity: [],
    peopleCategory: "",
    comment: "",
    service_type: window.location.pathname,
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  useEffect(() => {
    return () => {
      dispatch(clearMedia());
    };
  }, [dispatch]);
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const { comment, peopleCategory } = orderDetails;

  const onChange = (e) => {
    const updated = { [e.target.name]: e.target.value };
    setOrderDetails({ ...orderDetails, ...updated });
    const value = { ...orderDetails, ...updated };
    const { comment } = value;
    setQuantity(comment / 10);
    setOrderDetails((prevData) => ({
      ...prevData,
      quantity: [comment],
    }));
  };
  const onClick = () => {
    dispatch(loadInstagramMedia(username));
  };

  const usernameOnChange = (e) => {
    setUsername(e.target.value);
  };

  const onMediaClick = (mediaLink) => {
    console.log(mediaLink);
    setOrderDetails({ ...orderDetails, link: mediaLink });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!media) {
      toast.info("Please load media first.");
      return;
    }
    try {
      setIsLoading(true);
      const response = await dispatch(orderSubmission(orderDetails));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        await dispatch(clearMedia());
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setUsername("");
        setOrderDetails({
          link: [],
          comment: "",
          peopleCategory: "",
          service_type: window.location.pathname,
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === '"link" is not allowed to be empty') {
          setMessage(responseMessages.linkClickMessage);
        } else if (response.payload.message === "Incorrect service ID") {
          setMessage(responseMessages.unavailableServiceDuetoId);
        } else if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setQuantity(0);
      setIsLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };
  return (
    <div className="font-FiraSans py-8 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want More Reels Real Comments?
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Add Reels Real Comments
      </h3>
      <div className="flex mb-7 flex-col gap-4 p-3 bg-blue-200 text-blue-500 rounded-lg">
        <p className="text-sm sm:text-xs xs:text-xs">
          Real Instagram comment like never before. 100% REAL!!! NO BOTS
        </p>
        <p className="text-sm sm:text-xs xs:text-xs">
          All comments are related to posts and from Real and active accounts.
        </p>
        <p className="text-sm sm:text-xs xs:text-xs">
          None of its kind in the market.
        </p>
        <h4 className="text-sm sm:text-xs xs:text-xs">
          ENTER YOUR USERNAME AND SELECT THE NUMBER OF COMMENTS IN THE DROP DOWN
          MENU
        </h4>
      </div>
      <FormAndButtonTwo
        placeholder="Enter your Instagram Username"
        label="Instagram Username"
        onChange={usernameOnChange}
        value={username}
        onClick={onClick}
        mediaUrl={media}
        username={username}
        onMediaClick={onMediaClick} // Pass onMediaClick callback
      />
      <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pb-6 mt-5">
        <label className="block">Package:</label>
        <select
          name="comment"
          value={comment}
          onChange={onChange}
          className="w-2/3 sm:w-full xs:w-full px-2 py-3 rounded-lg shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
        >
          <option value="" disabled selected>
            Select number of comments
          </option>
          <option value="10">10 Real Comments</option>
          <option value="15">15 Real Comments</option>
          <option value="20">20 Real Comments</option>
          <option value="25">25 Real Comments</option>
          <option value="30">30 Real Comments</option>
          <option value="50">50 Real Comments</option>
          <option value="75">75 Real Comments</option>
          <option value="100">100 Real Comments</option>
        </select>
      </div>
      <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1">
        <label className="block">Female / Male / African American</label>
        <select
          id="peopleCategory"
          name="peopleCategory"
          value={peopleCategory}
          onChange={onChange}
          className="w-2/3 px-2 sm:w-full xs:w-full py-3 rounded-lg shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
        >
          <option value="" disabled selected>
            Select Category
          </option>
          <option value="Mix">Mix</option>
          <option value="Female">Female</option>
          <option value="Male">Male</option>
          <option value="African American">African American</option>
        </select>
      </div>
      *
      <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-10 flex justify-center">
        <Button
          type="submit"
          title={isLoading ? "Sending..." : "Add Comments"}
          variant="primary"
          size="sm"
          onClick={onSubmit}
          disabled={
            !comment || !peopleCategory || isLoading || !isAuthenticated
          }
        />
      </div>
      <ToastContainer />
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />
    </div>
  );
};

export default IgReelsOrganicComments;
