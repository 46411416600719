import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

export const BalanceBeforeAndAfter = ({ quantity }) => {
  const [afterPurchase, setAfterPurchase] = useState();
  const displayUser = useSelector((state) => state.login.user);
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const location = useLocation();
  const service = location.pathname;
  const Price = displayUser?.price[service] || 0;
  const Amount = Price * (quantity / 100);
  const totalAmount = quantity ? parseFloat(Amount.toFixed(3)) : 0;
  useEffect(() => {
    if (displayUser && totalAmount !== undefined) {
      let bal = parseFloat(
        (displayUser.wallet_balance - totalAmount).toFixed(3)
      );
      setAfterPurchase(bal);
    }
  }, [displayUser, service, Price, totalAmount, afterPurchase]);
  return (
    <>
      {isAuthenticated && (
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 py-8 xs:px-1">
          <div className="bg-[#f0f0f0] flex flex-col w-1/3 p-4 rounded sm:w-full xs:w-full">
            <p className="inline-flex font-semibold">
              Price : ${totalAmount} <span className="text-red-700"></span>
            </p>
            <p className="inline-flex font-semibold mt-3">
              Balance after Purchase : ${afterPurchase}
            </p>
            {afterPurchase < 0 && (
              <p className="text-red-700 mt-1 italic">
                You cannot make this order, kindly credit your account first.
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export const RateAndPrice = () => {
  const displayUser = useSelector((state) => state.login.user);
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const location = useLocation();
  const service = location.pathname;
  const Price = displayUser?.price[service] || 0;

  return (
    <>
      {isAuthenticated && (
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 py-8 xs:px-1">
          <div className="bg-[#f0f0f0] flex gap-3 w-max p-4 rounded-t-md">
            <p className="font-semibold inline-flex flex-col items-center">
              Account Balance <span>${displayUser.wallet_balance}</span>
            </p>
            <p className="border-r-2 border-l-2 px-3 inline-flex text-[#f49a39] flex-col items-center font-semibold">
              Rate per <span className="text-black">100</span>
            </p>
            <p className="px-3 inline-flex flex-col items-center font-semibold">
              Price <span>${Price}</span>
            </p>
          </div>
          <p className="font-semibold px-[0.7rem]">
            Insufficient balance?{" "}
            <Link to="/dashboard" className="text-blue-600">
              Click here to deposit
            </Link>
          </p>
        </div>
      )}
    </>
  );
};

export const BalanceBeforeAndAfterTwo = ({ quantity }) => {
  const [afterPurchase, setAfterPurchase] = useState();
  const displayUser = useSelector((state) => state.login.user);
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const location = useLocation();
  const service = location.pathname;
  const Price = displayUser?.price[service] || 0;
  const Amount = Price * quantity;
  const totalAmount = quantity ? parseFloat(Amount.toFixed(3)) : 0;
  useEffect(() => {
    if (displayUser && totalAmount !== undefined) {
      let bal = parseFloat(
        (displayUser.wallet_balance - totalAmount).toFixed(3)
      );
      setAfterPurchase(bal);
    }
  }, [displayUser, service, Price, totalAmount, afterPurchase]);
  return (
    <>
      {isAuthenticated && (
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 py-8 xs:px-1">
          <div className="bg-[#f0f0f0] flex flex-col w-1/3 p-4 rounded sm:w-full xs:w-full">
            <p className="inline-flex font-semibold">
              Price : ${totalAmount} <span className="text-red-700"></span>
            </p>
            <p className="inline-flex font-semibold mt-3">
              Balance after Purchase : ${afterPurchase}
            </p>
            {afterPurchase < 0 && (
              <p className="text-red-700 mt-1 italic">
                You cannot make this order, kindly credit your account first.
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export const RateAndPriceTwo = () => {
  const displayUser = useSelector((state) => state.login.user);
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const location = useLocation();
  const service = location.pathname;
  const Price = displayUser?.price[service] || 0;

  return (
    <>
      {isAuthenticated && (
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 py-8 xs:px-1">
          <div className="bg-[#f0f0f0] flex gap-3 w-max p-4 rounded-t-md">
            <p className="font-semibold inline-flex flex-col items-center">
              Account Balance <span>${displayUser.wallet_balance}</span>
            </p>

            <p className="px-3 inline-flex flex-col items-center font-semibold">
              Price <span>${Price}</span>
            </p>
          </div>
          <p className="font-semibold px-[0.7rem]">
            Insufficient balance?{" "}
            <Link to="/dashboard" className="text-blue-400">
              Click here to deposit
            </Link>
          </p>
        </div>
      )}
    </>
  );
};
