import React, { useEffect, useState } from "react";
import Button from "../../components/shared/Button";
import FormAndButtonTwo from "../../components/shared/FormAndButtonTwo";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import {
  clearMedia,
  loadInstagramMedia,
} from "../../store/Slice/instagramServiceSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { ErrorModal, SuccessModal } from "../../components/shared/ToastModals";
import responseMessages from "../../utils/responseMessages";
import { useOutletContext } from "react-router-dom";
import { useAccordionContext } from "../../SEO/AccordionContext";

const RealEngagement = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { media } = useSelector((state) => state.instagramService);
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [orderDetails, setOrderDetails] = useState({
    link: [],
    quantity: [],
    engagementPackage: "",
    service_type: window.location.pathname,
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const { engagementPackage } = orderDetails;

  const onChange = (e) => {
    const updated = { [e.target.name]: e.target.value };
    setOrderDetails({ ...orderDetails, ...updated });
    const value = { ...orderDetails, ...updated };
    const { engagementPackage } = value;
    if (engagementPackage === "starter") {
      setQuantity(1.7);
      setOrderDetails((prevData) => ({
        ...prevData,
        quantity: [17],
      }));
    } else if (engagementPackage === "regular") {
      setQuantity(3);
      setOrderDetails((prevData) => ({
        ...prevData,
        quantity: [30],
      }));
    } else if (engagementPackage === "premium") {
      setQuantity(4.5);
      setOrderDetails((prevData) => ({
        ...prevData,
        quantity: [45],
      }));
    } else if (engagementPackage === "advanced") {
      setQuantity(6.8);
      setOrderDetails((prevData) => ({
        ...prevData,
        quantity: [68],
      }));
    } else if (engagementPackage === "micro") {
      setQuantity(1);
      setOrderDetails((prevData) => ({
        ...prevData,
        quantity: [10],
      }));
    } else {
      setQuantity(0);
    }
    console.log(orderDetails);
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onClick = () => {
    dispatch(loadInstagramMedia(username));
  };
  const usernameOnChange = (e) => {
    setUsername(e.target.value);
  };

  const onMediaClick = (link) => {
    console.log(link);
    setOrderDetails({ ...orderDetails, link: [link] });
  };
  useEffect(() => {
    return () => {
      dispatch(clearMedia());
    };
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!media) {
      toast.info("Please load media first.");
      return;
    }
    try {
      setIsLoading(true);
      const response = await dispatch(orderSubmission(orderDetails));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        await dispatch(clearMedia());
        setSuccessModalVisible(true);
        setMessage(responseMessages.isAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setOrderDetails({
          link: "",
          engagementPackage: "",
          service_type: window.location.pathname,
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === '"link" is not allowed to be empty') {
          setMessage(responseMessages.linkClickMessage);
        } else if (response.payload.message === "Incorrect service ID") {
          setMessage(responseMessages.unavailableServiceDuetoId);
        } else if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setQuantity(0);
      setIsLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock2);
    }
  };
  return (
    <div className="font-FiraSans py-8 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want More Real Engagements?
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Add Instagram Real Engagements
      </h3>
      <div className="p-3 bg-blue-200 text-blue-500 rounded-lg flex flex-col gap-3">
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Finally, Boost your Instagram Page 100% Real!!!!!
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          After the Huge Instagram update, and Instagram changed algorithm we
          introduced this service with 100% Super Real Engagement, NOT BOTS!!!
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          None of its kind in the market.
        </p>
        <div>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            You get 100% real organic Likes, Comments and Followers for your
            posts
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            Promotions are Per post and we have 5 different packages as you see
            in the drop-down menu.
          </p>
        </div>
        <div>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            Plan micro 8-12 comments 4-6 followers and 20-25 post likes
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            Plan starter 15-20 comments 7-10 followers 35-45 likes
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            Plan regular 30-35 comments 11-13 followers and 55-65 likes
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            plan premium 45-52 comments 15-18 followers and 80-90 likes
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            plan Advanced 65-72 comments 18-20 followers and 120-130 likes
          </p>
        </div>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Kindly use our chat for more details and assistance about the service.
        </p>
      </div>
      <FormAndButtonTwo
        placeholder="Enter your Instagram Username"
        label="Instagram Username"
        onChange={usernameOnChange}
        value={username}
        onClick={onClick}
        mediaUrl={media}
        username={username}
        onMediaClick={onMediaClick} // Pass onMediaClick callback
      />
      <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 mt-4">
        <label className="block">Package:</label>
        <select
          id="engagementPackage"
          name="engagementPackage"
          onChange={onChange}
          value={engagementPackage}
          className="w-2/3 px-2 sm:w-full xs:w-full py-3 rounded-lg shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
        >
          <option value="" disabled selected>
            Select the Engagement package
          </option>
          <option value="micro">
            Plan Micro: 8-12 comments, 4-6 followers and 20-25 post likes
          </option>
          <option value="starter">
            Plan Starter: 15-20 comments, 7-10 followers and 35-45 post likes
          </option>
          <option value="regular">
            Plan Regular: 30-35 comments, 11-13 followers and 55-65 post likes
          </option>
          <option value="premium">
            Plan Premium: 45-52 comments, 15-18 followers and 80-90 post likes
          </option>
          <option value="advanced">
            Plan Advanced: 65-72 comments, 18-20 followers and 120-130 post
            likes
          </option>
        </select>
      </div>
      <div className=" text-center px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 mt-5">
        <Button
          type="submit"
          title={isLoading ? "Sending order..." : "Send Order"}
          size="sm"
          disabled={isLoading || !engagementPackage || !isAuthenticated}
          onClick={onSubmit}
        />
      </div>
      <ToastContainer />
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />
    </div>
  );
};

export default RealEngagement;
