import React from "react";
import { Link } from "react-router-dom";
import { currentYear } from "../../utils";

const Footer = () => {
  return (
    <footer className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 py-4 bg-[#f49a39] text-white">
      <div className="flex xl:gap-4 lg:gap-3 gap-2 flex-wrap justify-center p-2">
        <Link to="/about_us" className="font-FiraSans font-medium">
          About Us
        </Link>
        <Link to="/terms_of_service" className="font-FiraSans font-medium">
          Terms of Service
        </Link>
        <Link to="/privacy_policy" className="font-FiraSans font-medium">
          Privacy Policy
        </Link>
        <Link to="/faqs" className="font-FiraSans font-medium">
          FAQs
        </Link>
        <Link to="/contact_us" className="font-FiraSans font-medium">
          Contact Us
        </Link>
        <Link to="/blogs" className="font-FiraSans font-medium">
          Blog
        </Link>
      </div>
      {/* <div className="p-2 flex justify-center items-center gap-2"> */}
      <p className="font-FiraSans font-normal text-center text-[#fafafa]">
        Copyright &#169; {currentYear} Igcarts | All Rights Reserved.
      </p>
      {/* </div> */}
    </footer>
  );
};

export default Footer;
