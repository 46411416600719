import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../store/Slice/loginSlice";

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
// const TWO_MINUTES_IN_MS = 2 * 60 * 1000; //for testing purposes

const SessionTimeoutNotifier = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    const checkSessionExpiration = async () => {
      const tokenTimestamp = localStorage.getItem("tokenTimestamp");
      const currentTime = Date.now();

      if (
        tokenTimestamp &&
        currentTime - parseInt(tokenTimestamp, 10) > ONE_DAY_IN_MS
      ) {
        // Token expired, logout user
        await dispatch(logoutUser());
        // Set sessionExpired to true to display the modal
        setSessionExpired(true);
        // Redirect user to sign-in page after a delay
        setTimeout(() => {
          navigate("/signin");
        }, 4000);
      }
    };

    checkSessionExpiration();
    setSessionExpired(false);
  }, [dispatch, navigate]);

  return (
    <Modal
      title={
        <span className="text-center text-blue-700">Session Expired!</span>
      }
      open={sessionExpired} // Show modal only if sessionExpired is true
      footer={null}
      maskClosable={false}
    >
      <p>Your session has expired. You are being redirected to sign in.</p>
    </Modal>
  );
};

export default SessionTimeoutNotifier;
