import React from "react";

const Aboutus = () => {
  return (
    <div className="px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1 py-4">
      <h1 className="font-Montserrat font-bold text-center">About Us</h1>
      <p className="text-center">
        At IGCarts, we’re passionate about helping you grow your social media
        presence with real engagement. We know how crucial social proof is in
        today’s digital world, and that’s why we’re dedicated to delivering
        high-quality likes, comments, and views across all major social
        platforms.
      </p>
      <div className="flex gap-6 flex-wrap justify-center xl:flex-nowrap pt-8">
        <div className="shadow-lg rounded-md bg-white p-4 text-center">
          <h3 className="font-bold mb-4 text-xl">Our Mission</h3>
          <p>
            Our mission is simple:{" "}
            <strong>to empower creators, influencers, and businesses</strong> to
            achieve their goals by boosting their credibility and reach. We
            believe that social media growth shouldn’t be complicated, so we’ve
            made it easy for you to elevate your presence with just a few
            clicks.
          </p>
        </div>
        <div className="shadow-lg rounded-md bg-white p-4 text-center">
          <h3 className="font-bold mb-4 text-xl">Our Promise to You</h3>
          <p>
            At IGCarts, <strong>we value transparency and quality</strong> above
            all else. Our dedicated support team is available 24/7 to assist
            you, ensuring you have a seamless experience every step of the way.
            Your growth and satisfaction are our top priorities.
          </p>
        </div>
        <div className="shadow-lg rounded-md bg-white p-4 text-center">
          <h3 className="font-bold mb-4 text-xl">Why Choose US?</h3>
          <p>
            We understand the challenges of building an online presence from
            scratch. That’s why we focus on offering services that are{" "}
            <strong>quick, effective, and reliable</strong>. Our team is
            committed to delivering genuine likes, comments, and views that help
            you connect with your audience and strengthen your online
            reputation.
          </p>
        </div>
      </div>
      <div className="py-14">
        <p className="font-Montserrat font-bold font-lg text-center">
          What Sets Us Apart
        </p>
        <div className="flex gap-4 flex-column justify-center xl:flex-row">
          <p className="shadow-md p-3 rounded flex-1">
            <strong>Real Engagement, Real Results:</strong> All our services are
            designed to provide authentic engagement from real users, so you can
            build a trustworthy online presence.
          </p>
          <p className="shadow-md p-3 rounded flex-1">
            <strong>Fast & Secure Delivery:</strong> Your orders are processed
            quickly with secure payment methods to ensure a hassle-free
            experience every time.
          </p>
          <p className="shadow-md p-3 rounded flex-1">
            <strong>Tailored Packages for All Needs:</strong> Whether you’re an
            individual, influencer, or business, we have a range of packages
            that fit your goals and budget.
          </p>
        </div>
      </div>
      <div className="pb-6">
        <p className="font-Montserrat font-bold text-center">
          Let's Get Started
        </p>
        <p className="text-center">
          Ready to enhance your social media presence and take your profile to
          the next level? <strong>IGCarts</strong> is here to help you achieve
          real growth with real results.
        </p>
      </div>
    </div>
  );
};

export default Aboutus;
