import React, { useEffect, useState } from "react";
import Button from "../../components/shared/Button";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { ErrorModal, SuccessModal } from "../../components/shared/ToastModals";
import { useOutletContext } from "react-router-dom";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";

const AutoRealComment = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState({
    link: [],
    comment: "",
    quantity: [],
    numberOfPost: "",
    peopleCategory: "",
    service_type: window.location.pathname,
  });
  const { link, comment, numberOfPost, peopleCategory } = data;
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onChange = (e) => {
    let updated = {};
    if (e.target.name === "link") {
      updated = { [e.target.name]: [e.target.value] };
    } else {
      updated = { [e.target.name]: e.target.value };
    }
    setData((prevData) => ({
      ...prevData,
      ...updated,
    }));
    const value = { ...data, ...updated };
    const { comment, numberOfPost } = value;
    const total = comment * numberOfPost;
    setQuantity(total / 10);
    setData((prevData) => ({
      ...prevData,
      quantity: [total],
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(data));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setData({
          link: "",
          comment: "",
          numberOfPost: "",
          peopleCategory: "",
          service_type: window.location.pathname,
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setSuccessModalVisible(false);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };
  return (
    <div className="font-FiraSans py-8 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want More Real Comments?
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Add Auto Real comments
      </h3>
      <div className="p-3 bg-blue-200 text-blue-500 rounded-lg flex flex-col gap-3">
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Real Instagram comment like never before. 100% REAL!!! NO BOTS
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          All comments are related to posts and from Real and active accounts.
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          None of its kind in the market.
        </p>
        <h4 className="text-sm sm:text-xs font-semibold xs:text-xs">
          ENTER YOUR USERNAME AND SELECT THE NUMBER OF COMMENTS IN THE DROP DOWN
          MENU
        </h4>
      </div>
      <div className="pt-8">
        <div className="flex flex-col gap-1 px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1">
          <label className="font-FiraSans text-lg">Instagram Username</label>
          <input
            type="text"
            placeholder="Enter your instagram username"
            value={link}
            name="link"
            onChange={onChange}
            className="py-2 px-2 font-FiraSans outline-blue-800 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
          />
        </div>
        <div className="flex flex-col gap-1 px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-8">
          <label className="font-FiraSans text-lg">Number of Posts</label>
          <select
            id="numberOfPost"
            value={numberOfPost}
            onChange={onChange}
            name="numberOfPost"
            className="w-full px-2 py-3 rounded-md shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
          >
            <option value="" disabled selected>
              Select the number of posts you want auto comment
            </option>
            {Array.from({ length: 50 }, (_, index) => index + 1).map(
              (count) => (
                <option key={count} value={count}>
                  {count} - ({count} post{count !== 1 ? "s" : ""})
                </option>
              )
            )}
          </select>
        </div>
        <div className="flex flex-col gap-1 px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 py-8">
          <label className="font-FiraSans text-lg">Auto Ig Real Comments</label>
          <select
            name="comment"
            onChange={onChange}
            value={comment}
            className="w-full px-2 py-3 rounded-md shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
          >
            <option value="" disabled selected>
              Select number of comments
            </option>
            <option value="10">10 Real Comments </option>
            <option value="15">15 Real Comments </option>
            <option value="20">20 Real Comments </option>
            <option value="25">25 Real Comments </option>
            <option value="30">30 Real Comments </option>
            <option value="50">50 Real Comments </option>
            <option value="75">75 Real Comments </option>
            <option value="100">100 Real Comments </option>
          </select>
        </div>
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1">
          <label className="block font-FiraSans text-lg">Female / Male:</label>
          <select
            id="peopleCategory"
            value={peopleCategory}
            onChange={onChange}
            name="peopleCategory"
            className="px-2 w-full py-3 rounded-lg shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
          >
            <option>Select gender comments</option>
            <option value="Mixed">Mixed</option>
            <option value="Female">Female</option>
            <option value="Male">Male</option>
          </select>
        </div>
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-10 flex justify-center">
          <Button
            title={loading ? "Sending" : "Order"}
            variant="primary"
            size="sm"
            disabled={
              !link ||
              !comment ||
              !peopleCategory ||
              !numberOfPost ||
              loading ||
              !isAuthenticated
            }
            onClick={onSubmit}
          />
        </div>
      </div>
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />{" "}
    </div>
  );
};

export default AutoRealComment;
