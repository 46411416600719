import React, { useEffect, useState } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import axios from "axios";
import Button from "../../../../components/shared/Button";
import Table from "../../../../components/shared/Table";
import { Modal } from "antd"; // Import the Modal component

const NoticeArea = () => {
  const [isActive, setIsActive] = useState(false);
  const [noticeContent, setNoticeContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [editingNoticeId, setEditingNoticeId] = useState(null);
  const token = localStorage.getItem("adminToken");
  const [allNotice, setAllNotice] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedNoticeId, setSelectedNoticeId] = useState(null);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const handleTextareaChange = (e) => {
    setNoticeContent(e.target.value);
  };

  // Create Notice function
  const createNotice = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/notice/create`,
        {
          notice: noticeContent,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        setNoticeContent("");
        setLoading(false);
        alert("Notice created successfully");
        fetchNotice();
      } else {
        setLoading(false);
        alert("An error occurred while creating notice");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error creating notice:", error);
    }
  };

  // Update Notice function
  const updateNotice = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/notice/update/${editingNoticeId}`,
        {
          notice: noticeContent,
          is_active: isActive,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      );

      if (response.status === 200) {
        setNoticeContent("");
        setEditingNoticeId(null);
        setIsActive(false);
        setLoading(false);
        alert("Notice updated successfully");
        fetchNotice();
      } else {
        setLoading(false);
        alert("An error occurred while updating notice");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating notice:", error);
    }
  };

  const fetchNotice = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/notice/get`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json; charset=utf-8",
          },
        }
      );
      setAllNotice(response.data.data);
    } catch (error) {
      console.error("Error fetching notice:", error);
      alert("Sorry, we encountered an error. Please try again later.");
    }
  };

  useEffect(() => {
    fetchNotice();
  }, []);

  // Edit functionality to populate form for update
  const editNotice = (notice) => {
    setNoticeContent(notice.notice);
    setIsActive(notice.is_active);
    setEditingNoticeId(notice.notice_id);
  };

  const handleDeleteNoticeClick = (notice_id) => {
    setSelectedNoticeId(notice_id);
    setDeleteModalVisible(true);
  };

  const deleteNoticeConfirmation = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/notice/delete/${selectedNoticeId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert("Notice deleted successfully");
      fetchNotice();
      setDeleteModalVisible(false);
      setSelectedNoticeId(null);
    } catch (error) {
      console.error("Error deleting notice:", error);
      alert("Error occurred while deleting the notice.");
    }
  };

  const noticeDetails = [
    { name: "Notice Id", selector: (row) => row.notice_id },
    { name: "Notice", selector: (row) => row.notice },
    {
      name: "Active",
      selector: (row) => (row.is_active ? "Active" : "Inactive"),
    },
    {
      name: "Edit",
      selector: (row) => (
        <button className="text-blue-700" onClick={() => editNotice(row)}>
          Edit
        </button>
      ),
    },
    {
      name: "Remove",
      selector: (row) => (
        <button
          className="text-red-700 italic"
          onClick={() => handleDeleteNoticeClick(row.notice_id)} // Show modal instead of direct deletion
        >
          Remove
        </button>
      ),
    },
  ];

  return (
    <div className="px-4">
      <div className="mb-5">
        <Table title="Posted Notices" column={noticeDetails} data={allNotice} />
      </div>

      <form onSubmit={editingNoticeId ? updateNotice : createNotice}>
        <div className="flex flex-col gap-4 items-center py-4">
          {/* Toggle switch for update mode */}
          {editingNoticeId && (
            <div className="flex justify-start toggle-container">
              <label>
                <Toggle checked={isActive} onChange={handleToggle} />
              </label>
            </div>
          )}

          {/* Textarea */}
          <textarea
            value={noticeContent}
            onChange={handleTextareaChange}
            rows="8"
            placeholder="Type your notice here"
            className="resize-none shadow-md w-full p-2 outline-none"
          ></textarea>

          <Button
            type="submit"
            title={
              loading
                ? "Processing..."
                : editingNoticeId
                ? "Update Notice"
                : "Create Notice"
            }
            disabled={loading || !noticeContent.trim()}
            variant="primary"
          />
        </div>
      </form>

      {/* Modal for Confirm Deletion */}
      <Modal
        title={<span style={{ color: "#f44336" }}>Confirm Deletion</span>}
        open={deleteModalVisible}
        onOk={deleteNoticeConfirmation}
        onCancel={() => setDeleteModalVisible(false)} // Close the modal without action
        okText="Delete"
        cancelText="Cancel"
        okButtonProps={{
          style: { backgroundColor: "#f44336", color: "#ffffff" },
        }}
        cancelButtonProps={{
          style: { backgroundColor: "#fc8a24", color: "#ffffff" },
        }}
      >
        <p className="font-semibold">
          Are you sure you want to delete this notice?
        </p>
      </Modal>
    </div>
  );
};

export default NoticeArea;
