import React, { useEffect, useState } from "react";
import Table from "../../../../../components/shared/Table";
import axios from "axios";

const IpCheck = () => {
  const [ipPostMaker, setIpPostMaker] = useState([]);
  const token = localStorage.getItem("adminToken");

  const fetchIpMaker = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/auto-order`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json; charset=utf-8",
          },
        }
      );
      setIpPostMaker(response.data.data);
    } catch (error) {
      alert("We encountered an error while fetching Ips, please try again");
    }
  };

  useEffect(() => {
    fetchIpMaker();
  });

  const ipMakerDetails = [
    {
      name: "Ord. Id",
      selector: (row) => row.auto_order_id,
      width: "120px",
    },
    {
      name: "Sub. Id",
      selector: (row) => row.auto_subscription_id,
      width: "120px",
    },
    {
      name: "User",
      selector: (row) => row.user_id,
      width: "140px",
    },
    {
      name: "Qty.",
      selector: (row) => row.quantity,
      width: "80px",
    },
    {
      name: "Service",
      selector: (row) => row.service,
      width: "80px",
    },
    {
      name: "Service Name",
      selector: (row) => row.service_name,
      width: "110px",
    },
    {
      name: "Social Media",
      selector: (row) => row.social_media,
      width: "120px",
    },
    {
      name: "Ip Add.",
      selector: (row) => row.ip_address,
      width: "130px",
    },
    {
      name: "Link",
      selector: (row) => (
        <a href={row.last_post} target="_blank" rel="noopener noreferrer">
          {row.last_post}
        </a>
      ),
      width: "230px",
    },
  ];
  return (
    <div className="mt-6">
      <Table
        title="Ip Making Post"
        column={ipMakerDetails}
        data={ipPostMaker}
      />
    </div>
  );
};

export default IpCheck;
