import React from "react";
import { Modal, Button as AntdButton } from "antd";

export const SuccessModal = ({ visible, onClose, message }) => {
  return (
    <div className="relative bottom-0">
      <Modal
        title={<span style={{ color: "#4caf50" }}>Success!</span>}
        open={visible}
        onCancel={onClose}
        footer={[
          <AntdButton
            key="ok"
            type="primary"
            onClick={onClose}
            style={{
              backgroundColor: "#4caf50",
              borderColor: "#4caf50",
              color: "white",
            }}
          >
            OK
          </AntdButton>,
        ]}
      >
        {message}
      </Modal>
    </div>
  );
};

export const ErrorModal = ({ visible, onClose, message }) => {
  return (
    <div>
      <Modal
        title={<span style={{ color: "#f44336" }}>Error!</span>}
        open={visible}
        onCancel={onClose}
        footer={[
          <AntdButton
            key="ok"
            type="primary"
            onClick={onClose}
            style={{
              backgroundColor: "#f44336",
              borderColor: "#f44336",
              color: "white",
            }}
          >
            OK
          </AntdButton>,
        ]}
      >
        {message}
      </Modal>
    </div>
  );
};

export const InfoModal = ({ visible, onClose, message }) => {
  return (
    <div className="relative bottom-0">
      <Modal
        title={<span style={{ color: "blue" }}>Info!</span>}
        open={visible}
        onCancel={onClose}
        footer={[
          <AntdButton
            key="ok"
            type="primary"
            onClick={onClose}
            style={{
              backgroundColor: "blue",
              borderColor: "blue",
              color: "white",
            }}
          >
            OK
          </AntdButton>,
        ]}
      >
        {message}
      </Modal>
    </div>
  );
};
