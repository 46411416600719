import React, { useEffect, useState } from "react";
import Button from "../../components/shared/Button";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { ErrorModal, SuccessModal } from "../../components/shared/ToastModals";
import responseMessages from "../../utils/responseMessages";
import { useOutletContext } from "react-router-dom";
import { useAccordionContext } from "../../SEO/AccordionContext";
import {
  aigrowthSeoAccordion,
  aigrowthSeoAccordionHeading,
} from "../../SEO/components/pagesAccordion";
const AIGrowth = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const [isLoading, setIsLoading] = useState(false);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [orderDetails, setOrderDetails] = useState({
    link: "",
    quantity: [],
    engagementPackage: "",
    service_type: window.location.pathname,
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const { engagementPackage, link } = orderDetails;

  const onChangeOne = (e) => {
    const updated = { [e.target.name]: [e.target.value] };
    setOrderDetails({ ...orderDetails, ...updated });
  };
  const onChange = (e) => {
    const updated = { [e.target.name]: e.target.value };
    setOrderDetails({ ...orderDetails, ...updated });
    const value = { ...orderDetails, ...updated };
    const { engagementPackage } = value;
    if (engagementPackage === "standard") {
      setQuantity(1);
      setOrderDetails((prevData) => ({
        ...prevData,
        quantity: [1],
      }));
    } else if (engagementPackage === "pro") {
      setQuantity(2.06);
      setOrderDetails((prevData) => ({
        ...prevData,
        quantity: [2.06],
      }));
    } else if (engagementPackage === "premium") {
      setQuantity(3.37);
      setOrderDetails((prevData) => ({
        ...prevData,
        quantity: [3.37],
      }));
    } else {
      setQuantity(0);
    }
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await dispatch(orderSubmission(orderDetails));
      await dispatch(updateUserDetails());
      setIsLoading(false);
      setOrderDetails({
        link: "",
        engagementPackage: "",
        service_type: window.location.pathname,
      });
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        setSuccessModalVisible(true);
        setErrorModalVisible(false);
        setMessage(responseMessages.isAvailableOrderMessage);
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        setSuccessModalVisible(false);
        if (response.payload.message === "Incorrect service ID") {
          setMessage(responseMessages.unavailableServiceDuetoId);
        } else if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
    } catch (error) {
      setQuantity(0);
      setIsLoading(false);
      setErrorModalVisible(true);
      setSuccessModalVisible(false);
      setMessage(responseMessages.errorMessageForCatchBlock2);
    }
  };
  return (
    <div className="font-FiraSans py-4 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want More Growth on your account?
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Add Instagram AI Powered Growth{" "}
      </h3>
      <div className="p-3 bg-blue-200 text-blue-500 rounded-lg flex flex-col gap-1 mx-3">
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          US Targeted Engagement
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          AI Growth Tool.
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Our Artificial Intelligence Software will boost your account!
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          100% natural, no bots, no tricks. We find your audience and make them
          your organic followers.
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Our system works to find target followers for you 24 hours a day,
          exposing your content to them and constantly bringing in new
          followers!
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Our system is tailored to get you 90% US Audience.
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          NO PASSWORD REQUIRED.
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Followers Guaranteed Per Month.
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Targeted to your account's Niche.
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Targeting Suggestions & Optimization.
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Clean Home Feed
        </p>
      </div>
      <form onSubmit={onSubmit}>
        <div className="pt-2">
          <div className="px-12 lg:px-4 md:px-3 sm:px-2 xs:px-1 mt-4">
            <label className="font-FiraSans text-lg block">Username</label>
            <input
              type="text"
              placeholder="Type in your username here..."
              onChange={onChangeOne}
              name="link"
              value={link}
              className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 w-full rounded-md"
            />
          </div>
          <div className="px-12 lg:px-4 md:px-3 sm:px-2 xs:px-1 mt-4">
            <label className="block">Package:</label>
            <select
              id="engagementPackage"
              name="engagementPackage"
              onChange={onChange}
              value={engagementPackage}
              className="px-2 w-full py-3 rounded-lg shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
            >
              <option value="" disabled selected>
                Select the Engagement package
              </option>
              <option value="standard">
                Standard Plan: Instagram USA AI Growth Package (MONTHLY PACKAGE)
                - (1K Followers/Month)
              </option>
              <option value="pro">
                PRO Plan: Instagram USA AI Growth Package (MONTHLY PACKAGE) -
                (3K Followers/Month)
              </option>
              <option value="premium">
                Premium Plan: Instagram USA AI Growth Package (MONTHLY PACKAGE)
                (5K Followers/Month)
              </option>
            </select>
          </div>
          <div className=" text-center px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 mt-5">
            <Button
              type="submit"
              title={isLoading ? "Sending order..." : "Send Order"}
              size="sm"
              disabled={
                isLoading || !engagementPackage || !isAuthenticated || !link
              }
            />
          </div>
        </div>
      </form>

      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />
    </div>
  );
};

export default AIGrowth;
