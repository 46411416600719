import React from "react";
import { useSelector } from "react-redux";
const Header = () => {
  const displayUser = useSelector((state) => state.login.user);

  return (
    <>
      <div className="w-full">
        <p className="font-bold text-[#001f68]  text-[2.2rem] md:text-[1.7rem] sm:text-[1.3rem] xs:text-[1rem] ">
          Welcome, {displayUser && displayUser.user_name.toUpperCase()}
        </p>
      </div>
    </>
  );
};

export default Header;
