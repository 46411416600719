import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HiUserCircle } from "react-icons/hi2";
import { HiBars3BottomRight } from "react-icons/hi2";
import { FiX } from "react-icons/fi";
import { createDropdown } from "../../utils/NavbarDropdown";
import {
  Engagements,
  Facebook,
  IGAiGrowthPackage,
  IGLikes,
  IGReels,
  IGViews,
  Instagram,
  Threads,
  TikTok,
  Twitter,
  YoutubeSpotifySoundCloud,
} from "../../utils/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "antd";
import { logoutUser } from "../../store/Slice/loginSlice";

const Navbar = () => {
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const displayUser = useSelector((state) => state.login.user);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  const showLogoutModal = () => {
    setIsLogoutModalVisible(true);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    setToggleMenu(false);
    setIsLogoutModalVisible(false);
    navigate("/signin");
  };
  useEffect(() => {
    if (toggleMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Clean up on component unmount
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [toggleMenu]);
  const clickedItem = () => {
    setToggleMenu(false);
  };
  const ThreadsDropdown = () => {
    return createDropdown(Threads[0], clickedItem);
  };
  const IGLikesDropdown = () => {
    return createDropdown(IGLikes[0], clickedItem);
  };
  const IGViewsDropdown = () => {
    return createDropdown(IGViews[0], clickedItem);
  };
  const InstagramDropdown = () => {
    return createDropdown(Instagram[0], clickedItem);
  };
  const IGReelsDropdown = () => {
    return createDropdown(IGReels[0], clickedItem);
  };
  const TikTokDropdown = () => {
    return createDropdown(TikTok[0], clickedItem);
  };
  const FacebookDropdown = () => {
    return createDropdown(Facebook[0], clickedItem);
  };
  const YoutubeSpotifySoundCloudDropdown = () => {
    return createDropdown(YoutubeSpotifySoundCloud[0], clickedItem);
  };
  const EngagementsDropdown = () => {
    return createDropdown(Engagements[0], clickedItem);
  };
  const IGAiGrowthPackageDropdown = () => {
    return createDropdown(IGAiGrowthPackage[0], clickedItem);
  };
  const TwitterDropdown = () => {
    return createDropdown(Twitter[0], clickedItem);
  };
  return (
    <nav
      className={`px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 py-6 bg-[#f49a39] text-white ${
        toggleMenu ? "fixed top-0 left-0 right-0 z-50" : ""
      }`}
    >
      <div
        className={
          toggleMenu
            ? "flex flex-col justify-start mt-8 w-full h-screen"
            : "flex flex-col gap-2"
        }
      >
        {/*Logo and User Section */}
        <div className="flex justify-between">
          <Link
            to={isAuthenticated ? "/dashboard" : "/signin"}
            className="text-3xl font-semibold font-Montserrat sm:absolute xs:absolute md:absolute lg:absolute sm:left-2 sm:top-1 xs:left-2 xs:top-1 md:left-2 md:top-1 lg:left-2 lg:top-1"
          >
            Igcarts
          </Link>

          {/* User Section */}
          <Link
            onClick={clickedItem}
            to={isAuthenticated ? "/dashboard" : "/signin"}
            className="flex items-center sm:absolute xs:absolute md:absolute lg:absolute sm:right-16 sm:top-2 xs:right-16 xs:top-2 md:right-16 md:top-2 lg:right-16 lg:top-2"
          >
            <HiUserCircle size={19} className="text-gray-200" />
            {isAuthenticated ? (
              <p className="text-sm font-FiraSans">
                {displayUser?.user_name.toUpperCase()}
              </p>
            ) : (
              <p className="text-sm font-FiraSans">User</p>
            )}
          </Link>
        </div>

        {/* Hamburger Menu Icon */}
        <div
          className="hidden xs:block sm:block md:block lg:block top-1 right-4 md:right-4 absolute"
          onClick={handleToggleMenu}
        >
          {toggleMenu ? (
            <FiX size={32} className="text-white" />
          ) : (
            <HiBars3BottomRight size={32} className="text-white" />
          )}
        </div>

        {/* Navigation Links */}
        <ul
          className={
            toggleMenu
              ? "flex flex-col"
              : "flex gap-2 px-4 items-center font-FiraSans xs:hidden sm:hidden md:hidden lg:hidden"
          }
        >
          {/*Dropdowns starts*/}
          <IGAiGrowthPackageDropdown />
          <IGLikesDropdown />
          <IGViewsDropdown />
          <InstagramDropdown />
          <IGReelsDropdown />
          <ThreadsDropdown />
          <TikTokDropdown />
          <FacebookDropdown />
          <TwitterDropdown />
          <YoutubeSpotifySoundCloudDropdown />
          {/*Dropdowns ends*/}
        </ul>

        <ul
          className={`flex ${
            toggleMenu
              ? "flex-col"
              : "gap-3 px-4 font-FiraSans xs:hidden sm:hidden md:hidden lg:hidden"
          }`}
        >
          <EngagementsDropdown />
          <Link
            to="/blogs"
            className="font-FiraSans hover:bg-[#fc8a24] p-1"
            onClick={clickedItem}
          >
            Blogs
          </Link>
          <Link
            to="/services"
            className="font-FiraSans hover:bg-[#fc8a24] p-1"
            onClick={clickedItem}
          >
            Services
          </Link>
          <Link
            to="/faqs"
            className="font-FiraSans hover:bg-[#fc8a24] p-1"
            onClick={clickedItem}
          >
            FAQs
          </Link>
          {/* Auth Links */}
          {isAuthenticated ? (
            <>
              {/*<Link
                to="/complain_ticket"
                className="hover:bg-[#fc8a24] p-1"
                onClick={clickedItem}
              >
                Ticket
          </Link>*/}
              <Button
                type="link"
                onClick={showLogoutModal}
                className="p-1 text-sm font-FiraSans"
                style={{ color: "white" }}
              >
                Logout
              </Button>
            </>
          ) : (
            <>
              <Link
                to="/signup"
                className="hover:bg-[#fc8a24] p-1"
                onClick={clickedItem}
              >
                Sign Up
              </Link>
              <Link
                to="/signin"
                onClick={clickedItem}
                className="hover:bg-[#fc8a24] p-1"
              >
                Sign In
              </Link>
            </>
          )}
        </ul>
        {isLogoutModalVisible && (
          <Modal
            title="Confirm Logout"
            open={isLogoutModalVisible}
            onCancel={() => setIsLogoutModalVisible(false)}
            footer={[
              <Button
                key="cancel"
                onClick={() => setIsLogoutModalVisible(false)}
                style={{
                  borderColor: "#fc8a24",
                  color: "black",
                }}
              >
                Cancel
              </Button>,
              <Button
                key="logout"
                type="primary"
                onClick={handleLogout}
                style={{
                  background: "#fc8a24",
                  borderColor: "#fc8a24",
                }}
              >
                Logout
              </Button>,
            ]}
          >
            Are you sure you want to logout?
          </Modal>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
