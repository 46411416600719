import React, { useState } from "react";
import Button from "../../components/shared/Button";
import { ErrorModal, SuccessModal } from "../../components/shared/ToastModals";
import responseMessages from "../../utils/responseMessages";
import { useSelector } from "react-redux";
import axios from "axios";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [mailData, setMailData] = useState({
    message: "",
    link: "",
    subject: "",
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [messageShow, setMessageShow] = useState("");
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const token = localStorage.getItem("token");

  const { message, link, subject } = mailData;

  const onChange = (e) => {
    setMailData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/social/complaint`,
        mailData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      );
      setLoading(false);
      setMailData({ message: "", link: "", subject: "" });
      if (response.status === 200 || response.status === 201) {
        setSuccessModalVisible(true);
        setErrorModalVisible(false);
        setMessageShow(responseMessages.ticketSuccess);
      } else {
        setErrorModalVisible(true);
        setSuccessModalVisible(false);
        setMessageShow(responseMessages.ticketFailure);
      }
    } catch (error) {
      setLoading(false);
      setErrorModalVisible(true);
      setSuccessModalVisible(false);
      setMessageShow(responseMessages.errorMessage);
    }
  };
  return (
    <div>
      <div className="px-20 lg:px-15 md:px-8 sm:px-4 xs:px-2 py-6">
        <h2 className="text-center font-bold">Customer support - Tickets</h2>
        <form
          onSubmit={onSubmit}
          className="flex flex-col gap-8 lg:gap-6 md:gap-5 sm:gap-4 xs:gap-3 w-full py-4"
        >
          <div className="flex flex-col gap-1">
            <label className="font-FiraSans text-lg">Subject</label>
            <input
              type="text"
              placeholder="Put the subject of the message here"
              value={subject}
              onChange={onChange}
              name="subject"
              className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-FiraSans text-lg">Link/Username</label>
            <input
              type="text"
              placeholder="Input the username or link you want to complain about"
              value={link}
              onChange={onChange}
              name="link"
              className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-FiraSans text-lg">Message</label>
            <textarea
              rows="10"
              placeholder="Write your message here..."
              value={message}
              onChange={onChange}
              name="message"
              required
              className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
            />
          </div>
          <div className="flex justify-center">
            <Button
              type="submit"
              title={loading ? "Submitting ticket..." : "Submit Ticket"}
              size="md"
              disabled={
                loading || !isAuthenticated || !subject || !link || !message
              }
            />
          </div>
        </form>
        {/* Success Modal */}
        <SuccessModal
          visible={successModalVisible}
          onClose={() => setSuccessModalVisible(false)}
          message={messageShow}
        />
        {/* Error Modal */}
        <ErrorModal
          visible={errorModalVisible}
          onClose={() => setErrorModalVisible(false)}
          message={messageShow}
        />
      </div>
    </div>
  );
};

export default Index;
