import React, { useEffect, useState } from "react";
import TwoRowForm from "../../components/shared/TwoRowForm";
import Button from "../../components/shared/Button";
import { HiX } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import {
  ErrorModal,
  InfoModal,
  SuccessModal,
} from "../../components/shared/ToastModals";
import { useOutletContext } from "react-router-dom";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";

const ThreadsComments = () => {
  const [close, setClose] = useState(false);
  function closeUp() {
    setClose(!close);
  }

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [message, setMessage] = useState("");

  const [data, setData] = useState({
    service_type: window.location.pathname,
    link: [],
    quantity: [],
  });
  const { link, quantity } = data;
  const onChange = (e) => {
    const updated = { [e.target.name]: [e.target.value] };
    setData({ ...data, ...updated });
    const value = { ...data, ...updated };
    const { quantity } = value;
    setQuantity(quantity / 10);
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    if (quantity < 10) {
      setInfoModalVisible(true);
      setMessage(responseMessages.minimumQuantityOf10);
      return;
    }
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(data));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setData({
          link: [],
          quantity: [],
          service_type: window.location.pathname,
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };

  return (
    <>
      <div className="flex flex-col py-4">
        <div className="text-black xs:text-base sm:text-lg md:text-xl text-3xl text-center">
          Want More Threads Comments?
        </div>
        <h2 className="text-black font-bold xs:text-[1.5rem] sm:text-[2rem] md:text-[2.5rem] text-[3rem] text-center">
          Add Threads Comments
        </h2>
        <div className="font-Firasans py-8 sm:py-4 xs:py-2 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1 flex flex-col gap-6 ">
          {close ? null : (
            <div className="p-3 bg-[#EDD1D1] border-2 border-[#DCA7A7] rounded flex justify-between ">
              <div className="text-blue-800 font-bold ">
                <p>Instagram new product -THREADS COMMENTS</p>
              </div>
              <button onClick={closeUp}>
                <HiX />
              </button>
            </div>
          )}
          <div className="w-full">
            <TwoRowForm
              labelOne="Threads Link"
              placeholderOne="Threads Link"
              onChangeOne={onChange}
              valueOne={link}
              nameOne="link"
              onChangeTwo={onChange}
              valueTwo={quantity}
              nameTwo="quantity"
              labelTwo="Number of Comments"
            />
            <div className="text-center pt-4">
              <Button
                title={loading ? "Sending..." : "Add Comments"}
                size="md"
                onClick={onSubmit}
                disabled={!link || !quantity || loading || !isAuthenticated}
              />
            </div>
          </div>
          {/* Success Modal */}
          <SuccessModal
            visible={successModalVisible}
            onClose={() => setSuccessModalVisible(false)}
            message={message}
          />
          {/* Error Modal */}
          <ErrorModal
            visible={errorModalVisible}
            onClose={() => setErrorModalVisible(false)}
            message={message}
          />
          {/* Info Modal */}
          <InfoModal
            visible={infoModalVisible}
            onClose={() => setInfoModalVisible(false)}
            message={message}
          />
        </div>
      </div>
    </>
  );
};

export default ThreadsComments;
