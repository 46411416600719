import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "../../../../components/shared/Button";

const OrderEmail = () => {
  const [currentEmailAndCoupon, setCurrentEmailAndCoupon] = useState({});
  const [newEmailAndCoupon, setNewEmailAndCoupon] = useState({
    email: "",
    referral_code: "",
    referral_bonus: "",
  });
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const token = localStorage.getItem("adminToken");

  useEffect(() => {
    const fetchCurrentEmail = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_IGCARTS_BASE_URL}/admin/get-email`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        );
        setCurrentEmailAndCoupon(response.data.data);
      } catch (error) {
        alert("Error fetching current email, check your connection");
      }
    };
    fetchCurrentEmail();
  }, [token]);

  const handleNewEmailAndCouponInput = (event) => {
    const { name, value } = event.target;
    setNewEmailAndCoupon({ ...newEmailAndCoupon, [name]: value });
  };

  const handleEmailAndCouponChange = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await axios.patch(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/admin/change-email`,
        newEmailAndCoupon,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      );
      setCurrentEmailAndCoupon(newEmailAndCoupon);
      setNewEmailAndCoupon({
        email: "",
        referral_code: "",
        referral_bonus: "",
      });
      setLoading(false);
      setIsEditing(false);
    } catch (error) {
      setLoading(false);
      alert("Error changing email/coupon code or amount.");
    }
  };

  const handleEditClick = () => {
    console.log(currentEmailAndCoupon);
    setNewEmailAndCoupon({
      email:
        currentEmailAndCoupon.recipient_email || currentEmailAndCoupon.email,
      referral_code: currentEmailAndCoupon.referral_code,
      referral_bonus: currentEmailAndCoupon.referral_bonus,
    });
    setIsEditing(true);
  };

  return (
    <div className="mt-7">
      <h4 className="font-bold text-center text-xl mb-3">Order Email</h4>
      <form onSubmit={handleEmailAndCouponChange}>
        <div className="flex justify-center gap-2 mb-5 sm:flex-col xs:flex-col md:flex-wrap lg:flex-wrap">
          <input
            type="email"
            value={
              currentEmailAndCoupon.recipient_email ||
              currentEmailAndCoupon.email
            }
            disabled
            className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md"
          />
          <input
            type="email"
            name="email"
            placeholder="New Email"
            value={newEmailAndCoupon.email}
            onChange={handleNewEmailAndCouponInput}
            required
            disabled={!isEditing}
            className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md"
          />
        </div>
        <h4 className="font-bold text-center text-xl block mb-3">
          Coupon Code
        </h4>
        <div className="flex justify-center gap-2 mb-5 sm:flex-col xs:flex-col md:flex-wrap lg:flex-wrap">
          <input
            type="text"
            value={currentEmailAndCoupon.referral_code}
            disabled
            className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md"
          />
          <input
            type="text"
            name="referral_code"
            placeholder="Referral Code"
            value={newEmailAndCoupon.referral_code}
            onChange={handleNewEmailAndCouponInput}
            required
            disabled={!isEditing}
            className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md"
          />
        </div>
        <h4 className="font-bold text-center text-xl block mb-3">
          Coupon Amount
        </h4>
        <div className="flex justify-center gap-2 mb-5 sm:flex-col xs:flex-col md:flex-wrap lg:flex-wrap">
          <input
            type="text"
            value={currentEmailAndCoupon.referral_bonus}
            disabled
            className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md"
          />
          <input
            type="text"
            name="referral_bonus"
            placeholder="Referral Amount"
            value={newEmailAndCoupon.referral_bonus}
            onChange={handleNewEmailAndCouponInput}
            required
            disabled={!isEditing}
            className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md"
          />
        </div>
        <div className="flex justify-center gap-2">
          <Button
            type="button"
            title="Edit"
            onClick={handleEditClick}
            variant="secondary"
            disabled={isEditing}
          />
          <Button
            type="submit"
            title={loading ? "Changing email/coupon..." : "Change Email/Coupon"}
            disabled={loading || !isEditing}
            variant="primary"
          />
        </div>
      </form>
    </div>
  );
};

export default OrderEmail;
