import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BACKEND_ENDPOINTS } from "../../utils/backenUrl";

// Thunk action for logging in
export const loginAdmin = createAsyncThunk(
  "adminLogin/loginAdmin",
  async (adminDetails, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/${BACKEND_ENDPOINTS.ADMIN_SIGNIN}`,
        adminDetails
      );

      // Save token to localStorage
      localStorage.setItem("adminToken", response.data.data.token);
      localStorage.setItem("adminTokenTimestamp", Date.now().toString());

      dispatch(adminLoginSlice.actions.loginConfirmed());

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const adminLoginSlice = createSlice({
  name: "adminLogin",
  initialState: {
    adminDetails: {
      email: "",
      password: "",
    },
    rememberMe: false,
    error: null,
    isLoggedIn: false,
  },

  reducers: {
    loginConfirmed: (state) => {
      state.isLoggedIn = true;
    },
    logoutAdmin: (state) => {
      state.isLoggedIn = false;
      localStorage.removeItem("adminToken");
      localStorage.removeItem("adminTokenTimestamp");
    },
    setLoginDetails: (state, action) => {
      state.adminDetails = action.payload;
    },
    toggleRememberMe: (state) => {
      state.rememberMe = !state.rememberMe; // Toggle rememberMe state
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setLoginDetails,
  toggleRememberMe,
  setLoading,
  setError,
  logoutAdmin,
} = adminLoginSlice.actions;
export default adminLoginSlice.reducer;
