import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { TbCircleCheckFilled } from "react-icons/tb";

const StripeSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate("/dashboard");
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [navigate]);

  return (
    <div className="flex gap-3 justify-center my-32 items-center font-FiraSans px-3 md:px-2 sm:px-1 xs:px-1 sm:my-48 xs:my-48">
      <div className="shadow-lg rounded-sm p-5 flex flex-col gap-6 items-center bg-yellow-500 text-white">
        <TbCircleCheckFilled size={55} className="text-green-600" />
        <h2>Thanks for your order!</h2>
        <h4>Your payment is successful.</h4>
        <p>
          We appreciate your business! If you have any other questions, please
          email us at
          <a href="mailto:" className="decoration-0 text-blue-400"></a>. and if
          you want to browse through other services go to our{" "}
          <Link to="/services" className="text-blue-400">
            services page.
          </Link>
        </p>
      </div>
    </div>
  );
};

export default StripeSuccess;
