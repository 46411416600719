import React, { useEffect, useState } from "react";
import Button from "../../components/shared/Button";
import { Link, useOutletContext } from "react-router-dom";
import ThreeRowForm from "../../components/shared/ThreeRowForm";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import { ErrorModal, SuccessModal } from "../../components/shared/ToastModals";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";
import { Helmet } from "react-helmet";

const AutoLikes = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [data, setData] = useState({
    link: [],
    quantity: [],
    numberOfPost: "",
    likesQuantity: "",
    service_type: window.location.pathname,
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { link, numberOfPost, likesQuantity } = data;
  const onChange = (e) => {
    let updated = {};
    if (e.target.name === "likesQuantity" || e.target.name === "numberOfPost") {
      updated = { [e.target.name]: e.target.value };
    } else {
      updated = { [e.target.name]: [e.target.value] };
    }
    setData({
      ...data,
      ...updated,
    });
    const value = { ...data, ...updated };
    const { numberOfPost, likesQuantity } = value;
    const total = numberOfPost * likesQuantity;
    setQuantity(total);
    setData((prevData) => ({
      ...prevData,
      quantity: [total],
    }));
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(data));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setData({
          link: [],
          quantity: [],
          numberOfPost: "",
          likesQuantity: "",
          service_type: window.location.pathname,
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };

  return (
    <div className="font-FiraSans py-8 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <Helmet>
        <title>
          Auto Instagram Likes – Boost Engagement on Every Post Automatically
        </title>
        <meta
          name="description"
          content="Auto Instagram likes delivered to every post! Automate your engagement and keep your content performing without any extra effort. Sign up for auto likes now."
        />
      </Helmet>
      <h1 className="font-bold pb-9 text-4xl text-center sm:text-xl xs:text-xl md:text-2xl">
        Auto Instagram Likes – Engagement Made Easy
      </h1>
      <p className="font-medium pb-9 text-3xl text-center sm:text-xl xs:text-xl md:text-2xl">
        Auto Add High Quality Likes - None Drop
      </p>
      <div className="flex mb-7 flex-col gap-4 p-3 bg-blue-200 text-blue-500 rounded-lg">
        <div>
          <p className="text-sm sm:text-xs xs:text-xs">
            Don’t want to manually buy likes for every post? With our auto likes
            service, you’ll receive likes on every new post as soon as it goes
            live. This helps your posts perform better from the start, ensuring
            you always get the engagement you need without having to think about
            it. <br /> Perfect for influencers, businesses, or anyone looking to
            simplify their social media growth strategy.
          </p>
        </div>
        <div>
          <p className="text-sm sm:text-xs xs:text-xs mb-2">
            Why Auto Instagram Likes?
          </p>
          <p className="text-sm sm:text-xs xs:text-xs pl-2">
            - <strong>Automatic Delivery:</strong> Every time you post, our
            system will automatically deliver likes to help boost engagement
            right away.
          </p>
          <p className="text-sm sm:text-xs xs:text-xs pl-2">
            - <strong>Boost Visibility:</strong> More likes mean better
            visibility, helping your posts reach a larger audience faster.
          </p>
          <p className="text-sm sm:text-xs xs:text-xs pl-2">
            - <strong>Time-Saving:</strong> No need to manually buy likes for
            each post. Set it up once and enjoy consistent results.
          </p>
        </div>
      </div>
      <>
        <ThreeRowForm
          labelOne="Instagram Username"
          placeholderOne="Instagram Username"
          labelTwo="Number of Posts"
          labelThree="Auto Likes"
          onChangeOne={onChange}
          onClick={onChange}
          onChangeThree={onChange}
          valueOne={link}
          valueTwo={numberOfPost}
          valueThree={likesQuantity}
          nameOne="link"
          nameTwo="numberOfPost"
          nameThree="likesQuantity"
        />
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-10 flex justify-center">
          <Button
            type="submit"
            title={loading ? "Adding likes..." : "Auto Add Likes"}
            variant="primary"
            size="sm"
            onClick={onSubmit}
            disabled={
              !isAuthenticated ||
              !link ||
              !numberOfPost ||
              !likesQuantity ||
              loading
            }
          />
        </div>
      </>
      <p className="text-center font-FiraSans font-medium text-lg pt-4">
        If you’re looking for likes that won’t disappear, our{" "}
        <Link
          to="/ig_auto_high_quality_none_drop_likes"
          className="text-blue-600"
        >
          auto high-quality likes
        </Link>{" "}
        ensure lasting engagement without drops.
      </p>
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />
    </div>
  );
};

export default AutoLikes;
