export const aigrowthSeoAccordionHeading =
  "Questions about buying AI Growth package";
export const aigrowthSeoAccordion = [
  {
    title: "Q. Testing",
    content: "A. Testing testing",
  },
  {
    title: "Q. Time of delivery?",
    content: "A. Testing Testing",
  },
  {
    title: "Q. Can i add same media likes or views?",
    content: "A. Yes yes yes",
  },
  {
    title: "Q. What kind of payment is accepted?",
    content: "A. any payment",
  },
  {
    title: "Q.Is it safe?",
    content: "A. definitely",
  },
];

export const high_quality_fast_likesSeoAccordionHeading =
  "Questions about High Quality Fast Likes";
export const high_quality_fast_likesSeoAccordion = [
  {
    title: "Q. Do you provide Facebook page/post likes?",
    content: "A. Yes,sure",
  },
  {
    title: "Q. Can i be your reseller?",
    content: "A. Yes,please contact us with our chat",
  },
  {
    title: "Q. Do you provide Twitter followers?",
    content: "A. Yes,sure",
  },
  {
    title: "Q. Do you provide Youtube views?",
    content: "A. Yes,sure",
  },
];
export const realUSAFollowersSeoAccordionHeading =
  "Questions About Buying Instagram Followers";
export const realUSAFollowersSeoAccordion = [
  {
    title: "Q. How Do I Order Instagram Followers?",
    content:
      "A. Ordering Instagram followers from IGCart is simple. Select your desired number of followers, provide your Instagram handle, choose your desired demographic, and complete the purchase. Our team will handle the rest and ensure your followers are delivered promptly.",
  },
  {
    title: "Q. Why Should I Buy Instagram Followers?",
    content:
      "A. Buying Instagram followers can boost your profile's visibility, credibility, and engagement. It helps you attract more organic followers and grow your influence on the platform.",
  },
  {
    title: "Q. Can You Buy Instagram Followers That Are Real?",
    content:
      "A. Yes, you can buy real Instagram followers from us. We specialize in providing real, active, and premium followers who enhance your Instagram presence effectively.",
  },
  {
    title: "Q. Can I Get Assistance With My Ig Followers Order?",
    content:
      "A. Absolutely! Our live chat support is available 24/7 to help you with any questions or issues you may have regarding your order.",
  },
  {
    title: "Q. Is It Safe To Buy Instagram Followers?",
    content:
      "A. Yes, it is safe to buy Instagram followers from IGCart. We use secure methods to deliver your followers without compromising your account's safety.",
  },
  {
    title: "Q. Will My IG Followers Drop Over Time?",
    content:
      "A. Our followers are non-drop, meaning they are stable and won't decrease over time. If you experience any drop, our support team is ready to assist you.",
  },
];
export const autoDailyFollowersSeoAccordionHeading =
  "Frequently Asked Questions About Instagram Auto Followers";
export const autoDailyFollowersSeoAccordion = [
  {
    title: "Q. How do I buy Instagram auto followers?",
    content:
      "A. Simply choose a package, provide your Instagram username, and our system handles the rest. You'll start seeing followers increase without any additional input needed.",
  },
  {
    title: "Q. Are the followers real and active?",
    content:
      "A. Absolutely! We provide real and active followers to ensure your engagement metrics improve along with your follower count.",
  },
  {
    title: "Q. Is it safe to buy Ig auto followers?",
    content:
      "A. Yes, it's completely safe. Our methods comply with Instagram's terms of service, ensuring your account remains secure.",
  },
  {
    title: "Q. Can I choose the type of ig followers I receive?",
    content:
      "A. Yes, you can specify the demographic characteristics of the followers you want, such as location, interests, and gender.",
  },
  {
    title: "Q. What if I experience a drop in followers?",
    content:
      "A. Our Instagram auto followers are designed to be non-drop. However, should you notice any decrease, our customer support is ready to assist you",
  },
  {
    title: "Q. How long does it take to see results?",
    content:
      "A. Followers typically begin to appear within 24 hours after your purchase, with full delivery depending on the package size.",
  },
];
export const instagramLikesSeoAccordionHeading =
  "Frequently Asked Questions About Instagram Likes";
export const instagramLikesSeoAccordion = [
  {
    title: "Q. How can I buy Instagram likes?",
    content:
      "A. Choose the number of likes you want, provide the post details, and make your purchase. Our system will handle the rest, delivering likes to your posts instantly.",
  },
  {
    title: "Q. Are the likes provided by real accounts?",
    content:
      "A. Yes, all likes come from genuine, active Instagram accounts to ensure authenticity and quality engagement.",
  },
  {
    title: "Q. Is buying Instagram likes safe for my Instagram account?",
    content:
      "A. Absolutely! Our process is secure and adheres to Instagram's policies, protecting your account integrity.",
  },
  {
    title: "Q. Can I specify which posts receive the likes?",
    content:
      "A. Yes, you have the flexibility to select which posts will receive the likes during the purchase process.",
  },
  {
    title: "Q. What if the lG iikes don't appear on my posts?",
    content:
      "A. Our dedicated support team is available 24/7 to resolve any issues or delays in delivering your likes.",
  },
  {
    title: "Q. How quickly do the likes appear on my posts?",
    content:
      "A. Likes start appearing immediately after your content is published, ensuring timely enhancement of your post’s engagement.",
  },
];
export const instagramRealOrganicLikesSeoAccordionHeading =
  "Frequently Asked Questions About Buying Real Instagram Likes";
export const instagramRealOrganicLikesSeoAccordion = [
  {
    title: "Q. How do I purchase Real Instagram Likes?",
    content:
      "A. Select the number of likes you need, ensure any previous like deliveries have been completed, and place your order. We’ll handle the rest, providing likes from real users.",
  },
  {
    title: "Q. Are these likes really from active Instagram accounts?",
    content:
      "A. Absolutely! Our service guarantees that every like is from a real, active user involved in manual promotions.",
  },
  {
    title: "Q. Is it safe to use this service for my Instagram account?",
    content:
      "A. Yes, it's completely safe. Our process is transparent and follows all Instagram guidelines to keep your account secure.",
  },
  {
    title: "Q. Can I order IG likes for multiple posts?",
    content:
      "A. Yes, you can distribute your purchased likes across multiple posts as long as the total does not exceed the number you've bought.",
  },
  {
    title: "Q. What if I have issues with my order?",
    content:
      "A. Our live chat support is ready to assist you 24/7 with any challenges you might face with your order.",
  },
  {
    title: "Q. How long does it take for the IG likes to appear on my posts?",
    content:
      "A. The likes begin to appear as soon as they are processed, which is typically very quick, ensuring your posts get timely engagement.",
  },
];
export const autoHighQualityLikesSeoAccordionHeading =
  "Frequently Asked Questions About High-Quality Auto Instagram Likes";
export const autoHighQualityLikesSeoAccordion = [
  {
    title: "Q. How can I buy Instagram likes for future posts?",
    content:
      "A. Choose a package based on how many future posts you want likes for, provide your Instagram username, and complete your purchase. Our system will automatically add likes to your new posts as they go live.",
  },
  {
    title: "Q. Are the IG likes real?",
    content:
      "A. Yes, we ensure that all likes come from real, active Instagram accounts. This helps boost your engagement authentically.",
  },
  {
    title: "Q. Is buying likes safe for my Instagram account?",
    content:
      "A. Absolutely. Our service is designed to be safe and compliant with Instagram's policies, ensuring your account remains secure.",
  },
  {
    title: "Q. Can I specify which posts receive likes?",
    content:
      "A. Our service automatically applies likes to the posts you make after your purchase, according to the number specified in your chosen package.",
  },
  {
    title: "Q. What happens if a post doesn't receive likes?",
    content:
      "A. If there's any issue with the delivery of likes, our 24/7 support team is on hand to resolve it promptly.",
  },
  {
    title: "Q. Do the instagram likes appear immediately after posting?",
    content:
      "A. Yes, the likes are designed to be instant, so they start appearing as soon as your post is published on Instagram.",
  },
];
