import React from "react";
import { homepageMedias, hompageCardRendering } from "../../utils";
import Card from "./components/Card";
import { Link } from "react-router-dom";
import { IoCaretForwardCircleSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import "./home.css";

const Index = () => {
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const displayUser = useSelector((state) => state.login.user);

  return (
    <div className="pt-4 lg:pt-6 md:pt-5 sm:pt-4 xs:pt-3">
      <div className="px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1 text-center flex flex-col gap-4">
        <p className="font-FiraSans text-2xl">
          Hello{" "}
          {isAuthenticated ? (
            <span>{displayUser?.user_name.toUpperCase()}</span>
          ) : (
            ""
          )}
          , we are
        </p>
        <p className="font-medium font-FiraSans text-5xl">Igcarts</p>
        <h1 className="font-bold font-FiraSans text-2xl text-center">
          Boost Your Social Media Presence with Real Likes, Comments, and Views.
        </h1>
        <p className="font-FiraSans text-lg">
          Igcarts is a powerful Instagram, Tiktok, Facebook, Youtube, Spotify
          Growth Service, grow your accounts, pages, and videos, all day, every
          day in a simple way
        </p>
      </div>
      {/*Authentication div*/}
      <div className="px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1 flex flex-col items-center text-white font-FiraSans gap-12 py-4">
        <Link to="/signup">
          <p className="px-8 py-3 rounded-xl inline-flex gap-3 items-center bg-gradient-to-r from-[#f49a39] via-yellow-400 to-[#fc8a24] text-3xl">
            Sign up
            <IoCaretForwardCircleSharp />
          </p>
        </Link>
        <Link to="/signin">
          <p className="px-9 py-3 rounded-xl inline-flex gap-4 items-center bg-gradient-to-r from-[#f49a39] via-yellow-400 to-[#fc8a24] text-3xl">
            Sign in
            <IoCaretForwardCircleSharp />
          </p>
        </Link>
      </div>
      <div className="px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1 py-4 space-y-6">
        <h2 className="text-center font-bold text-xl">
          Igcart Top ranking growth service to help you build a robust social
          media presence!
        </h2>
        <div className="flex xl:flex-row lg:flex-row flex-col xl:gap-6 gap-3 justify-center">
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 grid-cols-1 gap-2">
            <Link to="/ig_followers" className="btn instagram-gradient">
              Buy Instagram Followers
            </Link>
            <Link to="ig_real_oganic_likes" className="btn instagram-gradient">
              Buy Organic Instagram Likes
            </Link>
            <Link to="/instagram_views" className="btn instagram-gradient">
              Buy Instagram Views
            </Link>
            <Link to="/ig_auto_likes" className="btn instagram-gradient">
              Buy Instagram Auto Likes
            </Link>
          </div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 grid-cols-1 gap-2">
            <Link to="/tiktok_followers" className="btn tiktok-gradient">
              Buy TikTok Followers
            </Link>
            <Link to="/buy-tiktok-likes" className="btn tiktok-gradient">
              Buy TikTok Likes
            </Link>
            <Link to="/tiktok_views" className="btn tiktok-gradient">
              Buy TikTok Views
            </Link>
            <Link to="/tiktok_comments" className="btn tiktok-gradient">
              Buy Tiktok Comments
            </Link>
          </div>
        </div>
      </div>
      <div className="px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1 py-6">
        <h6 className="text-center font-bold font-FiraSans text-2xl sm:text-xl xs:text-xl md:text-xl pb-3">
          Why Choose Us For Your Social Media Growth?
        </h6>
        <div className="flex gap-3 lg:grid lg:grid-cols-3 lg:gap-y-4 lg:justify-items-center md:flex-wrap md:justify-center sm:flex-col sm:gap-3 xs:flex-col xs:gap-3 sm:items-center xs:items-center">
          {hompageCardRendering.map((item, i) => {
            return (
              <Card
                key={i}
                bgColor={item.bgColor}
                heading={item.heading}
                description={item.description}
                image={item.image}
              />
            );
          })}
        </div>
      </div>
      {/*Get started div*/}
      <div className="px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1 flex flex-col gap-4 py-10 bg-gradient-to-r from-gray-50 via-gray-100 to-gray-50 shadow-md">
        <h6 className="text-center font-bold font-FiraSans text-2xl sm:text-xl xs:text-xl md:text-xl">
          Get Started With The Most Reliable Social Media Growth Service
        </h6>
        <p className="font-FiraSans text-lg text-center">
          Experience exceptional growth across Instagram, Facebook, TikTok,
          YouTube, Spotify, and SoundCloud with our customized services designed
          to boost your online presence. Whether you're aiming to increase
          visibility, grow your follower base, or engage more effectively with
          your audience on multiple platforms, we're here to support your
          journey every step of the way.
        </p>
      </div>

      {/*Our service offering div*/}
      <div className="bg-[#140f0a] my-4">
        <div className="px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1 font-FiraSans text-white text-center py-12 lg:py-11 md:py-10 sm:py-9 xs:py-9">
          <p className="text-xl sm:text-lg xs:text-lg">Our Service Offering</p>
          <h3 className="font-bold text-4xl lg:text-3xl md:text-2xl sm:text-xl xs:text-xl py-10">
            We Support Most Popular Social Networks
          </h3>
          <div className="flex gap-5 justify-center sm:flex-wrap sm:gap-3 xs:flex-wrap xs:gap-3">
            {homepageMedias.map((icon, index) => {
              return (
                <a key={index} href="###">
                  <img
                    src={icon}
                    alt="Top social media sopanels works with"
                    className="h-24 w-24"
                  />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
