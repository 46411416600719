export const RealUSAFollowersReview = [
  {
    title:
      "Join hundreds of satisfied customers who have transformed their social media profiles by buying IG followers from Igcarts - Invest in building a vibrant community that grow with you ",
    content: [
      {
        reviewContent:
          "Buying 500 real USA followers from Igcarts was a game changer! Noticed immediate increase in engagement. super responsive customer service too!",
        name: "Kate",
        date: "2024",
      },
      {
        reviewContent:
          "Best experience with IgCarts! All IG followers were real and active. No drops and lots of engagement. Worth the investment!",
        name: "Josh",
        date: "2024",
      },
      {
        reviewContent:
          "Ordering IG followers from IgCarts was simple, and the followers came fast! They’re active and exactly my target audience. Highly recommend it for real Instagram growth!",
        name: "Gavin",
        date: "2024",
      },
    ],
  },
];
export const autoDailyFollowersReview = [
  {
    title: "",
    content: [
      {
        reviewContent:
          "Igcarts provided me with real Instagram auto followers, increasing my profile's visibility and engagement significantly. The process was easy, and the results were instant!",
        name: "Sarah",
        date: "2024",
      },
      {
        reviewContent:
          "Igcarts has been a game-changer for my Instagram strategy. The Instagram auto liker followers helped boost my content's reach and interaction effortlessly",
        name: "Derek",
        date: "2024",
      },
      {
        reviewContent:
          "I was skeptical about buying followers, but igcart's Instagram real auto followers service proved me wrong. Great results with real engagement!",
        name: "Mia",
        date: "2024",
      },
    ],
  },
];
export const autoInstagramLikesReview = [
  {
    title: "",
    content: [
      {
        reviewContent:
          "Igcarts provided me with real Instagram auto followers, increasing my profile's visibility and engagement significantly. The process was easy, and the results were instant!",
        name: "Sarah",
        date: "2024",
      },
      {
        reviewContent:
          "Igcarts has been a game-changer for my Instagram strategy. The Instagram auto liker followers helped boost my content's reach and interaction effortlessly",
        name: "Derek",
        date: "2024",
      },
      {
        reviewContent:
          "I was skeptical about buying followers, but igcart's Instagram real auto followers service proved me wrong. Great results with real engagement!",
        name: "Mia",
        date: "2024",
      },
    ],
  },
];
export const InstagramLikesReview = [
  {
    title: "Success Stories After Buying IG Likes ",
    content: [
      {
        reviewContent:
          "Seeing my likes increase immediately after posting has been fantastic for my engagement stats!",
        name: "Liam",
        date: "2024",
      },
      {
        reviewContent:
          "The instant likes make a huge difference in how quickly my posts gain traction. It's been great for growing my audience.",
        name: "Ava",
        date: "2024",
      },
      {
        reviewContent:
          "I've been using this service for months, and it's consistently reliable. My posts get the visibility they need right away",
        name: "Olivia",
        date: "2024",
      },
    ],
  },
];
export const InstagramRealOrganicLikesReview = [
  {
    title: "Success Stories After Buying Instagram Organic Likes",
    content: [
      {
        reviewContent:
          "Real Organic Likes has made a visible difference in how my posts are perceived. Real likes from real people—this service is a game changer!",
        name: "Ethan",
        date: "2024",
      },
      {
        reviewContent:
          "I was tired of services that used bots, but finding this was a breath of fresh air. My engagement rates have improved, and it's all genuine.",
        name: "Grace",
        date: "2024",
      },
      {
        reviewContent:
          "Every like comes from a real account, and you can tell. The engagement has increased not just in numbers but in quality interactions as well.",
        name: "Mason",
        date: "2024",
      },
    ],
  },
];
export const AutoHighQualityLikesReview = [
  {
    title: "Success Stories From Igcarts Auto IG Likes",
    content: [
      {
        reviewContent:
          "Auto likes have changed the game for my Instagram strategy. Signing up was easy, and the likes started appearing the minute my posts went live. Super impressed!",
        name: "Jessica",
        date: "2024",
      },
      {
        reviewContent:
          "The quality of Instagram likes I've received has been fantastic. Each post gets a noticeable boost, which has helped attract more organic engagement too.",
        name: "Lucas",
        date: "2024",
      },
      {
        reviewContent:
          "I rely on this service to keep my Instagram active and engaging. It's hassle-free, and the likes are always from real, interested Instagram users.",
        name: "Amelia",
        date: "2024",
      },
    ],
  },
];
