import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BACKEND_ENDPOINTS } from "../../utils/backenUrl";
import { transformServiceType } from "../../utils/transformServiceType";

//Thunk action for logging in
export const loginUser = createAsyncThunk(
  "login/loginUser",
  async (loginDetails, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/${BACKEND_ENDPOINTS.SIGNIN}`,
        loginDetails
      );
      if (response.data.status !== "OK") {
        throw new Error("something went wrong");
      }
      // Save token and timestamp to localStorage
      const token = response.data.data.token;
      localStorage.setItem("token", token);
      localStorage.setItem("tokenTimestamp", Date.now().toString());
      // Fetch user data
      const userDataResponse = await axios.get(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/user/profile`,
        {
          headers: {
            Authorization: `Bearer ${response.data.data.token}`,
            "content-type": "application/json; charset=utf-8",
          },
        }
      );
      return userDataResponse.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserDetails = createAsyncThunk(
  "login/updateUserAfterPayment",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const userDataUpdate = await axios.get(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/user/profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json; charset=utf-8",
          },
        }
      );
      // Transform service_type in order history data
      const transformedOrdersInfo = userDataUpdate.data.data.order_history.map(
        (order) => ({
          ...order,
          service_type: transformServiceType(order.service_type),
        })
      );

      const updatedUserData = {
        ...userDataUpdate.data.data,
        order_history: transformedOrdersInfo,
      };
      dispatch(setUser(updatedUserData));

      return updatedUserData;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState: {
    loginDetails: {
      email: "",
      password: "",
    },
    rememberMe: false,
    error: null,
    user: null,
  },

  reducers: {
    logoutUser: (state) => {
      localStorage.removeItem("token"); // Remove token from localStorage
      localStorage.removeItem("tokenTimestamp"); //Remove timestamp for calculating token duration from localStorage
      state.user = null; // Remove user from state and logout
    },
    setLoginDetails: (state, action) => {
      state.loginDetails = action.payload;
    },
    toggleRememberMe: (state) => {
      state.rememberMe = !state.rememberMe; // Toggle rememberMe state
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const {
  setLoginDetails,
  toggleRememberMe,
  setLoading,
  setError,
  setUser,
  logoutUser,
} = loginSlice.actions;
export default loginSlice.reducer;
