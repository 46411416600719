import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "../../../../components/shared/Button";
import { useDispatch, useSelector } from "react-redux";
import { sendAdminPasswordResetEmail } from "../../../../store/Slice/adminPasswordResetSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import responseMessages from "../../../../utils/responseMessages";

const AdminForgetPassword = () => {
  const dispatch = useDispatch();
  const { email } = useSelector((state) => state.adminPasswordReset);
  const passwordResetLoading = useSelector(
    (state) => state.adminPasswordReset.loading
  );
  const [error, setError] = useState(false);
  const initialValues = {
    email: email || "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().label("Email").email().required(),
  });

  const onSubmit = async (values, { resetForm }) => {
    try {
      const response = await dispatch(
        sendAdminPasswordResetEmail(values.email)
      );
      if (response.payload.code === 200 || response.payload.code === 201) {
        toast.success(responseMessages.passwordResetMessage);
      } else {
        setError(true);
      }
      resetForm();
    } catch (error) {
      toast.error(responseMessages.errorMessageForCatchBlock2);
    }
  };

  return (
    <div className="flex items-center justify-center py-20 min-h-screen bg-[#f5f6fa] gap-10">
      <div>
        <p className="font-extrabold font-Montserrat text-9xl lg:text-8xl md:text-7xl sm:text-6xl xs:text-6xl bg-gradient-to-r from-yellow-500  via-[#001f68] to-yellow-600 bg-clip-text text-transparent">
          Igcarts
        </p>
        <p className="text-end text-4xl italic font-semibold text-yellow-600">
          admin
        </p>
      </div>
      <div className="w-full max-w-md lg:px-4 md:px-3 sm:px-2 xs:px-2 py-6 bg-yellow-600 opacity-80 rounded-md shadow-md">
        <p className="text-center font-FiraSans font-bold text-2xl text-[#001f68] lg:text-xl md:text-xl sm:text-lg xs:text-lg py-3">
          Forgot Password?
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isValid, handleSubmit }) => (
            <Form
              className="px-8 lg:px-5 md:px-4 sm:px-3 xs:px-2 py-4"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <label
                htmlFor="email"
                className="block text-[#001f68] text-sm font-FiraSans font-semibold"
              >
                Email:
              </label>
              <Field
                type="email"
                placeholder="Enter email to get a reset link"
                id="email"
                name="email"
                className="shadow appearance-none border font-FiraSans border-gray-300 rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              />
              <ErrorMessage
                name="email"
                component="p"
                className="text-red-500 text-sm italic"
              />
              <div className="mt-8 flex justify-center">
                <Button
                  type="submit"
                  title={passwordResetLoading ? "Sending..." : "Get Link"}
                  variant={
                    isValid && !passwordResetLoading ? "secondary" : "disabled"
                  }
                  disabled={!isValid || passwordResetLoading}
                />
              </div>
              {error && (
                <p className="text-center text-red-500 text-sm italic">
                  {responseMessages.passwordResetFail}
                </p>
              )}
            </Form>
          )}
        </Formik>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AdminForgetPassword;
