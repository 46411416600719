import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Thunk action for sending password reset email
export const sendPasswordResetEmail = createAsyncThunk(
  "passwordReset/sendPasswordResetEmail",
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/auth/forget-password`,
        { email }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const passwordResetSlice = createSlice({
  name: "passwordReset",
  initialState: {
    email: "",
    loading: false,
    error: null,
    successMessage: null,
  },
  reducers: {
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
    requestPasswordReset: (state) => {
      state.loading = true;
      state.error = null;
    },
    passwordResetSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.successMessage = action.payload;
    },
    passwordResetFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendPasswordResetEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendPasswordResetEmail.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(sendPasswordResetEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  requestPasswordReset,
  passwordResetSuccess,
  passwordResetFailure,
} = passwordResetSlice.actions;

export default passwordResetSlice.reducer;
