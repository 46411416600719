import React, { useEffect, useState } from "react";
import Button from "../../components/shared/Button";
import { useDispatch, useSelector } from "react-redux";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import { ErrorModal, SuccessModal } from "../../components/shared/ToastModals";
import { useOutletContext } from "react-router-dom";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";

const AutoViews = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const [loading, setLoading] = useState(false);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [data, setData] = useState({
    link: [],
    quantity: [],
    numberOfPost: "",
    speedOfViews: "",
    likesQuantity: "",
    service_type: window.location.pathname,
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [message, setMessage] = useState("");

  const { link, numberOfPost, speedOfViews, likesQuantity } = data;
  const onChange = (e) => {
    let updated = {};
    if (e.target.name === "link") {
      updated = { [e.target.name]: [e.target.value] };
    } else {
      updated = { [e.target.name]: e.target.value };
    }
    setData((prevData) => ({
      ...prevData,
      ...updated,
    }));
    const value = { ...data, ...updated };
    const { numberOfPost, likesQuantity } = value;
    const total = numberOfPost * likesQuantity;
    setQuantity(total);
    setData((prevData) => ({
      ...prevData,
      quantity: [total],
    }));
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(data));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setData({
          link: [],
          quantity: [],
          numberOfPost: "",
          speedOfViews: "",
          likesQuantity: "",
          service_type: window.location.pathname,
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };
  return (
    <div className="font-FiraSans py-8 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want More Auto Views?
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Add Auto Views
      </h3>
      <div className="p-3 bg-blue-200 text-blue-500 rounded-lg">
        <p className="text-sm font-semibold sm:text-xs xs:text-xs">
          Choose number of future posts and auto views package
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          The views are instant for your future posts
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Press Only One Time on order button
        </p>
      </div>
      <form
        className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 py-6"
        onSubmit={onSubmit}
      >
        <div className="flex flex-col gap-8 lg:gap-6 md:gap-5 sm:gap-4 xs:gap-3">
          <div className="flex flex-col gap-1">
            <label className="font-FiraSans text-lg">Instagram Username</label>
            <input
              type="text"
              placeholder="Instagram Username"
              onChange={onChange}
              value={link}
              name="link"
              className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-FiraSans text-lg">Number of Posts</label>
            <select
              onChange={onChange}
              value={numberOfPost}
              name="numberOfPost"
              className="w-full px-2 py-3 rounded-md shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
            >
              <option value="" disabled selected>
                Select the number of posts you want to be auto viewed
              </option>
              {Array.from({ length: 50 }, (_, index) => index + 1).map(
                (count) => (
                  <option key={count} value={count}>
                    {count} - ({count} post{count !== 1 ? "s" : ""})
                  </option>
                )
              )}
            </select>
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-FiraSans text-lg">Auto Real Views</label>
            <select
              id="likes"
              onChange={onChange}
              value={likesQuantity}
              name="likesQuantity"
              className="w-full px-2 py-3 rounded-md shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
            >
              <option value="" disabled selected>
                Select quantity of views
              </option>
              <option value="100">100-120 Auto views</option>
              <option value="200">200-230 Auto views</option>
              <option value="300">300-330 Auto views</option>
              <option value="500">500-550 Auto views</option>
              <option value="600">600-660 Auto views</option>
              <option value="700">700-770 Auto views</option>
              <option value="800">800-880 Auto views</option>
              <option value="900">900-990 Auto views</option>
              <option value="1000">1000-1100 Auto views</option>
              <option value="1200">1200-1300 Auto views</option>
              <option value="1500">1500-1600 Auto views</option>
              <option value="1750">1750-1900 Auto views</option>
              <option value="2000">2000-2100 Auto views</option>
              <option value="2500">2500-2700 Auto views</option>
              <option value="3000">3000-3250 Auto views</option>
              <option value="3500">3500-3750 Auto views</option>
              <option value="4000">4000-4250 Auto views</option>
              <option value="4500">4500-4800 Auto views</option>
              <option value="5000">5000-5300 Auto views</option>
              <option value="6000">6000-6500 Auto views</option>
              <option value="7500">7500-8000 Auto views</option>
              <option value="9000">9000-9500 Auto views</option>
              <option value="10000">10000-10500 Auto views</option>
              <option value="11000">11000-11200 Auto views</option>
              <option value="12500">12500-12800 Auto views</option>
              <option value="13500">13500-13800 Auto views</option>
              <option value="15000">15000-15400 Auto views</option>
              <option value="17500">17500-18000 Auto views</option>
              <option value="20000">20000-20500 Auto views</option>
              <option value="25000">25000-25500 Auto views</option>
              <option value="30000">30000-30500 Auto views</option>
            </select>
          </div>
          <div className="flex flex-col gap-1">
            <label className="font-FiraSans text-lg">Speed of Views</label>
            <select
              name="speedOfViews"
              value={speedOfViews}
              onChange={onChange}
              className="w-full px-2 py-3 rounded-md shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
            >
              <option value="" disabled selected>
                Select Views speed
              </option>
              <option value="instant">Instant</option>
              <option value="10min">10 min</option>
              <option value="15min">15 min</option>
              <option value="30min">30 min</option>
              <option value="60min">60 min</option>
              <option value="90min">90 min</option>
              <option value="2hrs">2 hrs</option>
              <option value="3hrs">3 hrs</option>
              <option value="4hrs">4 hrs</option>
              <option value="5hrs">5 hrs</option>
              <option value="6hrs">6 hrs</option>
              <option value="7hrs">7 hrs</option>
              <option value="8hrs">8 hrs</option>
              <option value="9hrs">9 hrs</option>
              <option value="10hrs">10 hrs</option>
              <option value="11hrs">11 hrs</option>
              <option value="12hrs">12 hrs</option>
            </select>
          </div>
        </div>
        <div className="pt-10 flex justify-center">
          <Button
            title={loading ? "Sending views..." : "Send Views"}
            variant="primary"
            size="sm"
            disabled={
              !link ||
              !numberOfPost ||
              loading ||
              !isAuthenticated ||
              !likesQuantity ||
              !speedOfViews
            }
          />
        </div>
      </form>

      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />
    </div>
  );
};

export default AutoViews;
