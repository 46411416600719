import { combineReducers } from "redux";
import userReducer from "./Slice/userSlice";
import loginReducer from "./Slice/loginSlice";
import passwordResetReducer from "./Slice/passwordResetSlice";
import instagramServiceReducer from "./Slice/instagramServiceSlice";
import submitOrderReducer from "./Slice/submitOrderSlice";
import adminLoginReducer from "./Slice/adminLoginSlice";
import adminPasswordResetReducer from "./Slice/adminPasswordResetSlice";
const rootReducer = combineReducers({
  user: userReducer,
  login: loginReducer,
  passwordReset: passwordResetReducer,
  instagramService: instagramServiceReducer,
  submitOrder: submitOrderReducer,
  adminLogin: adminLoginReducer,
  adminPasswordReset: adminPasswordResetReducer,
  // Add other reducers here
});

export default rootReducer;
