import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "../../components/shared/Button";
import { useDispatch, useSelector } from "react-redux";
import { sendPasswordResetEmail } from "../../store/Slice/passwordResetSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import responseMessages from "../../utils/responseMessages";

const Index = () => {
  const dispatch = useDispatch();
  const { email } = useSelector((state) => state.passwordReset);
  const passwordResetLoading = useSelector(
    (state) => state.passwordReset.loading
  );
  const [error, setError] = useState(false);
  const initialValues = {
    email: email || "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().label("Email").email().required(),
  });

  const onSubmit = async (values, { resetForm }) => {
    try {
      const response = await dispatch(sendPasswordResetEmail(values.email));
      if (response.payload.code === 200) {
        toast.success(responseMessages.passwordResetMessage);
      } else {
        setError(true);
      }
      resetForm();
    } catch (error) {
      toast.error(responseMessages.errorMessageForCatchBlock2);
    }
  };

  return (
    <div className="flex items-center justify-center py-20">
      <div className="w-full max-w-md lg:px-4 md:px-3 sm:px-2 xs:px-2 py-6 bg-white rounded-md shadow-md">
        <p className="text-center font-FiraSans font-bold text-2xl lg:text-xl md:text-xl sm:text-lg xs:text-lg py-3">
          Forgot Password?
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isValid, handleSubmit }) => (
            <Form
              className="px-8 lg:px-5 md:px-4 sm:px-3 xs:px-2 py-4"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <label
                htmlFor="email"
                className="block text-black text-sm font-FiraSans font-semibold"
              >
                Email:
              </label>
              <Field
                type="email"
                placeholder="Enter email to get a reset link"
                id="email"
                name="email"
                className="shadow appearance-none border font-FiraSans border-gray-300 rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              />
              <ErrorMessage
                name="email"
                component="p"
                className="text-red-500 text-sm italic"
              />
              <div className="mt-8 flex justify-center">
                <Button
                  type="submit"
                  title={passwordResetLoading ? "Sending..." : "Get Link"}
                  variant={
                    isValid && !passwordResetLoading ? "primary" : "disabled"
                  }
                  disabled={!isValid || passwordResetLoading}
                />
              </div>
              {error && (
                <p className="text-center text-red-500 text-sm italic">
                  {responseMessages.passwordResetFail}
                </p>
              )}
            </Form>
          )}
        </Formik>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Index;
