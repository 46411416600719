import React, { useEffect, useState } from "react";
import Button from "../../components/shared/Button";
import { Link, useOutletContext } from "react-router-dom";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import {
  clearMedia,
  loadInstagramMedia,
} from "../../store/Slice/instagramServiceSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import FormAndButtonTwo from "../../components/shared/FormAndButtonTwo";
import { ErrorModal, SuccessModal } from "../../components/shared/ToastModals";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";

const IgCommentsFromFollowers = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { media } = useSelector((state) => state.instagramService);
  const [username, setUsername] = useState("");
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [isLoading, setIsLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    link: [],
    quantity: [],
    comment: "",
    service_type: window.location.pathname,
  });
  useEffect(() => {
    return () => {
      dispatch(clearMedia());
    };
  }, [dispatch]);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [message, setMessage] = useState("");

  const { comment } = orderDetails;

  const onChange = (e) => {
    const updated = { [e.target.name]: e.target.value };
    setOrderDetails({ ...orderDetails, ...updated });
    const value = { ...orderDetails, ...updated };
    const { comment } = value;
    setQuantity(comment / 10);
    setOrderDetails((prevData) => ({
      ...prevData,
      quantity: [comment],
    }));
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onClick = () => {
    dispatch(loadInstagramMedia(username));
  };

  const usernameOnChange = (e) => {
    setUsername(e.target.value);
  };

  const onMediaClick = (mediaLink) => {
    setOrderDetails({ ...orderDetails, link: [mediaLink] });
    console.log(orderDetails);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!media) {
      toast.info("Please load media first.");
      return;
    }
    try {
      setIsLoading(true);
      const response = await dispatch(orderSubmission(orderDetails));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        await dispatch(clearMedia());
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
        setUsername("");
        setOrderDetails({
          link: [],
          comment: "",
          service_type: window.location.pathname,
        });
        await dispatch(updateUserDetails());
      } else {
        setErrorModalVisible(true);
        setQuantity(0);
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setQuantity(0);
      setIsLoading(false);
      setErrorModalVisible(true);
      setSuccessModalVisible(false);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };
  return (
    <>
      <div className=" flex flex-col gap-[1rem] lg:gap-[2rem] xl:gap-[2.5rem] mt-4 mb-4 px-20 lg:px-6 md:px-4 sm:px-3 xs:px-2 py-6 sm:py-4 xs:py-2">
        <div className="flex flex-col items-center">
          {" "}
          {/**header starts */}
          <div className="text-center xs:text-base sm:text-lg md:text-xl text-2xl">
            Want More Real Comments From Your Followers?
          </div>
          <h2 className="text-center font-bold xs:text-[1rem] sm:text-[1.5rem] md:text-[1.7rem] text-[2.5rem] ">
            Add Instagram Real Comments From Your Followers
          </h2>
        </div>
        {/*header end here*/}
        {/* <p className="bg-[#f0f0f0] font-semibold w-max p-2">Rate per 10 :</p> */}
        {/*Banner starts here */}
        <div className="p-3 bg-[#c7e5f3] text-[#31708f] border-2 border-[#9acfea] rounded w-full ">
          <p className="sm:text-sm xs:text-xs pb-4 ">
            <span className="font-bold">
              A special service that does not exist anywhere else
            </span>
            <br /> 100% super real Instagram comments from accounts that have
            followed you
          </p>

          <p className="sm:text-sm xs:text-xs pb-4">
            {" "}
            <span className="font-bold"> Very important:</span> only if you
            ordered real followers from us - the comments will be from those
            followers
            <br /> To order real followers click here :
            <Link to="/real...." className="text-blue-800 font-semibold">
              {" "}
              Real USA Followers{" "}
            </Link>{" "}
          </p>

          <p className="sm:text-sm xs:text-xs">
            <span>
              {" "}
              All comments are related to posts and from Real and active
              accounts.
            </span>
            <br /> ENTER YOUR USERNAME AND SELECT THE NUMBER OF COMMENTS IN THE
            DROP DOWN MENU
          </p>
        </div>

        {/*form section */}
        <FormAndButtonTwo
          placeholder="Enter your Instagram Username"
          label="Instagram Username"
          onChange={usernameOnChange}
          value={username}
          onClick={onClick}
          mediaUrl={media}
          username={username}
          onMediaClick={onMediaClick} // Pass onMediaClick callback
        />

        <div className="flex flex-col px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-2">
          <label className="font-FiraSans text-lg">Package:</label>
          <select
            id="comment"
            name="comment"
            onChange={onChange}
            value={comment}
            className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 rounded-md"
          >
            <option value="" disabled selected>
              Select a package
            </option>
            <option value="10">10 real comments</option>
            <option value="15">15 real comments</option>
            <option value="20">20 real comments</option>
            <option value="25">25 real comments</option>
            <option value="30">30 real comments</option>
            <option value="50">50 real comments</option>
            <option value="75">75 real comments</option>
            <option value="100">100 real comments</option>
          </select>
        </div>

        <div className=" text-center px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 mt-5">
          <Button
            type="submit"
            title={isLoading ? "Sending order..." : "Send Order"}
            size="md"
            disabled={isLoading || !isAuthenticated || !comment}
            onClick={onSubmit}
          />
        </div>
        {/* Success Modal */}
        <SuccessModal
          visible={successModalVisible}
          onClose={() => setSuccessModalVisible(false)}
          message={message}
        />
        {/* Error Modal */}
        <ErrorModal
          visible={errorModalVisible}
          onClose={() => setErrorModalVisible(false)}
          message={message}
        />
        <ToastContainer />
      </div>
    </>
  );
};

export default IgCommentsFromFollowers;
