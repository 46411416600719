import React, { createContext, useContext, useState } from "react";

const AccordionContext = createContext();

export const useAccordionContext = () => {
  return useContext(AccordionContext);
};

export const AccordionProvider = ({ children }) => {
  const [accordionData, setAccordionData] = useState([]);
  const [accordionHeading, setAccordionHeading] = useState("");
  const [reviews, setReviews] = useState([]);

  return (
    <AccordionContext.Provider
      value={{
        accordionData,
        setAccordionData,
        accordionHeading,
        setAccordionHeading,
        reviews,
        setReviews,
      }}
    >
      {children}
    </AccordionContext.Provider>
  );
};
