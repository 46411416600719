import Services from "../Services";
import Main from "./main";

const index = () => {
  return (
    <div className=" h-screen overflow-auto flex md:flex-col-reverse sm:flex-col-reverse xs:flex-col-reverse  ">
      <div className="w-[30%] lg:w-[40%] overflow-scroll md:w-full sm:w-full xs:w-full">
        <Services />
      </div>
      <div className="w-[70%] lg:w-[60%] md:w-full sm:w-full xs:w-full">
        <Main />
      </div>
    </div>
  );
};

export default index;
