import React, { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import Button from "../../components/shared/Button";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  ErrorModal,
  InfoModal,
  SuccessModal,
} from "../../components/shared/ToastModals";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";

const TikTokViews = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [data, setData] = useState({
    link: [],
    quantity: [],
    service_type: window.location.pathname,
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const { link, quantity } = data;

  const onChange = (e) => {
    const updated = { [e.target.name]: [e.target.value] };
    setData({ ...data, ...updated });
    const value = { ...data, ...updated };
    const { quantity } = value;
    setQuantity(quantity);
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    if (quantity < 100) {
      setInfoModalVisible(true);
      setMessage(responseMessages.minimumQuantityOf100);
      return;
    }
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(data));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setData({
          link: [],
          quantity: [],
          service_type: window.location.pathname,
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessage);
    }
  };
  return (
    <div className="font-FiraSans py-4 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want More TikTok Views?
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Add TikTok Views
      </h3>
      <div className="p-3 bg-blue-200 text-blue-500 rounded-lg">
        <p className="text-sm sm:text-xs xs:text-xs">
          Enter your tiktok username
        </p>
        <p className="text-sm sm:text-xs xs:text-xs">
          Press Load media and add views amount to the video(s) you choose
        </p>
        <p className="text-sm sm:text-xs xs:text-xs">
          The minimum amount for other is 100 views.
        </p>
        <p className="text-sm sm:text-xs xs:text-xs">
          Live chat is available 24/7 for assistance
        </p>
        <Link to="/tiktok_likes" className="text-red-500 font-semibold block">
          Click here for instant tiktok likes.
        </Link>
        <Link to="/tiktok_views-link" className="text-red-500 font-semibold">
          Click here for tiktok views for link.
        </Link>
      </div>
      <form onSubmit={onSubmit}>
        <div className="flex flex-col gap-1 pt-6">
          <label className="block font-FiraSans text-lg">
            TikTok Post Link
          </label>
          <input
            type="text"
            placeholder="Enter your TikTok post link for views..."
            onChange={onChange}
            value={link}
            name="link"
            className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
          />
        </div>
        <div className="flex flex-col gap-1 py-4">
          <label className="block font-FiraSans text-lg">Number of Views</label>
          <input
            type="text"
            placeholder="Enter number of views..."
            onChange={onChange}
            value={quantity}
            name="quantity"
            className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
          />
        </div>
        <div className="flex justify-center py-3">
          <Button
            title={loading ? "Sending Views Order..." : "Add Views"}
            variant="primary"
            size="sm"
            disabled={loading || !isAuthenticated || !link || !quantity}
          />
        </div>
      </form>
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />
      {/* Info Modal */}
      <InfoModal
        visible={infoModalVisible}
        onClose={() => setInfoModalVisible(false)}
        message={message}
      />
    </div>
  );
};

export default TikTokViews;
