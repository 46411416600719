import React, { useEffect } from "react";
import Button from "../../components/shared/Button";
import TwoRowFrom from "../../components/shared/TwoRowForm";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import {
  ErrorModal,
  InfoModal,
  SuccessModal,
} from "../../components/shared/ToastModals";
import { useOutletContext } from "react-router-dom";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";

const TiktokLinkLikes = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState({
    link: [],
    quantity: [],
    service_type: window.location.pathname,
  });
  const { link, quantity } = data;

  const onChange = (e) => {
    const updated = { [e.target.name]: [e.target.value] };
    setData({ ...data, ...updated });
    const value = { ...data, ...updated };
    const { quantity } = value;
    setQuantity(quantity);
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    if (quantity < 100) {
      setInfoModalVisible(true);
      setMessage(responseMessages.minimumQuantityOf100);
      return;
    }
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(data));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setData({
          link: [],
          quantity: [],
          service_type: window.location.pathname,
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock2);
    }
  };

  return (
    <div className="font-FiraSans py-4 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want More TikTok Likes?
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Add TikTok Likes
      </h3>
      <div className="flex flex-col gap-4 p-3 bg-blue-200 text-blue-500 rounded-lg">
        <div>
          <p className="text-sm sm:text-xs xs:text-xs">
            Please Add TikTok Link.
          </p>
          <p className="text-sm sm:text-xs xs:text-xs">Add Likes number.</p>
          <p className="text-sm sm:text-xs xs:text-xs">
            Example : https:/vm.tiktok.com/QW8NBF/
          </p>
        </div>
        <div>
          <p className="text-sm sm:text-xs xs:text-xs">
            The minimum amount for order is 100 Likes.
          </p>
          <p className="text-sm sm:text-xs xs:text-xs">
            Live chat is available 24/7 for assistance
          </p>
        </div>
      </div>
      <form>
        <TwoRowFrom
          placeholderOne="TikTok Link"
          labelOne="TikTok Link"
          onChangeOne={onChange}
          valueOne={link}
          nameOne="link"
          labelTwo="Number of Likes"
          onChangeTwo={onChange}
          valueTwo={quantity}
          nameTwo="quantity"
        />
        <div className="flex justify-center py-3">
          <Button
            type="submit"
            title={loading ? "Sending..." : "Add likes"}
            variant="primary"
            size="sm"
            onClick={onSubmit}
            disabled={loading || !isAuthenticated || !link || !quantity}
          />
        </div>
      </form>
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />
      {/* Info Modal */}
      <InfoModal
        visible={infoModalVisible}
        onClose={() => setInfoModalVisible(false)}
        message={message}
      />
    </div>
  );
};

export default TiktokLinkLikes;
