import React from "react";
import rating from "../../assets/starRating.svg";

const CustomerReviewCard = ({ title, content }) => {
  return (
    <div className="mb-4">
      <h2 className="font-bold text-[#001f68] text-center text-xl mb-2">
        Customers Reviews and Feedback
      </h2>
      <h2 className="font-FiraSans font-medium text-xl lg:text-lg md:text-base sm:text-sm xs:text-sm text-[#2f477d] text-center mb-3">
        {title}
      </h2>
      <div className="relative flex overflow-x-hidden">
        <div className="flex gap-2 animate-marquee whitespace-nowrap">
          {content.map((item, index) => (
            <div
              key={index}
              className="w-[26rem] md:w-[23rem] sm:w-[20rem] xs:w-[18rem] shadow-md text-sm text-[#23527c] px-1 py-2 font-FiraSans bg-white flex justify-between gap-4 flex-col"
            >
              <p className="italic" style={{ textWrap: "wrap" }}>
                "{item.reviewContent}"
              </p>
              <div className="h-8 flex justify-between items-center">
                <img src={rating} alt="user's rating" className="w-24" />
                <p className="">
                  {item.name}, <span>{item.date}</span>
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="flex gap-2 animate-marquee2 whitespace-nowrap top-0 absolute">
          {content.map((item, index) => (
            <div
              key={index}
              className="w-[26rem] md:w-[23rem] sm:w-[20rem] xs:w-[18rem] shadow-md text-sm text-[#23527c] px-1 py-2 font-FiraSans bg-white flex justify-between gap-4 flex-col"
            >
              <p className="italic" style={{ textWrap: "wrap" }}>
                "{item.reviewContent}"
              </p>
              <div className="h-8 flex justify-between items-center">
                <img src={rating} alt="user's rating" className="w-24" />
                <p className="">
                  {item.name}, <span>{item.date}</span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomerReviewCard;
