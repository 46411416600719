import React from "react";

const Card = ({ heading, description, image, bgColor }) => {
  return (
    <div
      className="py-14 px-2 text-center w-[13rem] text-white"
      style={{ backgroundColor: bgColor }}
    >
      <div className="flex flex-col items-center justify-center gap-4">
        <h2 className="font-FiraSans font-bold text-lg h-[4rem]">{heading}</h2>
        <p className="font-FiraSans h-[12rem]">{description}</p>
        <img
          src={image}
          alt="A corresponding emoji or picture for the Card title"
          className="w-16 h-16"
        />
      </div>
    </div>
  );
};

export default Card;
