import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoginDetails,
  loginUser,
  toggleRememberMe,
  setUser,
} from "../../store/Slice/loginSlice";
import Button from "../../components/shared/Button";
import { Link, useNavigate } from "react-router-dom";
import responseMessages from "../../utils/responseMessages";

const Index = () => {
  const dispatch = useDispatch();
  const rememberMe = useSelector((state) => state.login.rememberMe);
  const loginDetails = useSelector((state) => state.login.loginDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [unfoundUser, setUnfoundUser] = useState(false);
  const initialValues = {
    email: rememberMe && loginDetails ? loginDetails.email || "" : "",
    password: rememberMe && loginDetails ? loginDetails.password || "" : "",
  };
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  useEffect(() => {
    const storedLoginDetails = JSON.parse(localStorage.getItem("loginDetails"));
    if (storedLoginDetails) {
      dispatch(setLoginDetails(storedLoginDetails));
    }
  }, [dispatch]);

  useEffect(() => {
    let timer;
    if (error || unfoundUser) {
      timer = setTimeout(() => {
        setError(false);
        setUnfoundUser(false);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [error, unfoundUser]);

  const handleLogin = async (values, { resetForm }) => {
    try {
      setIsLoading(true);

      const response = await dispatch(loginUser(values));
      if (rememberMe) {
        localStorage.setItem("loginDetails", JSON.stringify(values));
      } else {
        localStorage.removeItem("loginDetails");
      }
      const { data } = response.payload;
      if (response.payload.code === 200 || response.payload.code === 201) {
        navigate("/dashboard");
        await dispatch(setUser(data));
        resetForm();
      } else if (response.payload.message === "User not found") {
        setUnfoundUser(true);
      } else {
        setError(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center py-20">
      <div className="w-full max-w-md lg:px-4 md:px-3 sm:px-2 xs:px-2 bg-white rounded-md shadow-md">
        <p className="text-center font-FiraSans font-semibold text-2xl lg:text-xl md:text-xl sm:text-lg xs:text-lg py-3">
          Login To Igcarts
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          {({ handleSubmit, isValid, setFieldValue }) => (
            <Form
              className="px-8 lg:px-5 md:px-4 sm:px-3 xs:px-2 py-4"
              onSubmit={handleSubmit}
            >
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-black text-sm font-FiraSans font-bold mb-2"
                >
                  Email Address
                </label>
                <Field
                  type="email"
                  name="email"
                  id="email"
                  placeholder="user@email.com"
                  className={`shadow appearance-none border font-FiraSans rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${
                    isValid ? "border-gray-300" : "border-red-500"
                  }`}
                  onChange={(e) => {
                    setFieldValue("email", e.target.value);
                  }}
                />
                <ErrorMessage
                  name="email"
                  component="p"
                  className="text-red-500 text-sm italic"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-black text-sm font-FiraSans font-bold mb-2"
                >
                  Password
                </label>
                <Field
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  className={`shadow appearance-none border font-FiraSans rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${
                    isValid ? "border-gray-300" : "border-red-500"
                  }`}
                  onChange={(e) => {
                    setFieldValue("password", e.target.value);
                  }}
                />
                <ErrorMessage
                  name="password"
                  component="p"
                  className="text-red-500 text-sm italic"
                />
              </div>

              <div className="flex items-center justify-between">
                <div className="flex gap-1">
                  <input
                    type="checkbox"
                    id="rememberMe"
                    className="accent-blue-800"
                    checked={rememberMe}
                    onChange={() => dispatch(toggleRememberMe())}
                  />
                  <p className="text-blue-800 hover:text-blue-500 text-xs font-semibold">
                    Remember me
                  </p>
                </div>
                <span className="text-blue-800 hover:text-blue-500 text-xs font-normal">
                  Forgot Password?{" "}
                  <Link
                    to="/forget_password"
                    className="text-blue-800 hover:text-blue-500 font-semibold transition ease-in-out duration-300"
                  >
                    Reset
                  </Link>
                </span>
              </div>
              <div className="flex items-center justify-center mt-6">
                <Button
                  type="submit"
                  title={isLoading ? "logging in..." : "Login"}
                  variant={isValid ? "primary" : "disabled"}
                  disabled={!isValid || isLoading}
                />
              </div>
              {error && (
                <p className="text-center text-red-500 text-sm italic">
                  {responseMessages.loginErrorMessage}!
                </p>
              )}
              {unfoundUser && (
                <p className="text-center text-red-500 text-sm italic">
                  {responseMessages.userNotFound}
                </p>
              )}
              <div className="text-center py-2">
                <span className="text-blue-800 hover:text-blue-500 font-FiraSans text-xs font-normal">
                  Don't have an account yet?{" "}
                  <Link
                    to="/signup"
                    className="text-blue-800 hover:text-blue-500 font-semibold font-FiraSans transition ease-in-out duration-300"
                  >
                    Signup
                  </Link>
                </span>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Index;
