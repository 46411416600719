import React from "react";
import { Link } from "react-router-dom";
import { currentYear } from "../../utils";

const PrivacyPolicy = () => {
  return (
    <div className="px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1 font-FiraSans py-4">
      <div className="bg-[#ededed] px-6 py-4 shadow-md rounded">
        <h2 className="text-center text-2xl font-medium py-2">
          Privacy Policy
        </h2>
        <p className="text-[#333333]">
          This policy covers how we use your personal information. We take your
          privacy seriously and we take all measures to protect your personal
          information. Any personal information received will only be used to
          fill your order or services. We do not share, give, sell, rent or
          trade personal information with third parties for any reason. All
          information is encrypted and stored in secure servers until you ask
          for a deletion or deleted by our automated maintence cleanups.
        </p>
        <div className="py-3">
          <p className="text-[#333333]">
            Users have the right to be forgotten and have their accounts deleted
            anytime. Please contact us threw Live Chat, Email or visit your
            Account Settings to delete your account.
          </p>
          <p className="text-[#333333]">
            When deleting an account there is information that cannot be deleted
            for invoicing and taxing proposes.
          </p>
          <p className="text-[#333333]">
            We keep : Deposits information & orders.
          </p>
          <p className="text-[#333333]">
            All other information will be permanently deleted and cannot be
            restored
          </p>
        </div>
        <div>
          <h4 className="text-[#000000] font-semibold text-lg">
            How we use your Information
          </h4>
          <ul className="list-disc pl-5">
            <li>
              <b>Username</b>
              <p>
                To identify and Login your account, used also for Live Chat
                scripts if any ( Example: Crisp, Intercom , Zendesk ).
              </p>
            </li>
            <li>
              <b>Email</b>
              <p>
                The e-mail is used as main registration identifier including to
                login your account, send newsletter about services status or
                services changes, reset your password or verify your account,
                LiveChat Platforms ( Example : Crisp, Zendesk, Intercom ). We
                are not fans of spamming or either abusing by sending a bunch of
                e-mails.
              </p>
            </li>
            <li>
              <b>Phone Number</b>
              <p>
                The Phone is used to verify your account, Two factor
                Authentication, Send Important Notifications about our services
                status or changes ( Example: Price changes ). We will not send
                newsletter by SMS
              </p>
            </li>
            <li>
              <b>First and Last Name</b>
              <p>
                Used for Invoicing only and display on the website banner when
                you are logged in
              </p>
            </li>
            <li>
              <b>Country, Address and Postal Code</b>
              <p>
                Used for Invoicing and display messages or content based on your
                native language.
              </p>
            </li>
            <li>
              <b>VAT ID and Company Name</b>
              <p> Used for Invoicing only.</p>
            </li>
            <li>
              <b>IP Address</b>
              <p>
                The IP Address are logged when necessary including for : Login,
                Registration, Inserting orders, Performing Deposits, Password
                Resets. This is mostly to help us identify fraud and
                non-legitimate actions, nothing else.
              </p>
            </li>
            <li>
              <b>Order Information</b>
              <p>
                The order information including Links or any relative
                information about the orders are only used for process the
                order, orders are saved forever, for the nature of the service
                we keep this records to avoid abuse of the service,
                over-delivery, and statistics ( how much you have ordered for a
                certain link for example ).
              </p>
            </li>
            <li>
              <b>Link Tracking</b>
              <p>
                We track links from orders from time to time we only collect the
                actual counters necessary to control while the order is being
                delivered, once its completed we stop tracking. No other
                information is scrapped, only the counters. Example : How much
                plays,likes,followers,following a link has.
              </p>
            </li>
            <li>
              <b>Cookies</b>
              <p>
                We use cookies to improve your website experience ONLY The
                following data is saved on cookies: PHP Session ID, Tutorials
                Identifier, Device ID, Identifier to the Logged in Account, User
                ID, Timezone and Datatable Filters We only use cookies when its
                really necessary, information saved on cookies is not shared,
                given, sold by any means. Cookies are saved usually for a period
                of 1 Month and are mandatory in order to use the website.
              </p>
            </li>
          </ul>
        </div>
        <div>
          <h4 className="text-[#000000] font-semibold text-lg">
            External Connections
          </h4>
          <p className="py-3">
            IGCARTS connects with multiple third party services to deliver
            certain features, the following companies are responsible for the
            data inserted in their databases and services.
          </p>
          <ul className="list-disc pl-5">
            <li>
              <b>Crisp, Zendesk, Intercom &amp; Support Websites</b>
              <p>
                Your <b>username</b> and <b>email</b> are shared with these
                websites to delivery the support.
              </p>
              <p>
                <a
                  href="https://crisp.chat/en/privacy/"
                  className="text-blue-500"
                >
                  - Check Crisp Privacy
                </a>
              </p>
              <p>
                <a
                  href="https://docs.intercom.com/pricing-privacy-and-terms/privacy/intercom-inc-privacy-policy"
                  className="text-blue-500"
                >
                  - Check Intercom Privacy
                </a>
              </p>
              <p>
                <a
                  href="https://www.zendesk.com/company/customers-partners/privacy-policy/"
                  className="text-blue-500"
                >
                  - Check Zendesk Privacy
                </a>
              </p>
            </li>
            <li>
              <b>Google Analytics</b>
              <p> Analytics of the web-site done by Google, no information</p>
              <p>
                <a
                  href="https://policies.google.com/privacy"
                  className="text-blue-500"
                >
                  - Check Google Analytics Privacy
                </a>
              </p>
            </li>
            <li>
              <b>Twillio</b>
              <p>
                SMS Gateway Delivery, <b>Phone number</b> is used to delivery
                the SMS notifications, only logs are saved.
              </p>
              <a
                href="https://www.twilio.com/legal/privacy"
                className="text-blue-500"
              >
                - Check Twilio Privacy
              </a>
            </li>
            <li>
              <b>Mailchimp</b>
              <p>
                Newsletter &amp; E-Mail Notifications <b>first Name</b> and{" "}
                <b>email</b> is used to deliver the email notifications
              </p>
              <a
                href="https://mailchimp.com/legal/privacy/"
                className="text-blue-500"
              >
                - Check Mailchimp Privacy
              </a>
            </li>
          </ul>
        </div>
      </div>
      <p className="text-center text-xs py-5 text-[#3e3b36]">
        This terms were last updated at: Wednesday, 31-January-2024 15:00:00
        CEST
      </p>
      <div className="flex gap-8 md:flex-col px-6 md:gap-4 sm:flex-col sm:gap-3 xs:flex-col xs:gap-2">
        <div className="w-1/2 sm:w-full xs:w-full">
          <h5 className="text-[#f49a39] font-semibold">
            IGCARTS<sup>®</sup>
          </h5>
          <p>
            Our mission is to give small and big businesses or agencies the
            opportunity to build their online presence easily and with
            affordable prices. Providing more than 20k users worldwide, we work
            daily to make sure you are on the top of the latest solutions and
            tactics to lead the online marketing.
          </p>
        </div>
        <div className="w-1/2 sm:w-full xs:w-full">
          <h5 className="font-semibold">Links</h5>
          <ul className="flex flex-col">
            <Link to="/signin" className="text-[#f49a39]">
              Sign in
            </Link>
            <Link to="/signup" className="text-[#f49a39]">
              Sign up
            </Link>
          </ul>
        </div>
      </div>
      <p className="text-center py-6 text-[#3e3b36]">
        Copyright &#169; {currentYear} IGCARTS<sup>®</sup>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
