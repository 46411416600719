import React, { useState } from "react";
import Button from "./Button";
import { useSelector } from "react-redux";
import { MdInfo } from "react-icons/md";
import { toast } from "react-toastify";

const FormAndButtonTwo = ({
  placeholder,
  onChange,
  value,
  label,
  onClick,
  mediaUrl,
  username,
  onMediaClick, // New prop to handle media click
}) => {
  const [selectedMediaLink, setSelectedMediaLink] = useState(null);
  const { loading, message, error } = useSelector(
    (state) => state.instagramService
  );
  const handleMediaClick = (index, link) => {
    setSelectedMediaLink(link);
    onMediaClick(link);
  };
  return (
    <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-6">
      <div className="flex gap-6 lg:gap-4 xl:items-end lg:items-end md:items-end md:gap-2 sm:gap-2 sm:flex-col xs:gap-2 xs:flex-col">
        <div className="flex flex-col gap-1 flex-grow">
          <label className="block font-FiraSans text-lg">{label}</label>
          <input
            type="text"
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
          />
        </div>
        <div className="sm:flex sm:justify-center xs:flex xs:justify-center">
          <Button
            type="button"
            title={loading ? "Loading media..." : "Load Media"}
            variant="primary"
            size="sm"
            onClick={onClick}
            disabled={!username || loading}
          />
        </div>
      </div>
      {loading ? message : ""}
      {error ? toast.error(message) : ""}
      {mediaUrl && (
        <div>
          <p className="inline-flex items-center gap-2 mt-6 text-lg bg-gray-200 p-3 rounded-lg">
            <MdInfo size={22} className="text-green-600" />
            Click on a media
          </p>
          <div className="mt-4 grid grid-cols-4 gap-x-4 gap-y-4">
            {mediaUrl.map((url, index) => (
              <div key={index} className="">
                <img
                  src={url.image}
                  alt={`Media ${index}`}
                  onClick={() => handleMediaClick(index, url.link)}
                  className={`w-full max-w-xs mx-auto ${
                    selectedMediaLink === url.link ? "opacity-20" : ""
                  }`}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FormAndButtonTwo;
