import React, { useEffect, useState } from "react";
import Button from "../../../../components/shared/Button";
import { Modal } from "antd";
import responseMessages from "../../../../utils/responseMessages";
import Table from "../../../../components/shared/Table";
import {
  ErrorModal,
  SuccessModal,
} from "../../../../components/shared/ToastModals";
import { ApiService } from "../../../../services/apiService";

const AdminBlog = () => {
  const [loading, setLoading] = useState(false);
  const [adminBlogPost, setAdminBlogPost] = useState({
    heading: "",
    content: "",
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [messageShow, setMessageShow] = useState("");
  const [blogData, setBlogData] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedBlogId, setSelectedBlogId] = useState(null);
  const [editedBlogPost, setEditedBlogPost] = useState({
    blog_id: null,
    heading: "",
    content: "",
  });

  const { heading, content } = adminBlogPost;
  const fetchBlogAdmin = async () => {
    try {
      const response = await ApiService.adminBlogs();
      console.log("response is", response);
      setBlogData(response.data.data);
    } catch (error) {
      console.error("Error fetching blog data:", error);
      alert("Sorry, we encountered an error. Please try again later.");
    }
  };
  useEffect(() => {
    fetchBlogAdmin();
  }, []);

  const showDeleteModal = (blogId) => {
    setSelectedBlogId(blogId);
    setDeleteModalVisible(true);
  };
  const handleDelete = async () => {
    try {
      const response = await ApiService.deleteABlog(selectedBlogId);
      if (response.status === 200) {
        const updatedBlogData = blogData.filter(
          (blog) => blog.blog_id !== selectedBlogId
        );
        setBlogData(updatedBlogData);
        alert("Blog post deleted successfully.");
      } else {
        alert("Failed to delete blog post. Please try again.");
      }
      setDeleteModalVisible(false);
      setSelectedBlogId(null);
    } catch (error) {
      alert(responseMessages.errorMessage);
    }
  };

  const onChange = (e) => {
    setAdminBlogPost((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await ApiService.createBlog(adminBlogPost);
      setLoading(false);
      if (response.status === 200 || response.status === 201) {
        setSuccessModalVisible(true);
        setMessageShow("Your blog post has been successfully created.");
        setAdminBlogPost({ heading: "", content: "" });
        fetchBlogAdmin();
      } else {
        setErrorModalVisible(true);
        setMessageShow(
          "Your blog post cannot be created at this time, please try again."
        );
      }
    } catch (error) {
      setLoading(false);
      setErrorModalVisible(true);
      setMessageShow(responseMessages.errorMessage);
    }
  };

  const handleEdit = (blogId) => {
    const selectedBlog = blogData.find((blog) => blog.blog_id === blogId);
    if (selectedBlog) {
      setEditedBlogPost({
        blog_id: selectedBlog.blog_id,
        heading: selectedBlog.heading,
        content: selectedBlog.content,
      });
      setEditModalVisible(true);
    } else {
      console.error("Blog not found for editing.");
    }
  };
  const onEditChange = (e) => {
    setEditedBlogPost((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { heading, content } = editedBlogPost;
      const response = await ApiService.editBlog(editedBlogPost.blog_id, {
        heading,
        content,
      });
      setLoading(false);
      if (response.status === 200) {
        alert("Blog post updated successfully.");
        setEditModalVisible(false);
        fetchBlogAdmin();
      } else {
        alert("Failed to update blog post. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      alert(responseMessages.errorMessage);
    }
  };
  const closeModal = () => {
    setEditModalVisible(false);
    setEditedBlogPost({ heading: "", content: "" });
  };

  const blogpostDetails = [
    { name: "Heading", selector: (row) => row.heading },
    {
      name: "Edit",
      selector: (row) => (
        <button
          onClick={() => handleEdit(row.blog_id)}
          className="text-blue-700"
        >
          Edit
        </button>
      ),
    },
    {
      name: "Remove",
      selector: (row) => (
        <button
          onClick={() => showDeleteModal(row.blog_id)}
          className="text-red-700 italic"
        >
          Remove
        </button>
      ),
    },
  ];

  return (
    <div className="px-10">
      <div className="mb-5">
        <Table title="Posted Blogs" column={blogpostDetails} data={blogData} />
      </div>
      <h4 className="font-bold text-xl text-center">Create a blog post...</h4>
      <form
        onSubmit={onSubmit}
        className="flex flex-col gap-8 lg:gap-6 md:gap-5 sm:gap-4 xs:gap-3 w-full py-4"
      >
        <div className="flex flex-col gap-1">
          <label className="font-FiraSans text-lg"></label>
          <input
            type="text"
            placeholder="Put the blogpost heading here..."
            value={heading}
            onChange={onChange}
            name="heading"
            required
            className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="font-FiraSans text-lg"></label>
          <textarea
            rows="14"
            placeholder="Type in your blogpost content here..."
            value={content}
            onChange={onChange}
            name="content"
            required
            className="py-2 px-2 font-FiraSans resize-none outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
          />
        </div>
        <div className="flex justify-center">
          <Button
            type="submit"
            title={loading ? "Creating Post..." : "Create Post"}
            size="md"
            disabled={loading}
          />
        </div>
      </form>
      {/* Edit Modal */}
      <div
        className={`fixed inset-0 flex items-center justify-center bg-white bg-opacity-95 ${
          editModalVisible ? "block" : "hidden"
        }`}
      >
        <div className="w-full xl:px-12 lg:px-12 px-6">
          <span
            className="font-semibold text-3xl cursor-pointer absolute right-14"
            onClick={closeModal}
          >
            &times;
          </span>
          <h2 className="font-semibold text-xl text-center">Edit Blog Post</h2>
          <form onSubmit={handleEditSubmit}>
            <div className="flex flex-col gap-1">
              <label className="font-FiraSans text-lg"></label>
              <input
                type="text"
                placeholder="Put the blogpost heading here..."
                value={editedBlogPost.heading}
                onChange={onEditChange}
                name="heading"
                required
                className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="font-FiraSans text-lg"></label>
              <textarea
                rows="14"
                placeholder="Type in your blogpost content here..."
                value={editedBlogPost.content}
                onChange={onEditChange}
                name="content"
                required
                className="py-2 px-2 font-FiraSans resize-none outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
              />
            </div>
            <div className="flex justify-center mt-3">
              <Button
                type="submit"
                title={loading ? "Updating Post..." : "Update Post"}
                size="md"
                disabled={loading}
              />
            </div>
          </form>
        </div>
      </div>
      {/* Delete Confirmation Modal */}
      <Modal
        title="Confirm Delete"
        visible={deleteModalVisible}
        onOk={handleDelete}
        onCancel={() => setDeleteModalVisible(false)}
        okText="Delete"
        cancelText="Cancel"
        okButtonProps={{
          style: { backgroundColor: "#f44336", color: "#ffffff" },
        }}
        cancelButtonProps={{
          style: { backgroundColor: "#fc8a24", color: "#ffffff" },
        }}
      >
        <p>Are you sure you want to delete this blog post?</p>
      </Modal>
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={messageShow}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={messageShow}
      />
    </div>
  );
};

export default AdminBlog;

// import React, { useEffect, useState } from "react";
// import Button from "../../../../components/shared/Button";
// import { Modal } from "antd";
// import axios from "axios";
// import responseMessages from "../../../../utils/responseMessages";
// import Table from "../../../../components/shared/Table";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css"; // Import Quill styles
// import {
//   ErrorModal,
//   SuccessModal,
// } from "../../../../components/shared/ToastModals";
// import { formats, modules } from "../../../../utils/adminBlog";

// const AdminBlog = () => {
//   const [loading, setLoading] = useState(false);
//   const [adminBlogPost, setAdminBlogPost] = useState({
//     heading: "",
//     content: "",
//   });
//   const [successModalVisible, setSuccessModalVisible] = useState(false);
//   const [errorModalVisible, setErrorModalVisible] = useState(false);
//   const [messageShow, setMessageShow] = useState("");
//   const [blogData, setBlogData] = useState([]);
//   const [editModalVisible, setEditModalVisible] = useState(false);
//   const [deleteModalVisible, setDeleteModalVisible] = useState(false);
//   const [selectedBlogId, setSelectedBlogId] = useState(null);
//   const [editedBlogPost, setEditedBlogPost] = useState({
//     blog_id: null,
//     heading: "",
//     content: "",
//   });
//   const token = localStorage.getItem("adminToken");

//   const { heading, content } = adminBlogPost;

//   const fetchBlogAdmin = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_IGCARTS_BASE_URL}/blog/get-adminBlogs`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "content-type": "application/json; charset=utf-8",
//           },
//         }
//       );
//       setBlogData(response.data.data);
//     } catch (error) {
//       console.error("Error fetching blog data:", error);
//       alert("Sorry, we encountered an error. Please try again later.");
//     }
//   };

//   useEffect(() => {
//     fetchBlogAdmin();
//   }, []);

//   const showDeleteModal = (blogId) => {
//     setSelectedBlogId(blogId);
//     setDeleteModalVisible(true);
//   };

//   const handleDelete = async () => {
//     try {
//       const response = await axios.delete(
//         `${process.env.REACT_APP_IGCARTS_BASE_URL}/blog/delete-blog/${selectedBlogId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json; charset=utf-8",
//           },
//         }
//       );
//       if (response.status === 200) {
//         const updatedBlogData = blogData.filter(
//           (blog) => blog.blog_id !== selectedBlogId
//         );
//         setBlogData(updatedBlogData);
//         alert("Blog post deleted successfully.");
//       } else {
//         alert("Failed to delete blog post. Please try again.");
//       }
//       setDeleteModalVisible(false);
//       setSelectedBlogId(null);
//     } catch (error) {
//       alert(responseMessages.errorMessage);
//     }
//   };

//   const onChange = (e) => {
//     setAdminBlogPost((prevData) => ({
//       ...prevData,
//       [e.target.name]: e.target.value,
//     }));
//   };

//   const onContentChange = (value) => {
//     setAdminBlogPost((prevData) => ({
//       ...prevData,
//       content: value,
//     }));
//   };

//   const onSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       setLoading(true);
//       const response = await axios.post(
//         `${process.env.REACT_APP_IGCARTS_BASE_URL}/blog/create-blog`,
//         adminBlogPost,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json; charset=utf-8",
//           },
//         }
//       );
//       setLoading(false);
//       setAdminBlogPost({ heading: "", content: "" });
//       if (response.status === 200 || response.status === 201) {
//         setSuccessModalVisible(true);
//         setMessageShow("Your blog post has been successfully created.");
//         fetchBlogAdmin();
//       } else {
//         setErrorModalVisible(true);
//         setMessageShow(
//           "Your blog post cannot be created at this time, please try again."
//         );
//       }
//     } catch (error) {
//       setLoading(false);
//       setErrorModalVisible(true);
//       setMessageShow(responseMessages.errorMessage);
//     }
//   };

//   const handleEdit = (blogId) => {
//     const selectedBlog = blogData.find((blog) => blog.blog_id === blogId);
//     if (selectedBlog) {
//       setEditedBlogPost({
//         blog_id: selectedBlog.blog_id,
//         heading: selectedBlog.heading,
//         content: selectedBlog.content,
//       });
//       setEditModalVisible(true);
//     } else {
//       console.error("Blog not found for editing.");
//     }
//   };

//   const onEditChange = (e) => {
//     setEditedBlogPost((prevData) => ({
//       ...prevData,
//       [e.target.name]: e.target.value,
//     }));
//   };

//   const handleEditSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       setLoading(true);
//       const { heading, content } = editedBlogPost;
//       const response = await axios.patch(
//         `${process.env.REACT_APP_IGCARTS_BASE_URL}/blog/update-blog/${editedBlogPost.blog_id}`,
//         { heading, content },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json; charset=utf-8",
//           },
//         }
//       );
//       setLoading(false);
//       if (response.status === 200) {
//         alert("Blog post updated successfully.");
//         setEditModalVisible(false);
//         fetchBlogAdmin();
//       } else {
//         alert("Failed to update blog post. Please try again.");
//       }
//     } catch (error) {
//       setLoading(false);
//       alert(responseMessages.errorMessage);
//     }
//   };

//   const closeModal = () => {
//     setEditModalVisible(false);
//     setEditedBlogPost({ heading: "", content: "" });
//   };

//   const blogpostDetails = [
//     { name: "Heading", selector: (row) => row.heading },
//     {
//       name: "Edit",
//       selector: (row) => (
//         <button
//           onClick={() => handleEdit(row.blog_id)}
//           className="text-blue-700"
//         >
//           Edit
//         </button>
//       ),
//     },
//     {
//       name: "Remove",
//       selector: (row) => (
//         <button
//           onClick={() => showDeleteModal(row.blog_id)}
//           className="text-red-700 italic"
//         >
//           Remove
//         </button>
//       ),
//     },
//   ];

//   return (
//     <div className="px-10">
//       <div className="mb-5">
//         <Table title="Posted Blogs" column={blogpostDetails} data={blogData} />
//       </div>
//       <h4 className="font-bold text-xl text-center">Create a blog post...</h4>
//       <form
//         onSubmit={onSubmit}
//         className="flex flex-col gap-8 lg:gap-6 md:gap-5 sm:gap-4 xs:gap-3 w-full py-4"
//       >
//         <div className="flex flex-col gap-1">
//           <label className="font-FiraSans text-lg"></label>
//           <input
//             type="text"
//             placeholder="Put the blogpost heading here..."
//             value={heading}
//             onChange={onChange}
//             name="heading"
//             required
//             className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
//           />
//         </div>
//         <div className="flex flex-col gap-1">
//           <label className="font-FiraSans text-lg"></label>
//           <ReactQuill
//             theme="snow"
//             modules={modules}
//             formats={formats}
//             value={content}
//             onChange={onContentChange}
//             placeholder="content goes here..."
//             className="font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
//           />
//         </div>
//         <div className="flex justify-center">
//           <Button
//             type="submit"
//             title={loading ? "Creating Post..." : "Create Post"}
//             size="md"
//             disabled={loading}
//           />
//         </div>
//       </form>
//       {/* Modals for Edit and Delete */}
//       {/* Delete Confirmation Modal */}
//       <Modal
//         title="Confirm Delete"
//         visible={deleteModalVisible}
//         onOk={handleDelete}
//         onCancel={() => setDeleteModalVisible(false)}
//         okText="Delete"
//         cancelText="Cancel"
//         okButtonProps={{
//           style: { backgroundColor: "#f44336", color: "#ffffff" },
//         }}
//         cancelButtonProps={{
//           style: { backgroundColor: "#ccc", color: "#000" },
//         }}
//       >
//         <p>Are you sure you want to delete this blog post?</p>
//       </Modal>
// {/* Success Modal */}
// <SuccessModal
//   visible={successModalVisible}
//   onClose={() => setSuccessModalVisible(false)}
//   message={messageShow}
// />
// {/* Error Modal */}
// <ErrorModal
//   visible={errorModalVisible}
//   onClose={() => setErrorModalVisible(false)}
//   message={messageShow}
// />
//       {/* Edit Modal */}
//       <Modal
//         title="Edit Blog Post"
//         visible={editModalVisible}
//         onCancel={closeModal}
//         footer={[
//           <Button
//             key="submit"
//             onClick={handleEditSubmit}
//             disabled={loading}
//             title="Save Changes"
//           />,
//           <Button key="cancel" onClick={closeModal} title="Cancel" />,
//         ]}
//       >
//         <form className="flex flex-col gap-8 lg:gap-6 md:gap-5 sm:gap-4 xs:gap-3 w-full py-4">
//           <div className="flex flex-col gap-1">
//             <label className="font-FiraSans text-lg"></label>
//             <input
//               type="text"
//               placeholder="Put the blogpost heading here..."
//               value={editedBlogPost.heading}
//               onChange={onEditChange}
//               name="heading"
//               required
//               className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
//             />
//           </div>
//           <div className="flex flex-col gap-1">
//             <label className="font-FiraSans text-lg"></label>
//             <ReactQuill
//               theme="snow"
//               value={editedBlogPost.content}
//               onChange={(value) =>
//                 setEditedBlogPost((prev) => ({ ...prev, content: value }))
//               }
//               className="font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
//             />
//           </div>
//         </form>
//       </Modal>
//     </div>
//   );
// };

// export default AdminBlog;
