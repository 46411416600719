import React from "react";
import { Link } from "react-router-dom";

const Fallback = () => {
  return (
    <div className="px-16 flex items-center justify-center h-screen">
      <div className="text-center shadow-2xl py-6 h-80 flex flex-col gap-1 justify-center">
        <h1 className="text-red-700 text-2xl border-b">404 Page Not Found</h1>
        <p className="border-b px-4">
          The page you requested was not found. Double-check the URL or try
          searching for the content again.
        </p>
        <Link to="/" className="text-blue-800 text-lg">
          Go back to home
        </Link>
      </div>
    </div>
  );
};

export default Fallback;
