import React, { useState } from "react";
import { Link } from "react-router-dom";
import responseMessages from "../../utils/responseMessages";
import { ErrorModal, SuccessModal } from "../../components/shared/ToastModals";
import Button from "../../components/shared/Button";
import { ApiService } from "../../services/apiService";
import { FaLinkedin, FaInstagram } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

const Contactus = () => {
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    subject: "",
    body: "",
  });
  const [loading, setLoading] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [messageShow, setMessageShow] = useState("");

  const onChange = (e) => {
    setContactData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await ApiService.sendContactMessage(contactData);
      setContactData({ name: "", email: "", subject: "", body: "" });
      if (response.status === 200 || response.status === 201) {
        setSuccessModalVisible(true);
        setMessageShow("Your support message has been sent successfully.");
        setLoading(false);
      } else {
        setErrorModalVisible(true);
        setLoading(false);
        setMessageShow(
          "Your support message could not be sent. Please try again."
        );
      }
    } catch (error) {
      setLoading(false);
      setErrorModalVisible(true);
      setMessageShow(responseMessages.errorMessage);
    }
  };

  return (
    <div className="px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1 py-4">
      <h1 className="font-Montserrat text-xl font-bold text-center">
        Get In Touch
      </h1>
      <p className="text-lg text-center font-medium">
        We’re here to help! Whether you have questions about our services, need
        assistance with an order, or just want to say hello, our team is ready
        to assist you through our live chat or other means below{" "}
        <span className="italic text-blue-600">faqs</span>, forms or channels
        below.
      </p>
      <div className="py-6">
        <div className="shadow-lg font-FiraSans font-medium flex justify-center sm:flex-col xs:flex-col md:flex-col">
          <form onSubmit={onSubmit} className="xl:px-2 flex-1">
            <div className=" space-y-6 xl:p-6 p-4 rounded-md">
              <p className="text-[#f49a39] font-semibold">Drop a Message</p>
              <div className="flex gap-6 md:flex-col sm:flex-col xs:flex-col">
                <input
                  type="text"
                  placeholder="John Doe"
                  name="name"
                  value={contactData.name}
                  onChange={onChange}
                  className="border flex-1 rounded-md outline-none px-3 py-1"
                />
                <input
                  type="email"
                  placeholder="johndoe@example.com"
                  name="email"
                  value={contactData.email}
                  onChange={onChange}
                  className="border flex-1 rounded-md outline-none px-3 py-1"
                />
              </div>
              <input
                type="text"
                name="subject"
                value={contactData.subject}
                onChange={onChange}
                placeholder="Type the subject of your request here..."
                className="border w-full rounded-md outline-none px-3 py-1"
              />
              <textarea
                name="body"
                id=""
                rows="6"
                value={contactData.body}
                onChange={onChange}
                placeholder="Write your message here..."
                className="resize-none border rounded-md outline-none w-full px-3 py-1"
              ></textarea>
              <div className="flex justify-center">
                <Button
                  type="submit"
                  title={loading ? "Sending Message..." : "Send Message"}
                  size="md"
                  disabled={loading}
                />
              </div>
            </div>
          </form>
          <div className="flex-1 flex flex-col justify-  bg-[#f49a39] text-white h- p-4">
            <h2 className="font-bold">Contact Information</h2>
            <p className="flex gap-2 items-center">
              <IoMdMail />{" "}
              <a href="mailto:info@igcarts.com" className="text-blue-600">
                info@igcarts.com
              </a>{" "}
              ||{" "}
              <a href="mailto:realigcart@gmail.com" className="text-blue-600">
                realigcart@gmail.com
              </a>
            </p>
            <p>
              24/7 <strong>Support Hours</strong>
            </p>
            <ul className="flex gap-2">
              <li>
                <a
                  href="https://www.linkedin.com/company/igcarts/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin size={28} className="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/the_igcarts/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram size={28} className="" />
                </a>
              </li>
            </ul>
            <p>
              You can also check our{" "}
              <Link to="/faqs" className="text-blue-600 italic">
                faqs
              </Link>{" "}
              page for answers to common questions about our services, delivery
              times, and more.
            </p>
          </div>
        </div>
      </div>
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={messageShow}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={messageShow}
      />
    </div>
  );
};

export default Contactus;
