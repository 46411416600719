import React, { useEffect, useState } from "react";
import Table from "../../../../components/shared/Table";
import { ApiService } from "../../../../services/apiService";
import { formatTimestamp } from "../../../../utils";

const UsersOrders = () => {
  const [userOrders, setUserOrders] = useState([]);
  const token = localStorage.getItem("adminToken");

  useEffect(() => {
    const fetchUserOrders = async () => {
      try {
        const response = await ApiService.adminAllOrders();
        const userOrders = response.data.data;
        const formattedOrders = userOrders.map((user) => ({
          username: user.User_user_name || user["User.user_name"],
          servicetype: user.service_type,
          quantity: user.quantity,
          price: user.price,
          order: user.order,
          created_at: formatTimestamp(user.createdAt),
        }));
        setUserOrders(formattedOrders);
      } catch (error) {
        alert("An error occured while fetching all user orders");
      }
    };
    fetchUserOrders();
  }, [token]);
  const userorders = [
    { name: "Username", selector: (row) => row.username },
    { name: "Service Type", selector: (row) => row.servicetype },
    { name: "Quantity", selector: (row) => row.quantity },
    { name: "Price ($)", selector: (row) => row.price },
    { name: "Order", selector: (row) => row.order },
    { name: "Order Time", selector: (row) => row.created_at },
  ];
  return (
    <div>
      <Table title="User Orders" column={userorders} data={userOrders} />
    </div>
  );
};

export default UsersOrders;
