import React, { useEffect } from "react";
import Button from "../../components/shared/Button";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import {
  ErrorModal,
  InfoModal,
  SuccessModal,
} from "../../components/shared/ToastModals";
import { useOutletContext } from "react-router-dom";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";

const FbPageLikes = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const [data, setData] = useState({
    link: [],
    quantity: [],
    service_type: window.location.pathname,
  });
  const { link, quantity } = data;

  const onChange = (e) => {
    const updated = { [e.target.name]: [e.target.value] };
    setData({ ...data, ...updated });
    const value = { ...data, ...updated };
    const { quantity } = value;
    setQuantity(quantity);
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    if (quantity < 100) {
      setInfoModalVisible(true);
      setMessage(responseMessages.minimumQuantityOf100);
      return;
    }
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(data));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setData({
          service_type: window.location.pathname,
          quantity: [],
          link: [],
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };

  return (
    <>
      <div className=" flex flex-col gap-[1rem] lg:gap-[2rem] xl:gap-[2.5rem] mt-4 mb-4 px-20 lg:px-6 md:px-4 sm:px-3 xs:px-2">
        <div className="font-FiraSans">
          <h2 className="text-3xl font-bold  ">FACEBOOK PAGE LIKES</h2>
        </div>
        {/*header end here*/}
        {/*Banner starts here */}
        <div className="p-3 bg-[#c7e5f3] text-[#31708f] border-2 border-[#9acfea] rounded w-full ">
          <p className="sm:text-sm xs:text-xs ">
            Please. Add Full Page Link!
            <br /> Example : https://www.facebook.com/rihanna
            <br /> The Order will start within 1-3 hours
          </p>
        </div>

        {/*form section */}
        <div className="flex xs:flex-col sm:flex-col sm:gap-1 xs:gap-1 md:gap-5 gap-[10rem] ">
          <label className="font-FiraSans text-lg w-[13rem]">
            FB Page Link :
          </label>
          <div className="w-[50%] sm:w-full xs:w-full  ">
            <input
              type="text"
              placeholder="..."
              name="link"
              onChange={onChange}
              value={link}
              className="py-2 px-2 font-FiraSans outline-blue-800 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full w-full rounded-md"
            />
          </div>
        </div>
        <div className="flex xs:flex-col sm:flex-col sm:gap-1 xs:gap-1 md:gap-5 gap-[10rem] ">
          <label className="font-FiraSans text-lg w-[13rem] ">Likes :</label>
          <div className="w-[50%] sm:w-full xs:w-full ">
            <input
              type="number"
              placeholder="Enter the quantity to buy"
              name="quantity"
              onChange={onChange}
              value={quantity}
              className="py-2 px-2 font-FiraSans outline-blue-800 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full w-full rounded-md"
            />
          </div>
        </div>

        {/**action button */}
        <div className="text-center">
          <Button
            type="submit"
            title={loading ? "Sending..." : "Submit"}
            size="md"
            disabled={loading || !isAuthenticated}
            onClick={onSubmit}
          />
          {/* Success Modal */}
          <SuccessModal
            visible={successModalVisible}
            onClose={() => setSuccessModalVisible(false)}
            message={message}
          />
          {/* Error Modal */}
          <ErrorModal
            visible={errorModalVisible}
            onClose={() => setErrorModalVisible(false)}
            message={message}
          />{" "}
          {/* Info Modal */}
          <InfoModal
            visible={infoModalVisible}
            onClose={() => setInfoModalVisible(false)}
            message={message}
          />
        </div>
      </div>
    </>
  );
};

export default FbPageLikes;
