import React from "react";

const TwoRowForm = ({
  labelOne,
  placeholderOne,
  onChangeOne,
  valueOne,
  nameOne,
  labelTwo,
  placeholderTwo,
  onChangeTwo,
  valueTwo,
  nameTwo,
}) => {
  return (
    <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 py-6">
      <div className="flex flex-col gap-8 lg:gap-6 md:gap-5 sm:gap-4 xs:gap-3">
        <div className="flex flex-col gap-1">
          <label className="font-FiraSans text-lg">{labelOne}</label>
          <input
            type="text"
            placeholder={placeholderOne}
            onChange={onChangeOne}
            name={nameOne}
            value={valueOne}
            className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="font-FiraSans text-lg">{labelTwo}</label>
          <input
            type="number"
            placeholder={placeholderTwo}
            onChange={onChangeTwo}
            name={nameTwo}
            value={valueTwo}
            min="0"
            className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
          />
        </div>
      </div>
    </div>
  );
};

export default TwoRowForm;
