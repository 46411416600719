import React, { useEffect, useState } from "react";
import Button from "../../components/shared/Button";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import {
  clearMedia,
  loadInstagramMedia,
} from "../../store/Slice/instagramServiceSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { ToastContainer, toast } from "react-toastify";
import {
  ErrorModal,
  InfoModal,
  SuccessModal,
} from "../../components/shared/ToastModals";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";
import ManualCommentFormAndButton from "../../components/shared/ManualCommentFormAndButton";

const CustomManualRealComm = () => {
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { media } = useSelector((state) => state.instagramService);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    link: [],
    service_type: window.location.pathname,
    quantity: [],
    manualRealComment: [],
  });

  useEffect(() => {
    return () => {
      dispatch(clearMedia());
    };
  }, [dispatch]);

  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);

  const onClick = () => {
    dispatch(loadInstagramMedia(username));
  };

  const usernameOnChange = (e) => {
    setUsername(e.target.value);
  };

  const onCommentChange = (index, updatedComments, links, comments) => {
    // Update the manualRealComment object correctly
    const newManualRealComments = {
      ...orderDetails.manualRealComment,
      ...updatedComments,
    };

    // Create a new quantity array by iterating over the updated comment data for each link
    const newQuantity = links.map((link, i) => {
      // Match the link with the corresponding manualRealComment entry
      const commentKey = Object.keys(newManualRealComments)[i]; // Use the same index as `links`
      const comment = newManualRealComments[commentKey] || ""; // Use updated or existing comment

      // Normalize the comment and split by both `\n` and `|` to handle any delimiter used
      const lines = comment.split(/\n|\|/).filter((line) => line.trim() !== ""); // Filter out empty lines

      return lines.length; // Count the number of non-empty comment lines
    });

    // Filter links and quantities based on non-zero rows
    const filteredLinks = links.filter((_, i) => newQuantity[i] > 0);
    const filteredQuantity = newQuantity.filter((count) => count > 0);

    const totalQuantity =
      filteredQuantity.reduce((sum, numComments) => sum + numComments, 0) / 10;

    // Update the orderDetails with the correct manualRealComment and quantity
    setOrderDetails((prevOrderDetails) => ({
      ...prevOrderDetails,
      manualRealComment: newManualRealComments, // Updated comments object
      link: filteredLinks, // Filtered links array
      quantity: filteredQuantity, // Filtered quantities based on non-zero rows
    }));
    setQuantity(totalQuantity);
  };

  // const onCommentChange = (index, updatedComments, links, comments) => {
  //   // Update the manualRealComment object correctly
  //   const newManualRealComments = {
  //     ...orderDetails.manualRealComment,
  //     ...updatedComments,
  //   };

  //   // Create a new quantity array by iterating over the updated comment data for each link
  //   const newQuantity = links.map((link, i) => {
  //     // Match the link with the corresponding manualRealComment entry
  //     const commentKey = Object.keys(newManualRealComments)[i];
  //     const comment = newManualRealComments[commentKey] || "";

  //     // Normalize the comment and split by both `\n` and `|` to handle any delimiter used
  //     const lines = comment.split(/\n|\|/).filter((line) => line.trim() !== "");

  //     return lines.length;
  //   });

  //   // Filter links, quantities, and manualRealComment to remove empty comments
  //   const filteredLinks = links.filter((_, i) => newQuantity[i] > 0);
  //   const filteredQuantity = newQuantity.filter((count) => count > 0);
  // const filteredComments = Object.values(newManualRealComments).filter(
  //   (comment) => comment.trim() !== ""
  // );

  //   const totalQuantity =
  //     filteredQuantity.reduce((sum, numComments) => sum + numComments, 0) / 10;

  //   // Update orderDetails with filtered manualRealComment, link, and quantity
  //   setOrderDetails((prevOrderDetails) => ({
  //     ...prevOrderDetails,
  // manualRealComment: filteredComments, // Filtered comments without empty strings
  //     link: filteredLinks, // Filtered links array
  //     quantity: filteredQuantity, // Filtered quantities
  //   }));
  //   setQuantity(totalQuantity);
  // };

  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!media) {
  //     toast.info("Please load media first.");
  //     return;
  //   }

  //   // // Calculate the total number of comment rows by summing the values in `quantity`
  //   // const totalCommentRows = orderDetails.quantity.every(
  //   //   (quantity) => quantity > 10
  //   // );

  //   // if (totalCommentRows < 10) {
  //   //   setInfoModalVisible(true);
  //   //   setMessage(responseMessages.minimumQuantityManualComment);
  //   //   return;
  //   // }

  //   try {
  //     setLoading(true);
  //     const response = await dispatch(orderSubmission(orderDetails));
  //     if (response.payload.code === 200 || response.payload.code === 201) {
  //       setQuantity(0);
  //       setUsername("");
  //       await dispatch(clearMedia());
  //       setSuccessModalVisible(true);
  //       setMessage(responseMessages.isAvailableOrderMessage);
  //       await dispatch(updateUserDetails());
  //       setOrderDetails({
  //         link: [],
  //         service_type: window.location.pathname,
  //         manualRealComment: [],
  //         quantity: [],
  //       });
  //     } else {
  //       setQuantity(0);
  //       setErrorModalVisible(true);
  //       setMessage(responseMessages.orderFailure);
  //       await dispatch(clearMedia());
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     setQuantity(0);
  //     setLoading(false);
  //     setErrorModalVisible(true);
  //     await dispatch(clearMedia());
  //     setMessage(responseMessages.errorMessageForCatchBlock);
  //   } finally {
  //     await dispatch(clearMedia());
  //   }
  // };

  const onSubmit = async (e) => {
    console.log(orderDetails.quantity);
    e.preventDefault();
    if (!media) {
      toast.info("Please load media first.");
      return;
    }

    // Ensure `manualRealComment` is an array before submission
    const manualRealCommentArray = Array.isArray(orderDetails.manualRealComment)
      ? orderDetails.manualRealComment
      : Object.values(orderDetails.manualRealComment); // Convert object to array if necessary

    // Check if any of the quantities are less than 10
    const hasInvalidQuantity = orderDetails.quantity.some(
      (quantity) => quantity < 10
    );

    if (hasInvalidQuantity) {
      setInfoModalVisible(true);
      setMessage(responseMessages.minimumQuantityManualComment);
      return;
    }

    try {
      setLoading(true);

      // Prepare the orderDetails object with the corrected manualRealComment array
      const response = await dispatch(
        orderSubmission({
          ...orderDetails,
          manualRealComment: manualRealCommentArray, // Ensure array is sent
        })
      );

      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        setUsername("");
        await dispatch(clearMedia());
        setSuccessModalVisible(true);
        await dispatch(updateUserDetails());
        setMessage(responseMessages.isAvailableOrderMessage);
        setOrderDetails({
          link: [],
          service_type: window.location.pathname,
          manualRealComment: [],
          quantity: [],
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };

  return (
    <div className="font-FiraSans py-8 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want More Ig Custom Manual Comments?
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Add Ig Custom Manual Comments
      </h3>
      <div className="p-3 bg-blue-200 text-blue-500 rounded-lg">
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Kindly enter your comments, 1 comment per line and please do not use @
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Minimum 10 comments, Don’t add blank lines
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Most importantly kindly avoid sexist comments as Instagram does not
          allow such. If you add sexist comments by mistake, they won’t be
          posted. You can also add Emojis.
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs text-red-600">
          All comments are posted with US, English-speaking accounts
        </p>
      </div>
      <form>
        <ManualCommentFormAndButton
          label="Instagram Username"
          placeholder="Enter Instagram username"
          onChange={usernameOnChange}
          value={username}
          onClick={onClick}
          mediaUrl={media}
          username={username}
          onCommentChange={onCommentChange}
        />
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-10 flex justify-center">
          <Button
            type="submit"
            title={loading ? "Sending comments..." : "Send Comments"}
            variant="primary"
            size="sm"
            onClick={onSubmit}
            disabled={loading || !isAuthenticated}
          />
        </div>
      </form>
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />
      {/* Info Modal */}
      <InfoModal
        visible={infoModalVisible}
        onClose={() => setInfoModalVisible(false)}
        message={message}
      />{" "}
    </div>
  );
};

export default CustomManualRealComm;
