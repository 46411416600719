import React, { useEffect, useState } from "react";
import Button from "../../components/shared/Button";
import FormAndButtonTwo from "../../components/shared/FormAndButtonTwo";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import {
  clearMedia,
  loadInstagramMedia,
} from "../../store/Slice/instagramServiceSlice";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { setLoading, updateUserDetails } from "../../store/Slice/loginSlice";
import { ToastContainer, toast } from "react-toastify";
import {
  ErrorModal,
  InfoModal,
  SuccessModal,
} from "../../components/shared/ToastModals";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";

const InstaRealComments = () => {
  const dispatch = useDispatch();
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const { media } = useSelector((state) => state.instagramService);
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    link: [],
    comment: "",
    quantity: [],
    peopleCategory: "",
    service_type: window.location.pathname,
  });
  useEffect(() => {
    return () => {
      dispatch(clearMedia());
    };
  }, [dispatch]);
  const { comment, peopleCategory } = orderDetails;

  const onChange = (e) => {
    const updated = { [e.target.name]: e.target.value };
    setOrderDetails({ ...orderDetails, ...updated });
    const value = { ...orderDetails, ...updated };
    const { comment } = value;
    setQuantity(comment / 10);
    setOrderDetails((prevData) => ({
      ...prevData,
      quantity: [comment],
    }));
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onClick = () => {
    dispatch(loadInstagramMedia(username));
  };

  const usernameOnChange = (e) => {
    setUsername(e.target.value);
  };

  const onMediaClick = (mediaLink) => {
    setOrderDetails({ ...orderDetails, link: [mediaLink] });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!media) {
      toast.info("Please load media first.");
      return;
    }
    if (comment < 10) {
      setInfoModalVisible(true);
      setMessage(responseMessages.minimumQuantityOf10);
      return;
    }
    try {
      setIsLoading(true);
      const response = await dispatch(orderSubmission(orderDetails));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        await dispatch(clearMedia());
        setSuccessModalVisible(true);
        setMessage(responseMessages.isAvailableOrderMessage);
        setUsername("");
        setOrderDetails({
          link: [],
          comment: "",
          peopleCategory: "",
          service_type: window.location.pathname,
        });
        await dispatch(updateUserDetails());
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === '"link" is not allowed to be empty') {
          setMessage(responseMessages.linkClickMessage);
        } else if (response.payload.message === "Incorrect service ID") {
          setMessage(responseMessages.unavailableServiceDuetoId);
        } else if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setIsLoading(false);
      setQuantity(0);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock2);
    }
  };
  return (
    <div className="font-FiraSans py-8 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want More Real Comments?
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Add Instagram Real Comments
      </h3>
      <div className="p-3 bg-blue-200 text-blue-500 flex flex-col gap-3 rounded-lg">
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Real Instagram comment like never before. 100% REAL!!! NO BOTS
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          All comments are related to posts and from Real and active accounts.
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          None of its kind in the market.
        </p>
        <h4 className="text-sm sm:text-xs font-semibold xs:text-xs">
          ENTER YOUR USERNAME AND SELECT THE NUMBER OF COMMENTS IN THE DROP DOWN
          MENU
        </h4>
      </div>
      <FormAndButtonTwo
        placeholder="Enter your Instagram Username"
        label="Instagram Username"
        onChange={usernameOnChange}
        value={username}
        onClick={onClick}
        mediaUrl={media}
        username={username}
        onMediaClick={onMediaClick} // Pass onMediaClick callback
      />
      <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 mt-4">
        <label className="block">Package: </label>
        <select
          id="comment"
          name="comment"
          onChange={onChange}
          value={comment}
          className="w-2/3 px-2 sm:w-full xs:w-full py-3 rounded-lg shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
        >
          <option value="" disabled selected>
            Select number of comments
          </option>
          <option value="10">10 Real Comments</option>
          <option value="15">15 Real Comments</option>
          <option value="20">20 Real Comments</option>
          <option value="25">25 Real Comments</option>
          <option value="30">30 Real Comments</option>
          <option value="50">50 Real Comments</option>
          <option value="75">75 Real Comments</option>
          <option value="100">100 Real Comments</option>
        </select>
      </div>
      <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-6">
        <label className="block">Mix / Female / Male / African American</label>
        <select
          name="peopleCategory"
          id="peopleCategory"
          value={peopleCategory}
          onChange={onChange}
          className="w-2/3 px-2 sm:w-full xs:w-full py-3 rounded-lg shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
        >
          <option value="" disabled selected>
            Select Category
          </option>
          <option value="Mix">Mix</option>
          <option value="Female">Female</option>
          <option value="Male">Male</option>
          <option value="African American">African American</option>
        </select>
      </div>
      <div className=" text-center px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 mt-5">
        <Button
          type="submit"
          title={isLoading ? "Sending order..." : "Send Order"}
          size="md"
          disabled={
            isLoading || !comment || !peopleCategory || !isAuthenticated
          }
          onClick={onSubmit}
        />
      </div>
      <ToastContainer />
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />
      {/* Info Modal */}
      <InfoModal
        visible={infoModalVisible}
        onClose={() => setInfoModalVisible(false)}
        message={message}
      />
    </div>
  );
};

export default InstaRealComments;
