import React, { useState } from "react";
import Youtube from "./components/Youtube";
import { accordionRendering } from "../../utils";
import Accordion from "./components/Accordion";

const Index = () => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <div>
      <div className="py-4">
        <Youtube />
      </div>
      <div className="px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1 ">
        {accordionRendering.map((item, index) => (
          <Accordion
            key={index}
            title={item.title}
            content={item.content}
            isOpen={index === openAccordionIndex}
            onToggle={() => toggleAccordion(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Index;
