import adminApi from "./adminAuthInterceptor";
import api from "./authInterceptor";

export const ApiService = {
  sendContactMessage: async (data) => {
    try {
      const response = api.post("/contact-us", data);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getAllBlogs: async () => {
    try {
      const response = api.get("/blog/get-blog");

      return response;
    } catch (error) {
      throw error;
    }
  },
  getOneBlog: async (blog_id) => {
    try {
      const response = api.get(`/blog/get/${blog_id}`);

      return response;
    } catch (error) {
      throw error;
    }
  },

  dashboardNotice: async () => {
    try {
      const response = api.get("/notice/get");
      return response;
    } catch (error) {
      throw error;
    }
  },
  adminAllOrders: async () => {
    try {
      const response = adminApi.get("/social/get-all-orders");

      return response;
    } catch (error) {
      throw error;
    }
  },
  // admin blogs apis start
  createBlog: async (data) => {
    try {
      const response = adminApi.post("/blog/create-blog", data);

      return response;
    } catch (error) {
      throw error;
    }
  },
  editBlog: async (blog_id, data) => {
    try {
      const response = adminApi.patch(`/blog/update-blog/${blog_id}`, data);

      return response;
    } catch (error) {
      throw error;
    }
  },
  adminBlogs: async () => {
    try {
      const response = adminApi.get("/blog/get-adminBlogs");

      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteABlog: async (selectedBlogId) => {
    try {
      const response = adminApi.delete(`/blog/delete-blog/${selectedBlogId}`);

      return response;
    } catch (error) {
      throw error;
    }
  },
  // admin blog apis ends
  adminindividualUserOrders: async () => {
    try {
      const response = adminApi.get("/user/get-user-with-order");
      return response;
    } catch (error) {
      throw error;
    }
  },

  getAllUsers: async () => {
    try {
      const response = adminApi.get("/user/all-users");
      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteAUser: async (data) => {
    try {
      const response = adminApi.patch("/user/delete-user", data);
      return response;
    } catch (error) {
      throw error;
    }
  },
  editAUser: async (data) => {
    try {
      const response = adminApi.patch("/user/update-user", data);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
