import React, { useState } from "react";

const Impressions = () => {
  const [addImpressionsChecked, setAddImpressionsChecked] = useState(false);
  const [addCommentsChecked, setAddCommentsChecked] = useState(false);
  const [addRealFemaleCommentsChecked, setAddRealFemaleCommentsChecked] =
    useState(false);
  const [slowDeliveryChecked, setSlowDeliveryChecked] = useState(false);

  const handleAddImpressionsChange = () => {
    setAddImpressionsChecked(!addImpressionsChecked);
  };

  const handleAddCommentsChange = () => {
    setAddCommentsChecked(!addCommentsChecked);
    setAddRealFemaleCommentsChecked(false);
  };

  const handleAddRealFemaleCommentsChange = () => {
    setAddRealFemaleCommentsChecked(!addRealFemaleCommentsChecked);
    setAddCommentsChecked(false);
  };

  const handleSlowDeliveryChange = () => {
    setSlowDeliveryChecked(!slowDeliveryChecked);
  };
  return (
    <div className="flex gap-2 flex-col px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 py-6">
      <div className="flex gap-2 items-center">
        <input
          type="checkbox"
          name="addImpressions"
          className="accent-[#0075ff] "
          value="addImpressions"
          checked={addImpressionsChecked}
          onChange={handleAddImpressionsChange}
        />
        <label className="text-lg font-semibold" htmlFor="addImpressions">
          Plus Impressions
          {addImpressionsChecked && (
            <p className=" text-red-700 font-semibold text-sm">
              Auto HQ Likes Plus Impressions
            </p>
          )}
        </label>
      </div>
      <div className="flex gap-2 items-center">
        <input
          type="checkbox"
          name="addComments"
          className="accent-[#0075ff]"
          value="addRealComments"
          checked={addCommentsChecked}
          onChange={handleAddCommentsChange}
        />
        <label className="text-lg font-semibold" htmlFor="addComments">
          Plus 10 Real Comments on each post
          {addCommentsChecked && (
            <p className="text-red-700 font-semibold text-sm">
              Plus 10 Real Comments
            </p>
          )}
        </label>
      </div>
      <div className="flex gap-2 items-center">
        <input
          type="checkbox"
          name="addComments"
          className="accent-[#0075ff]"
          value="addRealFemaleComments"
          checked={addRealFemaleCommentsChecked}
          onChange={handleAddRealFemaleCommentsChange}
        />
        <label
          className="text-lg font-semibold"
          htmlFor="addRealFemaleComments"
        >
          Plus 10 Real female Comments on each post
          {addRealFemaleCommentsChecked && (
            <p className="text-red-700 font-semibold text-sm">
              Plus 10 Real Female Comments
            </p>
          )}
        </label>
      </div>
      <div className="flex gap-2 items-center">
        <input
          type="checkbox"
          name="slow-delivery"
          className="accent-[#0075ff]"
          value="slow-delivery"
          checked={slowDeliveryChecked}
          onChange={handleSlowDeliveryChange}
        />
        <div>
          <label className="text-lg font-semibold" htmlFor="slow-delivery">
            Slow Delivery
          </label>
          {slowDeliveryChecked && (
            <p className="text-red-700 font-semibold text-sm">
              Auto HQ Likes - Slow Delivery
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Impressions;
