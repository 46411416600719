import React, { useEffect, useState } from "react";
import Button from "../../components/shared/Button";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { ErrorModal, SuccessModal } from "../../components/shared/ToastModals";
import { useOutletContext } from "react-router-dom";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";

const AutoRealEngagement = () => {
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [data, setData] = useState({
    link: [],
    numberOfPost: "",
    quantity: [],
    engagementPackage: "",
    service_type: window.location.pathname,
  });
  const { link, numberOfPost, engagementPackage } = data;
  const onChange = (e) => {
    let updated = {};
    if (e.target.name === "link") {
      updated = { [e.target.name]: [e.target.value] };
    } else {
      updated = { [e.target.name]: e.target.value };
    }
    setData((prevData) => ({
      ...prevData,
      ...updated,
    }));
    const value = { ...data, ...updated };
    const { engagementPackage, numberOfPost } = value;
    if (engagementPackage === "Starter") {
      let total = 1.7 * numberOfPost;
      setQuantity(total);
      setData((prevData) => ({
        ...prevData,
        quantity: [total * 100],
      }));
    } else if (engagementPackage === "Regular") {
      let total = 3 * numberOfPost;
      setQuantity(total);
      setData((prevData) => ({
        ...prevData,
        quantity: [total * 100],
      }));
    } else if (engagementPackage === "Premium") {
      let total = 4.5 * numberOfPost;
      setQuantity(total);
      setData((prevData) => ({
        ...prevData,
        quantity: [total * 100],
      }));
    } else if (engagementPackage === "Advanced") {
      let total = 6.8 * numberOfPost;
      setQuantity(total);
      setData((prevData) => ({
        ...prevData,
        quantity: [total * 100],
      }));
    } else if (engagementPackage === "micro") {
      let total = 1 * numberOfPost;
      setQuantity(total);
      setData((prevData) => ({
        ...prevData,
        quantity: [total * 100],
      }));
    } else {
      setQuantity(0);
    }
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(data));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setData({
          link: "",
          numberOfPost: "",
          engagementPackage: "",
          service_type: window.location.pathname,
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };

  return (
    <div className="font-FiraSans py-8 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want More Engagements?
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Add Auto Engagements
      </h3>
      <div className="p-3 bg-blue-200 text-blue-500 rounded-lg flex flex-col gap-3">
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Finally, Boost your Instagram Page 100% Real!!!!!
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          After the Huge Instagram update, and Instagram changed algorithm we
          introduced this service with 100% Super Real Engagement, NOT BOTS!!!
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          None of its kind in the market.
        </p>
        <div>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            You get 100% real organic Likes, Comments and Followers for your
            posts
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            Promotions are Per post and we have 5 different packages as you see
            in the drop-down menu.
          </p>
        </div>
        <div>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            We have 5 packages:
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            Plan micro 8-12 comments 4-6 followers and 20-25 post likes
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            Plan starter 15-20 comments 7-10 followers 35-45 likes
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            Plan regular 30-35 comments 11-13 followers and 55-65 likes
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            plan premium 45-52 comments 15-18 followers and 80-90 likes
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            plan Advanced 65-72 comments 18-20 followers and 120-130 likes
          </p>
        </div>
      </div>
      <div className="pt-8 flex flex-col gap-6">
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1">
          <label className="font-FiraSans block text-lg">
            Instagram Username
          </label>
          <input
            type="text"
            value={link}
            name="link"
            onChange={onChange}
            placeholder="Enter your instagram username"
            className="w-full py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
          />
        </div>
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1">
          <label className="font-FiraSans text-lg block">Number of Posts</label>
          <select
            id="numberOfPost"
            value={numberOfPost}
            onChange={onChange}
            name="numberOfPost"
            className="w-full px-2 py-3 rounded-md shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
          >
            <option value="" disabled selected>
              Select the number of posts you want to be auto liked
            </option>
            {Array.from({ length: 50 }, (_, index) => index + 1).map(
              (count) => (
                <option key={count} value={count}>
                  {count} - ({count} post{count !== 1 ? "s" : ""})
                </option>
              )
            )}
          </select>
        </div>
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1">
          <label className="font-FiraSans text-lg block">
            Auto Ig Engagement
          </label>
          <select
            name="engagementPackage"
            value={engagementPackage}
            onChange={onChange}
            className="px-2 w-full py-3 rounded-lg shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
          >
            <option value="" disabled selected>
              Select the Engagement package
            </option>
            <option value="micro">
              Plan Micro: 8-12 comments, 4-6 followers and 20-25 post likes
            </option>
            <option value="Starter">
              Plan Starter: 15-20 comments, 7-10 followers and 35-45 post likes
            </option>
            <option value="Regular">
              Plan Regular: 30-35 comments, 11-13 followers and 55-65 post likes
            </option>
            <option value="Premium">
              Plan Premium: 45-52 comments, 15-18 followers and 80-90 post likes
            </option>
            <option value="Advanced">
              Plan Advanced: 65-72 comments, 18-20 followers and 120-130 post
              likes
            </option>
          </select>
        </div>
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-10 flex justify-center">
          <Button
            type="submit"
            title={loading ? "Sending" : "Order"}
            variant="primary"
            size="sm"
            disabled={
              !link ||
              !engagementPackage ||
              !numberOfPost ||
              loading ||
              !isAuthenticated
            }
            onClick={onSubmit}
          />
        </div>
      </div>
      <ToastContainer />
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />
    </div>
  );
};

export default AutoRealEngagement;
