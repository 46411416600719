import React from "react";
import Button from "../../components/shared/Button";
import ThreeRowForm from "../../components/shared/ThreeRowForm";
import Impressions from "../../components/shared/Impressions";

const AutoViewsPlusImpression = () => {
  return (
    <div className="font-FiraSans py-8 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want More Auto Views Plus Impressions?
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Add Auto Views Plus Impressions
      </h3>
      <div className="p-3 bg-blue-200 text-blue-500 rounded-lg">
        <p className="text-sm font-semibold sm:text-xs xs:text-xs">
          Choose number of future posts and auto views package
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          The views are instant for your future posts
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Order Organic views and get the same Impressions amount (Impressions -
          Total number of times your videos were viewed)
        </p>
      </div>
      <form>
        <ThreeRowForm
          labelOne="Instagram Username"
          placeholderOne="Instagram Username"
          labelTwo="Number of Posts"
          labelThree="Auto Ig Views"
        />
        <Impressions />
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-10 flex justify-center">
          <Button title="Order" variant="primary" size="sm" />
        </div>
      </form>
    </div>
  );
};

export default AutoViewsPlusImpression;
