import React from "react";
import { Modal } from "antd";

const DeleteModal = ({
  deleteModalVisible,
  hideDeleteModal,
  deleteServiceConfirmation,
}) => {
  return (
    <Modal
      title={<span style={{ color: "#f44336" }}>Confirm Deletion</span>}
      open={deleteModalVisible}
      onOk={deleteServiceConfirmation}
      onCancel={hideDeleteModal}
      okText="Delete"
      cancelText="Cancel"
      okButtonProps={{
        style: { backgroundColor: "#f44336", color: "#ffffff" },
      }}
      cancelButtonProps={{
        style: { backgroundColor: "#fc8a24", color: "#ffffff" },
      }}
    >
      <p className="font-semibold">
        Are you sure you want to delete this Auto service?
      </p>
    </Modal>
  );
};

export default DeleteModal;
