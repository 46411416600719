import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Thunk action for loading Instagram media
export const loadInstagramMedia = createAsyncThunk(
  "instagramService/loadMedia",
  async (username, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/social/getPosts/${username}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      );
      return response.data.data.images;
    } catch (error) {
      return rejectWithValue(error.response.message);
    }
  }
);

export const loadInstagramVideosOnly = createAsyncThunk(
  "instagramService/loadMedia",
  async (username, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/social/getPosts/${username}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      );
      const filteredMedia = response.data.data.images.filter(
        (item) => item.type === "video"
      );
      return filteredMedia;
    } catch (error) {
      return rejectWithValue(error.response.message);
    }
  }
);

export const clearMedia = () => {
  return {
    type: "instagramService/clearMedia",
  };
};
const initialState = {
  loading: false,
  error: null,
  media: null,
  message: null,
};

const instagramServiceSlice = createSlice({
  name: "instagramService",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      console.log(state.error);
    },
    setMedia: (state, action) => {
      state.media = action.payload;
    },
    clearMedia: (state) => {
      state.media = null; // Reset media state to null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadInstagramMedia.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = "Please Wait!, We're fetching your media";
      })
      .addCase(loadInstagramMedia.fulfilled, (state, action) => {
        state.loading = false;
        state.media = action.payload;
      })
      .addCase(loadInstagramMedia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.message = "Network Error!";
      });
  },
});
export const { setLoading, setError, setMedia, setLink } =
  instagramServiceSlice.actions;
export default instagramServiceSlice.reducer;
