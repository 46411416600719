import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BACKEND_ENDPOINTS } from "../../utils/backenUrl";

// Thunk action for signing up
export const signupUser = createAsyncThunk(
  "user/signupUser",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/${BACKEND_ENDPOINTS.SIGNUP}`,
        userData,
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: {
      email: "",
      user_name: "",
      password: "",
      referral_code: "",
    },
    loading: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signupUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(signupUser.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { setLoading } = userSlice.actions;
export default userSlice.reducer;
