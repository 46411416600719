import React, { useEffect } from "react";
import { CirclesWithBar } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateUserDetails } from "../../../../store/Slice/loginSlice";

const ConfirmPayment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const payment_referenceSaved = sessionStorage.getItem("paymentReference");
  const token = localStorage.getItem("token");
  useEffect(() => {
    const confirmPayment = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_IGCARTS_BASE_URL}/payment/confirm-payment?payment_reference=${payment_referenceSaved}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        );

        if (response.status === 200 || response.payload.code === 201) {
          dispatch(updateUserDetails());
          sessionStorage.removeItem("paymentReference");
          // Navigate to success page upon successful payment confirmation
          navigate("/dashboard/payment-success");
        }
      } catch (error) {}
    };

    confirmPayment();
  }, [navigate, payment_referenceSaved, dispatch]);

  return (
    <div className="flex justify-center">
      <div className="py-44">
        <CirclesWithBar
          height="80"
          width="230"
          color="#f49a39"
          ariaLabel="loading"
          style={{ borderRadius: 9 }}
        />
        <p className="text-center text-[#f49a39] pt-3">
          Confirming your payment...
        </p>
      </div>
    </div>
  );
};

export default ConfirmPayment;
