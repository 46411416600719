import IG from "../assets/download.jpg";
import tik from "../assets/tiktokLogo.jpg";
import spotify from "../assets/spotify.png";
import soundcloud from "../assets/soundcloud.png";
import facebook from "../assets/fb.png";
import sale from "../assets/sale.png";
import thread from "../assets/thread.png";
import youtube from "../assets/youtube.png";
import X from "../assets/X.jpeg";

export const services = [
  //Special Discount
  {
    title: "Special Discount Rate",
    img: sale,
    content: [
      {
        address: "/instagram_views",
        servicePrice: "100 Video Views : $0.15",
      },
      {
        address: "/ig_reels_views",
        servicePrice: "100 Reel Views : $0.15",
      },
      {
        address: "/thread_followers",
        servicePrice: "100 Threads Followers : $0.8",
      },
      {
        address: "/thread_likes",
        servicePrice: "100 Threads Likes : $0.4",
      },
      {
        address: "thread_comment",
        servicePrice: "10 Threads Comments : $3",
      },
      {
        address: "/tiktok_comment",
        servicePrice: "10 TikTok Comments : $5",
      },
      {
        address: "/tiktok_views",
        servicePrice: "100 TikTok Views : $0.15",
      },
      {
        address: "/tiktok_likes",
        servicePrice: "100 TikTok Likes : $0.35",
      },
      {
        address: "/tiktok_followers",
        servicePrice: "100 TikTok Followers : $0.8",
      },
    ],
  },
  // Instagram Services
  {
    title: "Instagram Services",
    img: IG,
    content: [
      {
        address: "/ig_reels_views",
        servicePrice: "100 Reels Views : $0.15",
      },
      {
        address: "/ig_real_oganic_likes",
        servicePrice: "100 Real Likes : $0.45",
      },
      {
        address: "/ig_real_organic_comments",
        servicePrice: "10 Real Comments : $5",
      },
      {
        address: "/real_organic_usa_story_views",
        servicePrice: "100 USA Story Views : $0.5",
      },
      {
        address: "/ig_high_quality_fast_likes",
        servicePrice: "100 High Quality Likes - None Drop : $0.35",
      },
      {
        address: "/instagram_likes",
        servicePrice: `100 Instagram Likes : $0.3`,
      },
      {
        address: "/custom_manually_real_comments",
        servicePrice: "10 Custom Real Comments : $5",
      },
      {
        address: "/ig_reels_likes",
        servicePrice: "100 Reels Likes : $0.3",
      },
      {
        address: "/ig_reels_real_organic_likes",
        servicePrice: "100 Reels Real Likes : $0.45",
      },
      {
        address: "/ig_reels_real_organic_comments",
        servicePrice: "10 Reels Real Comments : $5",
      },
      {
        address: "/real_comments_from_your_followers",
        servicePrice: "10 Real Comments from your Followers : $8",
      },
      {
        address: "/real_usa_followers",
        servicePrice: "100 Real USA Followers : $2.5",
      },
      {
        address: "/ig_lives_view",
        servicePrice: "100 Instagram Live Views : $2.4",
      },
      {
        address: "/instagram_views",
        servicePrice: "100 Video Views : $0.15",
      },
      {
        address: "/igtv_views",
        servicePrice: "100 IGTV Views : $0.15",
      },
      {
        address: "/ig_followers",
        servicePrice: "100 Instagram Followers : $0.65",
      },
      {
        address: "/real_likes_from_your_followers",
        servicePrice: "100 Real Like from your Followers : $1.2",
      },
      {
        address: "/ig_story_views",
        servicePrice: "100 Story Views : $0.25",
      },
      {
        address: "/ig_impressions",
        servicePrice: "100 Instagram Impressions : $0.1",
      },
      {
        address: "/ig_saves",
        servicePrice: "100 Instagram Saves : $0.15",
      },
      {
        address: "/ig_profile_visits",
        servicePrice: "100 Profile Visits : $0.3",
      },
      {
        address: "/instagram_mentions",
        servicePrice: "100 Instagram Mentions : $3",
      },
      {
        address: "/ig_real_engagements",
        servicePrice: "10 Real Engagement : $3",
      },
      {
        address: "/comments_from_verified_accounts",
        servicePrice: "10 Instagram Comments from Verified Accounts : $20",
      },
    ],
  },
  //Instagram Auto Services
  {
    title: "Instagram Automatic Services",
    img: IG,
    content: [
      {
        address: "/auto_likes",
        servicePrice: "100 Instagram Auto Likes : $0.3",
      },
      {
        address: "/auto_real_likes",
        servicePrice: "100 Auto Real Organic Likes : $0.45",
      },
      {
        address: "/auto_real_comments",
        servicePrice: "10 Auto Real comments : $5",
      },
      {
        address: "/auto_daily_real_usa_followers",
        servicePrice: "100 Auto Daily Real USA Followers - None Drop : $2.5",
      },
      {
        address: "/auto_real_engagements",
        servicePrice: "Auto Real Engagements Plan Micro : $3",
      },
      {
        address: "/auto_daily_followers",
        servicePrice: "100 Auto Daily Followers : $0.65",
      },
      {
        address: "/auto_views",
        servicePrice: "100 Auto Video Views : $0.15",
      },
      {
        address: "/auto_ig_real_package",
        servicePrice: "Instagram Real Package : $5.5",
      },
      {
        address: "/ig_auto_high_quality_none_drop_likes",
        servicePrice: "100 Auto Quality Likes - None Drop : $0.4",
      },
    ],
  },
  // Tiktok Services
  {
    title: "Tiktok",
    img: tik,
    content: [
      {
        address: "/tiktok_comments",
        servicePrice: "10 TikTok Comments : $5",
      },
      {
        address: "/tiktok_views",
        servicePrice: "100 TikTok Views : $0.15",
      },
      {
        address: "/tiktok_likes",
        servicePrice: "100 TikTok Likes : $0.35",
      },
      {
        address: "/tiktok_followers",
        servicePrice: "100 TikTok Followers : $0.8",
      },
    ],
  },
  //Thread Services
  {
    title: "Thread Services",
    img: thread,
    content: [
      {
        address: "/thread_followers",
        servicePrice: "100 Threads Followers : $0.8",
      },
      {
        address: "/thread_likes",
        servicePrice: "100 Threads Likes : $0.4",
      },
      {
        address: "/thread_comment",
        servicePrice: "10 Threads Comments : $3",
      },
    ],
  },
  //Facebook Services
  {
    title: "Facebook",
    img: facebook,
    content: [
      {
        address: "/facebook_video_views",
        servicePrice: "100 Facebook Video Views : $0.3",
      },
      {
        address: "/facebook_post_likes",
        servicePrice: "100 Facebook Post Likes : $0.5",
      },
      {
        address: "/facebook_page_likes",
        servicePrice: "100 Facebook Page Likes : $0.8",
      },
    ],
  },
  // Youtube Services
  {
    title: "Youtube",
    img: youtube,
    content: [
      {
        address: "/youtube_views",
        servicePrice: "1000 YouTube Views : $3.5",
      },
      {
        address: "/youtube_likes",
        servicePrice: "100 YouTube Likes : $0.8",
      },
      {
        address: "/youtube_subscribers",
        servicePrice: "100 YouTube Subscribers : $5",
      },
    ],
  },
  // Spotify Services
  {
    title: "Spotify",
    img: spotify,
    content: [
      {
        address: "/spotify_plays",
        servicePrice: "1000 Spotify Plays : $5",
      },
      {
        address: "/spotify_followers",
        servicePrice: "100 Spotify Followers : $1.7",
      },
      {
        address: "/spotify_playlist_followers",
        servicePrice: "100 Spotify - Playlist Followers : $2",
      },
    ],
  },
  // SoundCloud Services
  {
    title: "Soundcloud",
    img: soundcloud,
    content: [
      {
        address: "/soundcloud_play",
        servicePrice: "1000 SoundCloud Plays : $1.5",
      },
      {
        address: "/soundcloud_likes",
        servicePrice: "100 SoundCloud Likes : $0.3",
      },
    ],
  },
  //Twitter(X) Services
  {
    title: "Twitter-X",
    img: X,
    content: [
      {
        address: "	/twitter_followers",
        servicePrice: "100 Twitter(X) Followers : $1",
      },
    ],
  },
];
