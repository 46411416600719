import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import {
  BalanceBeforeAndAfterTwo,
  RateAndPriceTwo,
} from "./shared/BalanceBeforeAndAfter";
import SEOAccordionList from "../SEO/SEOAccordionList";

const PackageBalanceLayout = () => {
  const [quantity, setQuantity] = useState(0);
  useEffect(() => {
    setQuantity(0);
  }, []);
  return (
    <div>
      <RateAndPriceTwo />
      <Outlet context={{ setQuantity }} />
      <BalanceBeforeAndAfterTwo quantity={quantity} />
      <SEOAccordionList />
    </div>
  );
};

export default PackageBalanceLayout;
