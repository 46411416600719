import React, { useEffect, useState } from "react";
import TwoRowForm from "../../components/shared/TwoRowForm";
import Button from "../../components/shared/Button";
import { useDispatch, useSelector } from "react-redux";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import {
  ErrorModal,
  InfoModal,
  SuccessModal,
} from "../../components/shared/ToastModals";
import { useOutletContext } from "react-router-dom";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";
const IgLiveViews = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [data, setData] = useState({
    service_type: window.location.pathname,
    link: [],
    quantity: [],
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const { link, quantity } = data;
  const onChange = (e) => {
    const updated = { [e.target.name]: [e.target.value] };
    setData({ ...data, ...updated });
    const value = { ...data, ...updated };
    const { quantity } = value;
    setQuantity(quantity);
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    if (quantity < 100) {
      setInfoModalVisible(true);
      setMessage(responseMessages.minimumQuantityOf100);
      return;
    }
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(data));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setData({
          link: [],
          quantity: [],
          service_type: window.location.pathname,
        });
      } else {
        setErrorModalVisible(true);
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };
  return (
    <div className="font-FiraSans py-8 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Buy IG Live Views
      </h3>
      <div className="p-3 bg-blue-200 text-blue-500 flex flex-col gap-4 rounded-lg">
        <div>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            Real Instagram Live views with Hearts (Likes)...Also with a
            possibility of becoming Top Live
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            Real live views come after 4-8 minutes with likes!!!
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            Don't close until you see the live views coming
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs text-red-600">
            Ensure you only add the order once (E.g if you need 1000 lives views
            order it once and not 200, 300, 500)
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            The viewers will stay for at least one hour
          </p>
          <p className="text-sm sm:text-xs font-semibold xs:text-xs">
            Minimum 250 views for an order
          </p>
        </div>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs text-red-600">
          Please order when you are already live or chat with us when you are
          going live. we can't start your order before you are in live
        </p>
      </div>
      <TwoRowForm
        labelOne="Instagram Username"
        placeholderOne="Enter your Instagram Username"
        placeholderTwo="1000"
        onChangeOne={onChange}
        valueOne={link}
        nameOne="link"
        onChangeTwo={onChange}
        valueTwo={quantity}
        nameTwo="quantity"
        labelTwo="Number of live views"
      />
      <div className="text-center pt-4">
        <Button
          type="submit"
          title={loading ? "Sending..." : "Submit"}
          size="md"
          onClick={onSubmit}
          disabled={!link || !quantity || loading || !isAuthenticated}
        />
      </div>
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />
      {/* Info Modal */}
      <InfoModal
        visible={infoModalVisible}
        onClose={() => setInfoModalVisible(false)}
        message={message}
      />
    </div>
  );
};

export default IgLiveViews;
