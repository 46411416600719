import React, { useState } from "react";
import Accordion from "./Accordion";
import { services } from "../../utils/Services";

const Index = () => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <div className="px-20 lg:px-6 md:px-4 sm:px-3 xs:px-2 py-8 sm:py-4 xs:py-3 ">
        <div className="p-4 sm:px-2 xs:px-1 shadow-xl  rounded-xl ">
          <button className="w-full text-xl bg-[#fcbb45] text-[#001f68] rounded-xl shadow-xl hover:text-[#23527c] p-4 font-FiraSans font-bold">
            <h3>Click on the Icon below to see our services</h3>
          </button>

          {services.map((item, index) => (
            <Accordion
              key={index}
              title={item.title}
              image={item.img}
              value={item.content}
              isOpen={index === openAccordionIndex}
              onToggle={() => toggleAccordion(index)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Index;
