import React from "react";

const index = () => {
  return (
    <div className="flex flex-col gap-5 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1 ">
      {/*<div>
        <p className="text-lg text-blue-800 font-FiraSans font-semibold sm:text-base xs:text-base sm:font-medium xs:font-medium">
          HOW TO USE IGCARTS
        </p>
        <iframe
          src="https://www.youtube.com/embed/09-D8SEy_sg"
          title="How to use Igcarts to add Likes, Views, Comments, Followers and many more..............."
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="bg-white p-3 shadow-md w-full xl:w-[45rem] xl:h-[26rem] lg:w-[40rem] lg:h-[22rem] md:w-[36rem] md:h-[20rem]"
        ></iframe>
  </div>*/}
      <div>
        <p className="text-lg text-blue-800 font-FiraSans font-semibold sm:text-base xs:text-base sm:font-medium xs:font-medium">
          HOW TO ADD INSTAGRAM LIKES
        </p>
        <iframe
          title="How to add Instagram likes"
          src="https://www.youtube.com/embed/jQF2CtrXIQU"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="bg-white p-3 shadow-md w-full xl:w-[45rem] xl:h-[26rem] lg:w-[40rem] lg:h-[22rem] md:w-[36rem] md:h-[20rem]"
        ></iframe>
      </div>
      <div>
        <p className="text-lg text-blue-800 font-FiraSans font-semibold sm:text-base xs:text-base sm:font-medium xs:font-medium">
          HOW TO ADD INSTAGRAM VIEWS
        </p>
        <iframe
          src="https://www.youtube.com/embed/z27xbkl1nVM"
          title="How to add Instagram Views"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="bg-white p-3 shadow-md w-full xl:w-[45rem] xl:h-[26rem] lg:w-[40rem] lg:h-[22rem] md:w-[36rem] md:h-[20rem]"
        ></iframe>
      </div>
      <div>
        <p className="text-lg text-blue-800 font-FiraSans font-semibold sm:text-base xs:text-base sm:font-medium xs:font-medium">
          HOW TO ADD INSTAGRAM FOLLOWERS
        </p>
        <iframe
          src="https://www.youtube.com/embed/kZPFTYeAJWw"
          title="how to add Instagram followers"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="bg-white p-3 shadow-md w-full xl:w-[45rem] xl:h-[26rem] lg:w-[40rem] lg:h-[22rem] md:w-[36rem] md:h-[20rem]"
        ></iframe>
      </div>
      <div>
        <p className="text-lg text-blue-800 font-FiraSans font-semibold sm:text-base xs:text-base sm:font-medium xs:font-medium">
          HOW TO ADD INSTAGRAM STORY VIEWS
        </p>
        <iframe
          src="https://www.youtube.com/embed/i4Xj2_Ck6Kk"
          title="how to add instagram story views"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="bg-white p-3 shadow-md w-full xl:w-[45rem] xl:h-[26rem] lg:w-[40rem] lg:h-[22rem] md:w-[36rem] md:h-[20rem]"
        ></iframe>
      </div>
      {/*<div>
        <p className="text-lg text-blue-800 font-FiraSans font-semibold sm:text-base xs:text-base sm:font-medium xs:font-medium">
          HOW TO ADD INSTAGRAM REAL LIKES WITH IMPRESSIONS
        </p>
        <iframe
          src="https://www.youtube.com/embed/5edk60e4WVM"
          title="How to add Instagram Likes + Impression"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="bg-white p-3 shadow-md w-full xl:w-[45rem] xl:h-[26rem] lg:w-[40rem] lg:h-[22rem] md:w-[36rem] md:h-[20rem]"
        ></iframe>
  </div>*/}
    </div>
  );
};

export default index;
