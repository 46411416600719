import React, { useState, useEffect } from "react";
import { Outlet, useNavigate, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutAdmin } from "../../store/Slice/adminLoginSlice";
import { Modal } from "antd";
import { CiLogout } from "react-icons/ci";
import { FaUsers } from "react-icons/fa";
import { AiOutlineSetting } from "react-icons/ai";
import { FaBlog } from "react-icons/fa6";
import { LuReceipt } from "react-icons/lu";
import { RiCoupon4Line } from "react-icons/ri";
import { MdAutoMode, MdNotificationImportant } from "react-icons/md";

const AdminPage = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.adminLogin.isLoggedIn);
  const navigate = useNavigate();
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      // Redirect to admin signin if not logged in
      navigate("/admin_signin");
    }
  }, [isLoggedIn, navigate]);

  const handleLogoutConfirm = () => {
    dispatch(logoutAdmin());
    setLogoutModalVisible(false);
    navigate("/admin_signin");
  };

  const handleLogoutClick = () => {
    setLogoutModalVisible(true);
  };

  return (
    <div className="py-3 px-2">
      <div className="flex">
        <div className="w-[15%] shadow-lg min-h-screen py-10 flex flex-col justify-between items-center">
          <div className="flex flex-col gap-3 text-xl font-semibold">
            <NavLink
              to="/admin/user-details"
              className={({ isActive }) =>
                `inline-flex gap-2 items-center ${
                  isActive ? "bg-[#f49a39] text-white" : "text-gray-700"
                } p-2 rounded`
              }
            >
              <FaUsers />
              All Users
            </NavLink>
            <NavLink
              to="/admin/configuration-and-settings"
              className={({ isActive }) =>
                `inline-flex gap-2 items-center ${
                  isActive ? "bg-[#f49a39] text-white" : "text-gray-700"
                } p-2 rounded`
              }
            >
              <AiOutlineSetting />
              ConfigAndSett.
            </NavLink>
            <NavLink
              to="/admin/order-email-and-coupon-settings"
              className={({ isActive }) =>
                `inline-flex gap-2 items-center ${
                  isActive ? "bg-[#f49a39] text-white" : "text-gray-700"
                } p-2 rounded`
              }
            >
              <RiCoupon4Line />
              OrderAndCoup.
            </NavLink>
            <NavLink
              to="/admin/all-users-orders"
              className={({ isActive }) =>
                `inline-flex gap-2 items-center ${
                  isActive ? "bg-[#f49a39] text-white" : "text-gray-700"
                } p-2 rounded`
              }
            >
              <LuReceipt />
              All Orders
            </NavLink>
            <NavLink
              to="/admin/blog-post-posting"
              className={({ isActive }) =>
                `inline-flex gap-2 items-center ${
                  isActive ? "bg-[#f49a39] text-white" : "text-gray-700"
                } p-2 rounded`
              }
            >
              <FaBlog />
              Blog Posting
            </NavLink>
            <NavLink
              to="/admin/auto-services"
              className={({ isActive }) =>
                `inline-flex gap-2 items-center ${
                  isActive ? "bg-[#f49a39] text-white" : "text-gray-700"
                } p-2 rounded`
              }
            >
              <MdAutoMode />
              Auto Services
            </NavLink>
            <NavLink
              to="/admin/ip_configuration"
              className={({ isActive }) =>
                `inline-flex gap-2 items-center ${
                  isActive ? "bg-[#f49a39] text-white" : "text-gray-700"
                } p-2 rounded`
              }
            >
              IP Configurations
            </NavLink>
            <NavLink
              to="/admin/notice_area"
              className={({ isActive }) =>
                `inline-flex gap-2 items-center ${
                  isActive ? "bg-[#f49a39] text-white" : "text-gray-700"
                } p-2 rounded`
              }
            >
              <MdNotificationImportant />
              Notice Area
            </NavLink>
          </div>
          <button
            onClick={handleLogoutClick}
            className="cursor-pointer text-red-700 font-FiraSans text-xl inline-flex gap-1"
          >
            <CiLogout size={24} />
            Logout
          </button>
        </div>
        <div className="w-full">
          <h1 className="text-center font-Montserrat text-2xl py-2 shadow-lg">
            Admin Dashboard
          </h1>
          <div className="p-2">
            <Outlet />
          </div>
        </div>
      </div>

      <Modal
        title="Confirm Logout"
        visible={logoutModalVisible}
        onOk={handleLogoutConfirm}
        onCancel={() => setLogoutModalVisible(false)}
        okText="Logout"
        cancelText="Cancel"
        okButtonProps={{
          style: { backgroundColor: "#f44336", color: "#ffffff" },
        }}
        cancelButtonProps={{
          style: { backgroundColor: "#fc8a24", color: "#ffffff" },
        }}
      >
        <p>Are you sure you want to logout?</p>
      </Modal>
    </div>
  );
};

export default AdminPage;

// Responsiveness code to be completed

// import React, { useState, useEffect } from "react";
// import { Outlet, useNavigate, NavLink } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { logoutAdmin } from "../../store/Slice/adminLoginSlice";
// import { Modal } from "antd";
// import { CiLogout } from "react-icons/ci";
// import { FaUsers } from "react-icons/fa";
// import { AiOutlineSetting } from "react-icons/ai";
// import { FaBlog } from "react-icons/fa6";
// import { LuReceipt } from "react-icons/lu";
// import { RiCoupon4Line } from "react-icons/ri";
// import { MdAutoMode } from "react-icons/md";
// import { FiMenu, FiX } from "react-icons/fi"; // Icon for menu toggle

// const AdminPage = () => {
//   const dispatch = useDispatch();
//   const isLoggedIn = useSelector((state) => state.adminLogin.isLoggedIn);
//   const navigate = useNavigate();
//   const [logoutModalVisible, setLogoutModalVisible] = useState(false);
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for sidebar toggle

//   useEffect(() => {
//     if (!isLoggedIn) {
//       // Redirect to admin signin if not logged in
//       navigate("/admin_signin");
//     }
//   }, [isLoggedIn, navigate]);

//   const handleLogoutConfirm = () => {
//     dispatch(logoutAdmin());
//     setLogoutModalVisible(false);
//     navigate("/admin_signin");
//   };

//   const handleLogoutClick = () => {
//     setLogoutModalVisible(true);
//   };

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar visibility
//   };

//   return (
//     <div className="relative h-screen">
//       <div className="flex h-full">
//         {/* Sidebar */}
//         <div
//           className={`fixed top- left-0 h-full xl:w-[15%] w-[50%] shadow-lg bg-white py-10 flex flex-col justify-between items-center transition-transform transform ${
//             isSidebarOpen ? "translate-x-0" : "-translate-x-full"
//           } xl:static xl:translate-x-0`}
//         >
//           <div className="flex flex-col gap-3 text-xl font-semibold">
//             <NavLink
//               to="/admin/user-details"
//               className={({ isActive }) =>
//                 `inline-flex gap-2 items-center ${
//                   isActive ? "bg-[#f49a39] text-white" : "text-gray-700"
//                 } p-2 rounded`
//               }
//             >
//               <FaUsers />
//               All Users
//             </NavLink>
//             <NavLink
//               to="/admin/configuration-and-settings"
//               className={({ isActive }) =>
//                 `inline-flex gap-2 items-center ${
//                   isActive ? "bg-[#f49a39] text-white" : "text-gray-700"
//                 } p-2 rounded`
//               }
//             >
//               <AiOutlineSetting />
//               ConfigAndSett.
//             </NavLink>
//             <NavLink
//               to="/admin/order-email-and-coupon-settings"
//               className={({ isActive }) =>
//                 `inline-flex gap-2 items-center ${
//                   isActive ? "bg-[#f49a39] text-white" : "text-gray-700"
//                 } p-2 rounded`
//               }
//             >
//               <RiCoupon4Line />
//               OrderAndCoup.
//             </NavLink>
//             <NavLink
//               to="/admin/all-users-orders"
//               className={({ isActive }) =>
//                 `inline-flex gap-2 items-center ${
//                   isActive ? "bg-[#f49a39] text-white" : "text-gray-700"
//                 } p-2 rounded`
//               }
//             >
//               <LuReceipt />
//               All Orders
//             </NavLink>
//             <NavLink
//               to="/admin/blog-post-posting"
//               className={({ isActive }) =>
//                 `inline-flex gap-2 items-center ${
//                   isActive ? "bg-[#f49a39] text-white" : "text-gray-700"
//                 } p-2 rounded`
//               }
//             >
//               <FaBlog />
//               Blog Posting
//             </NavLink>
//             <NavLink
//               to="/admin/auto-services"
//               className={({ isActive }) =>
//                 `inline-flex gap-2 items-center ${
//                   isActive ? "bg-[#f49a39] text-white" : "text-gray-700"
//                 } p-2 rounded`
//               }
//             >
//               <MdAutoMode />
//               Auto Services
//             </NavLink>
//           </div>
//           <a
//             onClick={handleLogoutClick}
//             className="cursor-pointer text-red-700 font-FiraSans text-xl inline-flex gap-1"
//           >
//             <CiLogout size={24} />
//             Logout
//           </a>
//         </div>

//         {/* Main content area */}
//         <div className="w-full flex flex-col h-full">
//           <div className="flex items-center gap-1 p-2 shadow-lg">
//             {/* Toggle button for sidebar */}
//             <button className="xl:hidden" onClick={toggleSidebar}>
//               {isSidebarOpen ? <FiX size={24} /> : <FiMenu size={24} />}
//             </button>
//             <h1 className="flex-1 text-center font-Montserrat text-2xl">
//               Admin Dashboard
//             </h1>
//           </div>
//           <div className="p-2 flex-1 overflow-auto">
//             <Outlet />
//           </div>
//         </div>

//         {/* Overlay */}
//         {isSidebarOpen && (
//           <div
//             className="z-50 fixed inset-0 bg-black opacity-50 xl:hidden"
//             onClick={toggleSidebar}
//           ></div>
//         )}
//       </div>

//       <Modal
//         title="Confirm Logout"
//         visible={logoutModalVisible}
//         onOk={handleLogoutConfirm}
//         onCancel={() => setLogoutModalVisible(false)}
//         okText="Logout"
//         cancelText="Cancel"
//         okButtonProps={{
//           style: { backgroundColor: "#f44336", color: "#ffffff" },
//         }}
//         cancelButtonProps={{
//           style: { backgroundColor: "#fc8a24", color: "#ffffff" },
//         }}
//       >
//         <p>Are you sure you want to logout?</p>
//       </Modal>
//     </div>
//   );
// };

// export default AdminPage;
