import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/shared/Button";
import { Link, useNavigate } from "react-router-dom";
import {
  loginAdmin,
  setLoginDetails,
  toggleRememberMe,
} from "../../../../store/Slice/adminLoginSlice";
import responseMessages from "../../../../utils/responseMessages";

const AdminLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const rememberMe = useSelector((state) => state.adminLogin.rememberMe);
  const adminDetails = useSelector((state) => state.adminLogin.adminDetails); // Get admin details from Redux state

  const [error, setError] = useState(false);
  const initialValues = {
    email: rememberMe && adminDetails ? adminDetails.email || "" : "",
    password: rememberMe && adminDetails ? adminDetails.password || "" : "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required(),
    password: Yup.string().required(),
  });
  useEffect(() => {
    const storedAdminDetails = JSON.parse(localStorage.getItem("adminDetails"));
    if (storedAdminDetails) {
      dispatch(setLoginDetails(storedAdminDetails));
    }
  }, [dispatch]);
  const handleLogin = async (values, { resetForm }) => {
    try {
      setIsLoading(true);
      // Dispatch loginUser action
      const response = await dispatch(loginAdmin(values));
      if (rememberMe) {
        localStorage.setItem("adminDetails", JSON.stringify(values));
      } else {
        localStorage.removeItem("adminDetails");
      }
      setIsLoading(false);
      if (response.payload.code === 200 || response.payload.code === 201) {
        navigate("/admin");
      } else {
        setError(true);
      }
      resetForm();
    } catch (error) {
      setIsLoading(false);
      setError(true);
    }
  };

  return (
    <div className="flex gap-10 min-h-screen justify-center items-center py-20 sm:flex-col md:flex-col xs:flex-col bg-[#f5f6fa]">
      <div>
        <p className="font-extrabold font-Montserrat text-9xl lg:text-8xl md:text-7xl sm:text-6xl xs:text-6xl bg-gradient-to-r from-yellow-500  via-[#001f68] to-yellow-600 bg-clip-text text-transparent">
          Igcarts
        </p>
        <p className="text-end text-4xl italic font-semibold text-yellow-600">
          admin
        </p>
      </div>
      <div className="w-full max-w-md lg:px-4 md:px-3 sm:px-2 xs:px-2 bg-yellow-600 opacity-80 rounded-md shadow-md">
        <p className="text-center font-FiraSans text-[#001f68] font-semibold text-2xl lg:text-xl md:text-xl sm:text-lg xs:text-lg py-3">
          Login Here
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          {({ handleSubmit, isValid, setFieldValue }) => (
            <Form
              className="px-8 lg:px-5 md:px-4 sm:px-3 xs:px-2 py-4"
              onSubmit={handleSubmit}
            >
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-[#001f68] text-sm font-FiraSans font-bold mb-2"
                >
                  Email Address
                </label>
                <Field
                  type="email"
                  name="email"
                  id="email"
                  placeholder="user@email.com"
                  className={`shadow appearance-none border font-FiraSans rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${
                    isValid ? "border-gray-400" : "border-red-500"
                  }`}
                  onChange={(e) => {
                    setFieldValue("email", e.target.value);
                  }}
                />
                <ErrorMessage
                  name="email"
                  component="p"
                  className="text-red-900 text-sm italic"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-[#001f68] text-sm font-FiraSans font-bold mb-2"
                >
                  Password
                </label>
                <Field
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  className={`shadow appearance-none border font-FiraSans rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline ${
                    isValid ? "border-gray-400" : "border-red-500"
                  }`}
                  onChange={(e) => {
                    setFieldValue("password", e.target.value);
                  }}
                />
                <ErrorMessage
                  name="password"
                  component="p"
                  className="text-red-900 text-sm italic"
                />
              </div>

              <div className="flex items-center justify-between">
                <div className="flex gap-1">
                  <input
                    type="checkbox"
                    id="rememberMe"
                    className="accent-blue-800"
                    checked={rememberMe}
                    onChange={() => dispatch(toggleRememberMe())}
                  />
                  <p className="text-blue-800 hover:text-blue-500 text-xs font-semibold">
                    Remember me
                  </p>
                </div>
                <span className="text-blue-800 hover:text-blue-500 text-xs font-normal">
                  Forgot Password?{" "}
                  <Link
                    to="/admin_forget_password"
                    className="text-blue-800 hover:text-blue-500 font-semibold transition ease-in-out duration-300"
                  >
                    Reset
                  </Link>
                </span>
              </div>
              {error && (
                <p className="text-center text-red-900 text-sm italic mt-2">
                  {responseMessages.adminLoginFailedMessage}!
                </p>
              )}
              <div className="flex items-center justify-center mt-6">
                <Button
                  type="submit"
                  title={isLoading ? "logging in..." : "Login"}
                  variant={isValid ? "secondary" : "disabled"}
                  disabled={!isValid || isLoading}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AdminLogin;
