import React, { useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import { Link } from "react-router-dom";

const Accordion = ({ title, image, value, isOpen, onToggle }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="mb-4">
      <button
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onToggle}
        className={`w-full px-4 sm:px-2 xs:px-2 py-6 sm:py-2 xs:py-1 font-FiraSans text-start font-bold flex justify-between items-center border rounded-xl shadow-md ${
          isHovered ? "bg-[#fafafa] " : "text-[#001f68]"
        }`}
      >
        <div className="flex gap-2 items-center">
          <img src={image} alt="" width={35} />
          <h6 className="text-xl">{title}</h6>
        </div>
        {isOpen ? <FiMinus size={24} /> : <FiPlus size={24} />}
      </button>
      {isOpen && (
        <div className="">
          {value.map((item, index) => (
            <div
              key={index}
              className="text-lg text-[#337ab7] hover:text-[#23527c] px-4  py-2 font-FiraSans font-bold bg-white flex gap-2"
            >
              <Link to={item.address}>{item.servicePrice}</Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Accordion;
