import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import {
  BalanceBeforeAndAfter,
  RateAndPrice,
} from "./shared/BalanceBeforeAndAfter";
import SEOAccordionList from "../SEO/SEOAccordionList";

const BalanceLayout = () => {
  const [quantity, setQuantity] = useState(0);
  return (
    <div>
      <RateAndPrice />
      <Outlet context={{ setQuantity }} />
      <BalanceBeforeAndAfter quantity={quantity} />
      <SEOAccordionList />
    </div>
  );
};

export default BalanceLayout;
