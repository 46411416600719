import React, { useState } from "react";
import { useAccordionContext } from "../SEO/AccordionContext";
import SEOAccordion from "../../src/SEO/components/SEOAccordion";
import CustomerReviewCard from "../../src/SEO/components/CustomerReviewCard";
const SEOAccordionList = () => {
  const { accordionData, accordionHeading, reviews } = useAccordionContext();
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="px-24 lg:px-4 md:px-3 sm:px-2 xs:px-1 flex flex-col gap-3 pt-4">
      <h2 className="text-[#001f68] text-center font-FiraSans font-bold text-xl">
        {accordionHeading}
      </h2>
      <div className="grid grid-cols-2 gap-x-2  sm:grid-cols-1 xs:grid-cols-1">
        {accordionData.map((item, index) => (
          <SEOAccordion
            key={index}
            title={item.title}
            content={item.content}
            isOpen={index === openAccordionIndex}
            onToggle={() => toggleAccordion(index)}
          />
        ))}
      </div>
      <div>
        {reviews.map((item, index) => (
          <CustomerReviewCard
            key={index}
            title={item.title}
            content={item.content}
          />
        ))}
      </div>
    </div>
  );
};

export default SEOAccordionList;
