import React, { useEffect } from "react";
import Button from "../../components/shared/Button";
import TwoRowForm from "../../components/shared/TwoRowForm";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import {
  ErrorModal,
  InfoModal,
  SuccessModal,
} from "../../components/shared/ToastModals";
import { useOutletContext } from "react-router-dom";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";

const YoutubeLikes = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    link: [],
    quantity: [],
    service_type: window.location.pathname,
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [message, setMessage] = useState("");

  const { link, quantity } = data;
  const onChange = (e) => {
    const updated = { [e.target.name]: [e.target.value] };
    setData({ ...data, ...updated });
    const value = { ...data, ...updated };
    const { quantity } = value;
    setQuantity(quantity);
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    if (quantity < 100) {
      setInfoModalVisible(true);
      setMessage(responseMessages.minimumQuantityOf100);
      return;
    }
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(data));
      setLoading(false);
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setData({
          link: [],
          quantity: [],
          service_type: window.location.pathname,
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
    } catch (error) {
      setLoading(false);
      setQuantity(0);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock2);
    }
  };

  return (
    <>
      <div className="font-FiraSans py-8 sm:py-4 xs:py-2  px-20 lg:px-6 md:px-4 sm:px-3 xs:px-2 flex flex-col gap-6 sm:gap-3 xs:gap-2">
        {/**header*/}
        <div className="text-center xs:text-lg sm:text-lg md:text-xl text-3xl">
          Want More Likes?
        </div>
        <h2 className="text-center font-bold xs:text-[1.5rem] sm:text-[2rem] md:text-[2.5rem] text-[3rem] ">
          Add YouTube Likes
        </h2>

        {/*info Banner*/}
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1  w-full">
          <div className="p-3 bg-[#c7e5f3] text-[#31708f] border-2 border-[#9acfea] rounded ">
            <p className="sm:text-sm xs:text-xs">
              Please. Add Full Youtube Video!
              <br />
              Example : https://www.youtube.com/watch?v=YQHsXMglC9A
              <br />
              The Order will start within 3-18 hours
            </p>
          </div>
        </div>

        {/**form section */}
        <div className="w-full">
          <TwoRowForm
            labelOne="Youtube Video Link"
            placeholderOne="Enter the URL to your Youtube video"
            onChangeOne={onChange}
            valueOne={link}
            nameOne="link"
            labelTwo="Likes"
            placeholderTwo="Enter the amount of likes you want(100 minimum)"
            onChangeTwo={onChange}
            valueTwo={quantity}
            nameTwo="quantity"
          />
        </div>
        <div className="text-center">
          <Button
            type="submit"
            title={loading ? "Sending" : "Add likes"}
            size="md"
            onClick={onSubmit}
            disabled={!link || !quantity || loading || !isAuthenticated}
          />
        </div>
        {/* Success Modal */}
        <SuccessModal
          visible={successModalVisible}
          onClose={() => setSuccessModalVisible(false)}
          message={message}
        />
        {/* Error Modal */}
        <ErrorModal
          visible={errorModalVisible}
          onClose={() => setErrorModalVisible(false)}
          message={message}
        />
        {/* Info Modal */}
        <InfoModal
          visible={infoModalVisible}
          onClose={() => setInfoModalVisible(false)}
          message={message}
        />
      </div>
    </>
  );
};

export default YoutubeLikes;
