import React from "react";
import { Modal, Input } from "antd";

const EditNoOfPinnedPostModal = ({
  editModalVisible,
  hideEditModal,
  handleInputChange,
  editNoOfPinnedPost,
  updateNoOfPinnedPost,
  loading,
}) => {
  return (
    <Modal
      title="Edit Number of Pinned Posts"
      open={editModalVisible}
      onCancel={hideEditModal}
      onOk={updateNoOfPinnedPost}
      okText={loading ? "Updating..." : "Update"}
      cancelText="Cancel"
      okButtonProps={{
        style: { backgroundColor: "#fc8a24", color: "#ffffff" },
      }}
      cancelButtonProps={{
        style: { backgroundColor: "#f44336", color: "#ffffff" },
      }}
    >
      <div>
        <Input
          type="number"
          name="no_of_post_pinned"
          value={editNoOfPinnedPost.no_of_post_pinned}
          onChange={handleInputChange}
          placeholder="Number of Pinned Posts"
        />
      </div>
    </Modal>
  );
};

export default EditNoOfPinnedPostModal;
