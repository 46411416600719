import React, { useState } from "react";
import Table from "../../../../../components/shared/Table";
import axios from "axios";
import { Input, Modal } from "antd";
import Button from "../../../../../components/shared/Button";
import responseMessages from "../../../../../utils/responseMessages";

const AutoServicesConfig = ({
  autoServiceConfigData,
  setAutoServiceConfigData,
  fetchAutoServiceConfigData,
}) => {
  const [configCreation, setConfigCreation] = useState({
    url: "",
    api_key: "",
    serviceName: "",
    serviceID: "",
  });
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updatedPost, setupdatedPost] = useState({
    ip_id: null,
    url: "",
    api_key: "",
    serviceID: "",
    serviceName: "",
  });
  const token = localStorage.getItem("adminToken");

  const autoServicesConfig = [
    {
      name: "Url",
      selector: (row) => row.url,
    },
    { name: "Api Key", selector: (row) => row.api_key },
    {
      name: "Service Name",
      selector: (row) => row.service_name,
    },
    { name: "Service", selector: (row) => row.service },
    {
      name: "Delete",
      selector: (row) => (
        <button
          onClick={() => handleEdit(row)}
          className="text-green-700 italic"
        >
          Edit
        </button>
      ),
      width: "80px",
    },
    {
      name: "Delete",
      selector: (row) => (
        <button
          onClick={() => handleRemove(row)}
          className="text-red-700 italic"
        >
          Delete
        </button>
      ),
      width: "80px",
    },
  ];

  const showDeleteModal = (id) => {
    setSelectedServiceId(id);
    setDeleteModalVisible(true);
  };

  const hideDeleteModal = () => {
    setDeleteModalVisible(false);
  };

  const handleRemove = (row) => {
    showDeleteModal(row.auto_service_id);
  };

  const deleteServiceConfirmation = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/auto-service/delete/${selectedServiceId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json; charset=utf-8",
          },
        }
      );
      if (response.status === 200) {
        setAutoServiceConfigData((prevData) =>
          prevData.filter(
            (service) => service.auto_service_id !== selectedServiceId
          )
        );
        hideDeleteModal();
      } else {
        alert("Failed to delete service. Please try again later.");
      }
    } catch (error) {
      alert("Error deleting service, please try again later.");
    }
  };

  const addAutoServiceConfig = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/auto-service/create`,
        configCreation,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json; charset=utf-8",
          },
        }
      );

      setAutoServiceConfigData([...autoServiceConfigData, response.data.data]);
      setLoading(false);
      fetchAutoServiceConfigData();
      setConfigCreation({
        url: "",
        api_key: "",
        serviceName: "",
        serviceID: "",
      });
    } catch (error) {
      setLoading(false);
      alert("Error creating auto service configuratiom, please try again.");
      console.log("response error", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setConfigCreation({ ...configCreation, [name]: value });
  };
  const handleEdit = (row) => {
    setupdatedPost({
      auto_service_id: row.auto_service_id,
      url: row.url,
      api_key: row.api_key,
      serviceName: row.service_name,
      serviceID: row.service,
    });
    setUpdateModalVisible(true);
  };
  const onEditChange = (e) => {
    setupdatedPost((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleUpdateService = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { url, api_key, serviceID, serviceName } = updatedPost;
      const response = await axios.patch(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/auto-service/update/${updatedPost.auto_service_id}`,
        { url, api_key, serviceID, serviceName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      );
      setLoading(false);
      if (response.status === 200) {
        alert("Configuration details updated successfully.");
        setUpdateModalVisible(false);
        fetchAutoServiceConfigData();
      } else {
        alert("Failed to update config details. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      alert(responseMessages.errorMessage);
    }
  };
  return (
    <div className="mt-8">
      <Table
        title="Auto Services Config"
        column={autoServicesConfig}
        data={autoServiceConfigData}
      />
      <form onSubmit={addAutoServiceConfig} className="mt-5">
        <div className="xl:grid xl:grid-cols-3 flex flex-wrap justify-center gap-4 mb-5 sm:flex-col xs:flex-col md:flex-wrap lg:flex-wrap sm:items-center xs:items-center md:items-center lg:items-center">
          <Input
            type="text"
            name="url"
            placeholder="URL"
            value={configCreation.url}
            onChange={handleInputChange}
            required
            className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
          />
          <Input
            type="text"
            name="api_key"
            placeholder="Api Key"
            value={configCreation.api_key}
            onChange={handleInputChange}
            className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
          />
          <Input
            type="text"
            name="serviceName"
            placeholder="Service Name"
            value={configCreation.serviceName}
            onChange={handleInputChange}
            className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
          />
          <Input
            type="text"
            name="serviceID"
            placeholder="Service"
            value={configCreation.serviceID}
            onChange={handleInputChange}
            className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
          />
        </div>
        <div className="flex justify-center mt-6">
          <Button
            type="submit"
            title={loading ? "Creating Config..." : "Create Config"}
            size="md"
            disabled={loading}
          />
        </div>
      </form>

      {/* Update Modal */}
      <Modal
        title="Update Auto Service Config"
        open={updateModalVisible}
        onOk={handleUpdateService}
        onCancel={() => setUpdateModalVisible(false)}
        okText={loading ? "Updating..." : "Update"}
        okButtonProps={{
          style: { backgroundColor: "blue", color: "#ffffff" },
        }}
        cancelText="Cancel"
      >
        <div className="flex flex-wrap gap-4">
          <Input
            type="text"
            name="url"
            placeholder="URL"
            value={updatedPost.url}
            onChange={onEditChange}
            required
            className="w-full"
          />
          <Input
            type="text"
            name="api_key"
            placeholder="Api Key"
            value={updatedPost.api_key}
            onChange={onEditChange}
            className="w-full"
          />
          <Input
            type="text"
            name="serviceName"
            placeholder="Service Name"
            value={updatedPost.serviceName}
            onChange={onEditChange}
            className="w-full"
          />
          <Input
            type="text"
            name="serviceID"
            placeholder="Service"
            value={updatedPost.serviceID}
            onChange={onEditChange}
            className="w-full"
          />
        </div>
        {/* Delete modal */}
      </Modal>
      <Modal
        title={<span style={{ color: "#f44336" }}>Confirm Deletion</span>}
        open={deleteModalVisible}
        onOk={deleteServiceConfirmation}
        onCancel={hideDeleteModal}
        okText="Delete"
        cancelText="Cancel"
        okButtonProps={{
          style: { backgroundColor: "#f44336", color: "#ffffff" },
        }}
        cancelButtonProps={{
          style: { backgroundColor: "#fc8a24", color: "#ffffff" },
        }}
      >
        <p className="font-semibold">
          Are you sure you want to delete this Auto service Config?
        </p>
      </Modal>
    </div>
  );
};

export default AutoServicesConfig;
