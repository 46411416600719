import React, { useEffect, useState } from "react";
import Header from "./header";
import { loadStripe } from "@stripe/stripe-js";
import Button from "../../components/shared/Button";
import Table from "../../components/shared/Table";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import responseMessages from "../../utils/responseMessages";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import NoticeDisplay from "./components/NoticeDisplay";

const stripePromise = loadStripe(
  `${process.env.REACT_APP_IGCARTS_STRIPE_PUBLISHABLE_KEY}`
);
const Main = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateUserDetails());
  }, []);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({
    price: 0.0,
    product_name: "Wallet funding for igcarts user account",
  });
  const token = localStorage.getItem("token");
  const displayUser = useSelector((state) => state.login.user);
  const userOrder = [
    { name: "Price ($)", selector: (row) => row.price },
    // {
    //   name: "Quantity",
    //   selector: (row) => row.quantity,
    // },
    {
      name: "Service Type",
      selector: (row) => row.service_type,
    },
    {
      name: "Username/Link",
      selector: (row) => row.link,
    },
  ];
  const handlePayment = async (event) => {
    event.preventDefault();
    if (product.price < 5) {
      alert("Minimum amount to deposit is $5");
    }
    const stripe = await stripePromise;

    try {
      setLoading(true);
      const response = await axios({
        url: `${process.env.REACT_APP_IGCARTS_BASE_URL}/payment/process-payment`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json; charset=utf-8",
        },
        data: product,
      });
      sessionStorage.setItem(
        "paymentReference",
        response.data.data.payment_reference
      );

      const result = await stripe.redirectToCheckout({
        sessionId: response.data.data.payment_reference,
      });
      console.log("result", result);
      if (response.data.code !== 200 || response.payload.code === 201) {
        toast.error(responseMessages.paymentError);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div className="w-full flex flex-col items-center px-1 py-6 md:py-2 sm:py-2 xs:py-1 gap-[2rem] lg:gap-6 md:gap-5 sm:gap-4 xs:gap-3">
      <Header />
      {/*header end here*/}

      <div className="mt-[2rem] text-[#001f68] flex gap-[10rem] lg:flex-col lg:gap-8 md:flex-col sm:flex-col xs:flex-col sm:gap-4 xs:gap-2 md:gap-[1.5rem] md:px-2 ">
        <div className="p-5 bg-[#fcbb45] flex flex-col gap-5 sm:gap-2 xs:gap-1 rounded-md">
          <h3 className="text-[1.5rem] md:text-[1rem] sm:text-[1rem] xs:text-[0.6rem] font-medium ">
            Wallet Balance
          </h3>
          <p className="text-[2.2rem] md:text-[1.3rem] font-FiraSans sm:text-[1.3rem] xs:text-[1rem] font-bold ">
            ${displayUser && displayUser.wallet_balance}
          </p>
        </div>
        {/*balance ends here*/}
        {/*top up starts here*/}
        <div className="p-5 bg-[#fcbb45] flex flex-col gap-3 sm:gap-2 xs:gap-1 rounded-md">
          <div className="flex gap-3 items-center ">
            <label
              htmlFor=""
              className="font-medium text-md lg:text-lg xl:text-lg "
            >
              Amount:
            </label>
            <input
              type="number"
              placeholder="Min. of $5"
              className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-xl"
              onChange={(e) =>
                setProduct({ ...product, price: e.target.value })
              }
              min="5"
            />
          </div>
          <Button
            title={loading ? "Processing..." : "Top Up"}
            size="md"
            variant="secondary"
            onClick={handlePayment}
            disabled={loading || !product.price}
          />
        </div>
      </div>
      {/**data table section */}
      <div className="w-full pt-5">
        {displayUser && (
          <Table
            data={displayUser && displayUser.order_history}
            column={userOrder}
            showPagination={false}
          />
        )}
      </div>
      <div className="fixed bottom-1/4 right-1">
        <NoticeDisplay />
      </div>
    </div>
  );
};

export default Main;
