import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "../../../../components/shared/Button";

function ConfigandSett() {
  const [services, setServices] = useState([]);
  const [newService, setNewService] = useState({
    url: "",
    api_key: "",
    serviceName: "",
    serviceID: "",
    price: "",
    quantity: "",
    social_media: "",
    // is_available: false,
  });
  const [editingService, setEditingService] = useState(null);
  const [searchServices, setSearchServices] = useState("");
  const [filteredServices, setFilteredServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("adminToken");
  const fetchServices = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/service/get-all-service`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json; charset=utf-8",
          },
        }
      );
      setServices(response.data.data);
    } catch (error) {
      alert(
        "We encountered an error while fetching services, please try again"
      );
    }
  };
  useEffect(() => {
    fetchServices();
  }, [editingService, token]);

  useEffect(() => {
    setFilteredServices(
      services.filter((service) =>
        service.social_media?.includes(searchServices)
      )
    );
  }, [searchServices, services]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewService({ ...newService, [name]: value });
  };

  const isAvailableChange = (event) => {
    const { checked } = event.target;
    setNewService((prevService) => ({
      ...prevService,
      is_available: checked,
    }));
  };

  const editService = (service) => {
    setEditingService(service);
    setNewService({
      url: service.url,
      api_key: service.api_key,
      serviceName: service.service_name,
      serviceID: service.service,
      price: service.price,
      quantity: service.quantity,
      is_available: service.is_available,
      social_media: service.social_media,
    });
  };

  const updateService = async () => {
    try {
      setLoading(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/service/update-service/${editingService.service_id}`,
        newService,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json; charset=utf-8",
          },
        }
      );

      const updatedServices = services.map((service) =>
        service.service_id === editingService.service_id
          ? response.data
          : service
      );
      setServices(updatedServices);
      setLoading(false);
      setEditingService(null);
      setNewService({
        url: "",
        api_key: "",
        serviceName: "",
        serviceID: "",
        price: "",
        quantity: "",
        is_available: false,
        social_media: "",
      });
    } catch (error) {
      alert("Service update fail, try again");
    }
  };

  const addService = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/service/add-service`,
        newService,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json; charset=utf-8",
          },
        }
      );

      setServices([...services, response.data]);
      setLoading(false);
      fetchServices();
      setNewService({
        url: "",
        api_key: "",
        serviceName: "",
        serviceID: "",
        price: "",
        quantity: "",
        social_media: "",
      });
    } catch (error) {
      alert("Error adding service, check your connection and try again");
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center w-full mb-3 px-4">
        <h2 className="font-Montserrat font-bold text-xl text-center">
          Services
        </h2>
        <input
          type="text"
          placeholder="Search by Social Media"
          value={searchServices}
          onChange={(e) => setSearchServices(e.target.value)}
          className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md"
        />
      </div>

      <div className="flex w-full py-2">
        <table>
          <thead>
            <tr style={{ background: "#FCBB45", color: "#001F68" }}>
              <th style={{ border: "1px solid black", padding: "10px" }}>
                URL
              </th>
              <th style={{ border: "1px solid black", padding: "10px" }}>
                Service Name
              </th>
              <th style={{ border: "1px solid black", padding: "10px" }}>
                ServiceId
              </th>
              <th style={{ border: "1px solid black", padding: "10px" }}>
                Api Key
              </th>
              <th style={{ border: "1px solid black", padding: "10px" }}>
                Price($)
              </th>
              <th style={{ border: "1px solid black", padding: "10px" }}>
                Quantity
              </th>
              <th style={{ border: "1px solid black", padding: "10px" }}>
                Is Available
              </th>
              <th style={{ border: "1px solid black", padding: "10px" }}>
                Social Media
              </th>
              <th style={{ border: "1px solid black", padding: "10px" }}>
                Edit
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredServices.map((service, index) => (
              <tr key={index}>
                <td style={{ border: "1px solid gray", padding: "3px" }}>
                  {service.url}
                </td>
                <td style={{ border: "1px solid gray", padding: "3px" }}>
                  {service.service_name}
                </td>
                <td style={{ border: "1px solid gray", padding: "3px" }}>
                  {service.service}
                </td>
                <td style={{ border: "1px solid gray", padding: "3px" }}>
                  {service.api_key}
                </td>
                <td style={{ border: "1px solid gray", padding: "3px" }}>
                  {service.price}
                </td>
                {service.quantity && (
                  <td style={{ border: "1px solid gray", padding: "3px" }}>
                    {service.quantity}
                  </td>
                )}
                <td
                  style={{
                    border: "1px solid gray",
                    padding: "9px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={service.is_available}
                    readOnly
                  />
                </td>
                <td style={{ border: "1px solid gray", padding: "3px" }}>
                  {service.social_media}
                </td>
                <td style={{ border: "1px solid gray", padding: "3px" }}>
                  <button
                    onClick={() => editService(service)}
                    className="underline text-blue-500"
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex flex-wrap justify-center gap-2 mb-5 sm:flex-col xs:flex-col md:flex-wrap lg:flex-wrap sm:items-center xs:items-center md:items-center lg:items-center">
        <input
          type="text"
          name="url"
          placeholder="URL"
          value={newService.url}
          onChange={handleInputChange}
          required
          className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
        />
        <input
          type="text"
          name="api_key"
          placeholder="API Key"
          value={newService.api_key}
          onChange={handleInputChange}
          required
          className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
        />
        <input
          type="text"
          name="serviceName"
          placeholder="Service Name"
          value={newService.serviceName}
          onChange={handleInputChange}
          required
          className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
        />
        <input
          type="text"
          name="serviceID"
          placeholder="Service ID"
          value={newService.serviceID}
          onChange={handleInputChange}
          required
          className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
        />
        <input
          type="text"
          name="price"
          placeholder="Price"
          value={newService.price}
          onChange={handleInputChange}
          required
          className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
        />
        <input
          type="text"
          name="quantity"
          placeholder="quantity"
          value={newService.quantity}
          onChange={handleInputChange}
          className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
        />
        <div className="flex gap-2 items-center shadow-sm bg-white outline-none border border-gray-300 p-2 rounded-md sm:w-full xs:w-full md:w-full">
          <label>Is Available</label>
          <input
            type="checkbox"
            name="is_available"
            checked={newService.is_available}
            onChange={isAvailableChange}
            className="accent-[#fc8a24]"
          />
        </div>
        <input
          type="text"
          name="social_media"
          placeholder="social_media"
          value={newService.social_media}
          onChange={handleInputChange}
          className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
        />
      </div>
      <div className="flex justify-center mb-4">
        <Button
          title={editingService ? "Update Service" : "Add Service"}
          onClick={editingService ? updateService : addService}
          disabled={loading}
        />
      </div>
    </div>
  );
}

export default ConfigandSett;
