import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "../../../../components/shared/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import responseMessages from "../../../../utils/responseMessages";

const NewPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[@#$%^&+=_!?]).*$/,
      "New Password must contain at least one capital letter and one special character (@#$%^&+=_!?)"
    )
    .required("New Password is required"),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm New Password field is required"),
});

const AdminNewPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const otp = searchParams.get("otp");
  const [error, setError] = useState(false);
  const onSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_IGCARTS_BASE_URL}/admin/reset-password?email=${email}&otp=${otp}`,
        {
          password: values.newPassword,
          confirmPassword: values.confirmNewPassword,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      );
      resetForm();
      setLoading(false);
      if (response.data.code === 200 || response.payload.code === 201) {
        toast.success(responseMessages.newPasswordSuccess);
        navigate("/signin");
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center py-20 min-h-screen bg-[#f5f6fa] gap-10">
      <div>
        <p className="font-extrabold font-Montserrat text-9xl lg:text-8xl md:text-7xl sm:text-6xl xs:text-6xl bg-gradient-to-r from-yellow-500  via-[#001f68] to-yellow-600 bg-clip-text text-transparent">
          Igcarts
        </p>
        <p className="text-end text-4xl italic font-semibold text-yellow-600">
          admin
        </p>
      </div>
      <div className="w-full max-w-md lg:px-4 md:px-3 sm:px-2 xs:px-2 py-6 bg-yellow-600 opacity-80 rounded-md shadow-md">
        <p className="text-center font-FiraSans font-bold text-2xl text-[#001f68] lg:text-xl md:text-xl sm:text-lg xs:text-lg py-3">
          New Password
        </p>
        <Formik
          initialValues={{
            newPassword: "",
            confirmNewPassword: "",
          }}
          validationSchema={NewPasswordSchema}
          onSubmit={onSubmit}
        >
          {({ isValid, handleSubmit }) => (
            <Form
              className="px-8 lg:px-5 md:px-4 sm:px-3 xs:px-2 py-4"
              onSubmit={handleSubmit}
            >
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-[#001f68] text-sm font-FiraSans font-semibold"
                >
                  New Password
                </label>
                <Field
                  type="password"
                  placeholder="New password"
                  id="newPassword"
                  name="newPassword"
                  className="shadow appearance-none border font-FiraSans border-gray-300 rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                />
                <ErrorMessage
                  name="newPassword"
                  component="p"
                  className="text-red-500 text-sm italic"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="confirmNewPassword"
                  className="block text-[#001f68] text-sm font-FiraSans font-semibold"
                >
                  Confirm New Password
                </label>
                <Field
                  type="password"
                  placeholder="Confirm new password"
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  className="shadow appearance-none border font-FiraSans border-gray-300 rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                />
                <ErrorMessage
                  name="confirmNewPassword"
                  component="p"
                  className="text-red-500 text-sm italic"
                />
              </div>
              <div className="mt-6 flex justify-center">
                <Button
                  type="submit"
                  title={loading ? "Resetting password...." : "Confirm"}
                  variant="secondary"
                  disabled={!isValid || loading}
                />
              </div>
              {error && (
                <p className="text-center text-red-500 text-sm italic">
                  {responseMessages.newPasswordFails}
                </p>
              )}
            </Form>
          )}
        </Formik>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AdminNewPassword;
