import React from "react";
import Table from "../../../../../components/shared/Table";

const AutoServicesTable = ({ autoServiceData, handleRemove, handleEdit }) => {
  const autoServicesDetails = [
    {
      name: "Service Name",
      selector: (row) => row.service_name,
      width: "120px",
    },
    { name: "IgC. Username", selector: (row) => row.username, width: "140px" },
    {
      name: "Last post",
      selector: (row) => (
        <a href={row.last_post} target="_blank" rel="noopener noreferrer">
          {row.last_post}
        </a>
      ),
      width: "180px",
    },
    {
      name: "Username",
      selector: (row) => row.social_username,
      width: "120px",
    },
    { name: "Post Done", selector: (row) => row.post_done, width: "80px" },
    {
      name: "No. of Post",
      selector: (row) => row.no_of_posts,
      width: "120px",
    },
    { name: "Pinned", selector: (row) => row.no_of_post_pinned, width: "90px" },
    { name: "Duration", selector: (row) => row.duration, width: "80px" },
    { name: "Qty.", selector: (row) => row.quantity, width: "80px" },
    {
      name: "Edit",
      selector: (row) => (
        <button
          onClick={() => handleEdit(row)}
          className="text-blue-700 italic"
        >
          Edit
        </button>
      ),
      width: "60px",
    },
    {
      name: "Del.",
      selector: (row) => (
        <button
          onClick={() => handleRemove(row)}
          className="text-red-700 italic"
        >
          Del.
        </button>
      ),
      width: "60px",
    },
  ];

  return (
    <Table
      title="Auto Services"
      column={autoServicesDetails}
      data={autoServiceData}
    />
  );
};

export default AutoServicesTable;
