import React, { useEffect, useState } from "react";
import Button from "./Button";
import { useSelector } from "react-redux";
import { MdInfo } from "react-icons/md";
import { toast } from "react-toastify";

const FormAndButton = ({
  placeholder,
  onChange,
  value,
  label,
  onClick,
  mediaUrl,
  username,
  //onMediaClick,
  onQuantityChange,
}) => {
  const { loading, message, error } = useSelector(
    (state) => state.instagramService
  );

  const [quantities, setQuantities] = useState({});
  const [selectedMediaLink, setSelectedMediaLink] = useState(null);
  const [links, setLink] = useState([]);
  const [qty, setQty] = useState([]);

  useEffect(() => {
    if (!mediaUrl) {
      setQuantities({});
      setSelectedMediaLink(null); // Reset selected media link when mediaUrl is cleared
    } else {
      const initialQuantities = mediaUrl.reduce((acc, _, index) => {
        acc[index] = 0;
        return acc;
      }, {});
      setQuantities(initialQuantities);
    }
  }, [mediaUrl]);

  const handleQuantityChange = (index, newQuantity, link) => {
    // keep each quantity in an object to allow multiple selection
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [index]: newQuantity,
    }));

    const isThere = links.find((item) => item === link);
    if (isThere) {
      const where = links.indexOf(isThere);
      qty[where] = newQuantity;
    } else {
      setLink((prev) => [...prev, link]);
      setQty((prev) => [...prev, newQuantity]);
    }

    onQuantityChange(
      index,
      { ...quantities, [index]: newQuantity },
      links,
      qty
    );
  };

  return (
    <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 py-4">
      <div className="flex gap-6 lg:gap-4 xl:items-end lg:items-end md:items-end md:gap-2 sm:gap-2 sm:flex-col xs:gap-2 xs:flex-col">
        <div className="flex flex-col gap-1 flex-grow">
          <label className="block font-FiraSans text-lg">{label}</label>
          <input
            type="text"
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
          />
        </div>
        <div className="sm:flex sm:justify-center xs:flex xs:justify-center">
          <Button
            type="button"
            title={loading ? "Loading media..." : "Load Media"}
            variant="primary"
            size="sm"
            onClick={onClick}
            disabled={!username || loading}
          />
        </div>
      </div>
      {loading ? message : ""}
      {error ? toast.error(message) : ""}
      {mediaUrl && (
        <div>
          {/*<p className="inline-flex items-center gap-2 mt-6 text-xl bg-gray-200 p-3 rounded-lg">
            <MdInfo size={22} className="text-green-600" />
            Click on a media and increment the quantity
      </p>*/}
          <div className="mt-4 grid grid-cols-4 gap-x-4 gap-y-4">
            {mediaUrl.map((url, index) => (
              <div key={index} className="">
                <img
                  src={url.image}
                  alt={`Media ${index}`}
                  //onClick={() => handleMediaClick(index, url.link)}
                  className={`w-full max-w-xs mx-auto ${
                    selectedMediaLink === url.link ? "opacity-20" : ""
                  }`}
                />
                <div className="flex justify-between mt-4 items-center">
                  <Button
                    title="-"
                    variant="primary"
                    size="xs"
                    onClick={() =>
                      handleQuantityChange(
                        index,
                        Math.max(0, quantities[index] - 5),
                        url.link
                      )
                    }
                  />
                  <input
                    type="text"
                    value={quantities[index] || 0}
                    onChange={(e) =>
                      handleQuantityChange(
                        index,
                        parseInt(e.target.value),
                        url.link
                      )
                    }
                    className="text-center outline-none w-7 xl:w-full lg:w-full"
                  />
                  <Button
                    title="+"
                    variant="primary"
                    size="xs"
                    onClick={() =>
                      handleQuantityChange(
                        index,
                        quantities[index] + 5,
                        url.link
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FormAndButton;
