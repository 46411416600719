import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMedia,
  loadInstagramVideosOnly,
} from "../../store/Slice/instagramServiceSlice";
import FormAndButton from "../../components/shared/FormAndButton";
import { Link, useOutletContext } from "react-router-dom";
import Button from "../../components/shared/Button";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { ToastContainer, toast } from "react-toastify";
import {
  ErrorModal,
  InfoModal,
  SuccessModal,
} from "../../components/shared/ToastModals";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";

const InstagramViews = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { media } = useSelector((state) => state.instagramService);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [orderDetails, setOrderDetails] = useState({
    link: "",
    quantity: "",
    service_type: window.location.pathname,
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  useEffect(() => {
    return () => {
      dispatch(clearMedia());
    };
  }, [dispatch]);
  const onClick = () => {
    dispatch(loadInstagramVideosOnly(username));
  };
  const onChange = (e) => {
    setUsername(e.target.value);
  };
  const onQuantityChange = (index, quantities, link, quantity) => {
    let sumOfQuantities = 0;
    Object.keys(quantities).forEach((item) => {
      if (typeof quantities[item] == "number") {
        sumOfQuantities += quantities[item];
      }
    });
    setQuantity(sumOfQuantities);
    setOrderDetails({ ...orderDetails, link, quantity });
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!media) {
      toast.info("Please load media first.");
      return;
    }
    if (orderDetails.quantity < 100) {
      setInfoModalVisible(true);
      setMessage(responseMessages.minimumQuantityOf100);
      return;
    }
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(orderDetails));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        await dispatch(clearMedia());
        setSuccessModalVisible(true);
        setMessage(responseMessages.isAvailableOrderMessage);
        setOrderDetails({
          link: "",
          quantity: "",
          service_type: window.location.pathname,
        });
        await dispatch(clearMedia());
        await dispatch(updateUserDetails());
        setUsername("");
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === '"link" is not allowed to be empty') {
          setMessage(responseMessages.linkClickMessage);
        } else if (response.payload.message === "Incorrect service ID") {
          setMessage(responseMessages.unavailableServiceDuetoId);
        } else if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorModalVisible(true);
      setQuantity(0);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };

  return (
    <div className="font-FiraSans py-8 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want More Views?
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Add Instagram Views
      </h3>
      <div className="p-3 bg-[#f1dbdb] flex flex-col gap-4 rounded-lg">
        <Link
          to="/ig_reels_views"
          className="text-sm font-semibold sm:text-xs xs:text-xs text-[#303efb]"
        >
          For Reels Views press here
        </Link>
        <Link
          to="/igtv_views"
          className="text-sm sm:text-xs font-semibold xs:text-xs text-[#303efb]"
        >
          For IGTV Views press here
        </Link>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Our views work fine but currently have a start-time of 0-5 minutes due
          to minor overload (instead of instantly).
        </p>
      </div>
      <FormAndButton
        placeholder="Enter your Instagram Username"
        label="Instagram Username"
        onChange={onChange}
        value={username}
        onClick={onClick}
        mediaUrl={media}
        username={username}
        onQuantityChange={onQuantityChange}
      />
      <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-10 flex justify-center">
        <Button
          type="submit"
          title={loading ? "Sending..." : "Add Views"}
          variant="primary"
          size="sm"
          onClick={onSubmit}
          disabled={loading || !isAuthenticated}
        />
      </div>
      <ToastContainer />
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />
      {/* Info Modal */}
      <InfoModal
        visible={infoModalVisible}
        onClose={() => setInfoModalVisible(false)}
        message={message}
      />
    </div>
  );
};

export default InstagramViews;
