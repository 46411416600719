import React, { useEffect, useState } from "react";
import Button from "./Button";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const ManualCommentFormAndButton = ({
  placeholder,
  onChange,
  value,
  label,
  onClick,
  mediaUrl,
  username,
  onCommentChange,
}) => {
  const { loading, message, error } = useSelector(
    (state) => state.instagramService
  );
  const [comments, setComments] = useState({});
  const [selectedMediaLink, setSelectedMediaLink] = useState(null);
  const [links, setLinks] = useState([]);
  const [comment, setComment] = useState([]);

  useEffect(() => {
    if (!mediaUrl) {
      setSelectedMediaLink(null); // Reset selected media link when mediaUrl is cleared
    }
  }, [mediaUrl]);

  // const handleCommentChange = (index, newComment, link) => {
  //   // Update comments state with line breaks and replace '\n' with '|'
  //   const formattedComment = newComment.split("\n").join("|");

  //   setComments((prevComments) => ({
  //     ...prevComments,
  //     [index]: newComment, // Keep the new comment with '\n' for UI
  //   }));

  //   // If link already exists, update the comment, otherwise add it
  //   const isThere = links.find((item) => item === link);
  //   if (isThere) {
  //     const where = links.indexOf(isThere);
  //     comment[where] = formattedComment; // Use the formatted comment with '|'
  //   } else {
  //     setLinks((prev) => [...prev, link]);
  //     setComment((prev) => [...prev, formattedComment]);
  //   }

  //   // Pass the new comment back to the parent
  //   onCommentChange(
  //     index,
  //     { ...comments, [index]: formattedComment },
  //     links,
  //     comment
  //   );
  // };

  const handleCommentChange = (index, newComment, link) => {
    // Format the comment by replacing '\n' with '|' for storing
    const formattedComment = newComment.split("\n").join("|");

    // Update comments state
    setComments((prevComments) => ({
      ...prevComments,
      [index]: newComment, // Keep the new comment with '\n' for UI purposes
    }));

    // Update links and comments arrays
    const isLinkPresent = links.includes(link);
    if (isLinkPresent) {
      const linkIndex = links.indexOf(link);
      comment[linkIndex] = formattedComment; // Update comment at the right link index
    } else {
      setLinks((prevLinks) => [...prevLinks, link]);
      setComment((prevComments) => [...prevComments, formattedComment]);
    }

    // Call onCommentChange to update orderDetails and quantity
    onCommentChange(
      index,
      { ...comments, [index]: formattedComment },
      links,
      comment
    );
  };

  const handleTextChange = (e, index, link) => {
    // Allow the user to type with normal newlines
    const newComment = e.target.value;
    handleCommentChange(index, newComment, link);
  };

  const handlePaste = (e, index, link) => {
    e.preventDefault(); // Prevent default paste behavior

    const pastedText = e.clipboardData.getData("Text"); // Get pasted text
    const currentText = comments[index] || ""; // Get current text for that index

    // Append pasted text to the current text
    const newComment = currentText + pastedText;

    // Pass the updated comment to handleCommentChange
    handleCommentChange(index, newComment, link);
  };

  return (
    <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 py-4">
      <div className="flex gap-6 lg:gap-4 xl:items-end lg:items-end md:items-end md:gap-2 sm:gap-2 sm:flex-col xs:gap-2 xs:flex-col">
        <div className="flex flex-col gap-1 flex-grow">
          <label className="block font-FiraSans text-lg">{label}</label>
          <input
            type="text"
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
          />
        </div>
        <div className="sm:flex sm:justify-center xs:flex xs:justify-center">
          <Button
            type="button"
            title={loading ? "Loading media..." : "Load Media"}
            variant="primary"
            size="sm"
            onClick={onClick}
            disabled={!username || loading}
          />
        </div>
      </div>
      {loading ? message : ""}
      {error ? toast.error(message) : ""}
      {mediaUrl && (
        <div>
          <div className="mt-4 grid grid-cols-4 gap-x-4 gap-y-4">
            {mediaUrl.map((url, index) => (
              <div key={index} className="">
                <img
                  src={url.image}
                  alt={`Media ${index}`}
                  className={`w-full max-w-xs mx-auto ${
                    selectedMediaLink === url.link ? "opacity-20" : ""
                  }`}
                />
                <div className="mt-3">
                  <textarea
                    rows="10"
                    className="resize-none shadow-md bg-white w-full p-2 outline-none rounded-md"
                    placeholder="Write your comment..."
                    onChange={(e) => handleTextChange(e, index, url.link)}
                    onPaste={(e) => handlePaste(e, index, url.link)} // Track paste events
                    value={comments[index] || ""}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ManualCommentFormAndButton;
