import React from "react";
import FormAndButton from "../../components/shared/FormAndButton";
import Button from "../../components/shared/Button";
import { Link } from "react-router-dom";
import Impressions from "../../components/shared/Impressions";

const InstagramViewsPlusImpression = () => {
  return (
    <div className="font-FiraSans py-8 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want More Views Plus Impressions?
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Add Instagram Views Plus Impressions
      </h3>
      <div className="p-3 bg-[#f1dbdb] flex flex-col gap-4 rounded-lg">
        <Link
          to="/ig_reels_views"
          className="text-sm font-semibold sm:text-xs xs:text-xs text-[#303efb]"
        >
          For Reels Views press here
        </Link>
        <Link
          to="/igtv_views"
          className="text-sm sm:text-xs font-semibold xs:text-xs text-[#303efb]"
        >
          For IGTV Views press here
        </Link>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Our views work fine but currently have a start-time of 0-5 minutes due
          to minor overload (instead of instantly).
        </p>
      </div>
      <form>
        <FormAndButton
          placeholder="Enter your Instagram Username"
          label="Instagram Username"
        />
        <Impressions />
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-10 flex justify-center">
          <Button title="Send Views" variant="primary" size="sm" />
        </div>
      </form>
    </div>
  );
};

export default InstagramViewsPlusImpression;
