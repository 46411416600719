import React, { useEffect, useState } from "react";
import Button from "../../components/shared/Button";
import TwoRowForm from "../../components/shared/TwoRowForm";
import {
  ErrorModal,
  InfoModal,
  SuccessModal,
} from "../../components/shared/ToastModals";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";

const Mention = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const [loading, setLoading] = useState(false);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [data, setData] = useState({
    link: [],
    quantity: [],
    userToTag: "",
    service_type: window.location.pathname,
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [message, setMessage] = useState("");

  const { link, quantity, userToTag } = data;
  const onChange = (e) => {
    let updated = {};
    if (e.target.name === "userToTag") {
      updated = { [e.target.name]: e.target.value };
    } else {
      updated = { [e.target.name]: [e.target.value] };
    }
    setData((prevData) => ({
      ...prevData,
      ...updated,
    }));
    const value = { ...data, ...updated };
    const { quantity } = value;
    setQuantity(quantity);
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    if (quantity < 100) {
      setInfoModalVisible(true);
      setMessage(responseMessages.minimumQuantityOf100);
      return;
    }
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(data));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setData({
          link: [],
          quantity: [],
          userToTag: "",
          service_type: window.location.pathname,
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };
  return (
    <div className="font-FiraSans py-8 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want More Mention?
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Add Mentions
      </h3>
      <div className="p-3 bg-blue-200 text-blue-500 rounded-lg">
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          This is the most effective tool to get instant targeted attention.
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Get those targeted audience now! Mentions are used to tag the
          followers of a certain user on one of your posts. (In the comment
          sections).
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Delivery Time: Few hours depending on the quantity.
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          How to order
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          You need to provide us the post link you want us to provide mention
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Username to tag must be a celebrity/ Big Brand or users with large
          account
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          (we make a list of the username followers and tag them in your post)
        </p>
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          if you order 1000 quantity of mentions, we will tag e.g 1000 followers
          of the user you provided on you post in the comments section
        </p>
      </div>
      <TwoRowForm
        labelOne="Instagram Post Link"
        placeholderOne="Link to instagram post"
        labelTwo="Quantity :"
        onChangeOne={onChange}
        valueOne={link}
        nameOne="link"
        onChangeTwo={onChange}
        valueTwo={quantity}
        nameTwo="quantity"
      />
      <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-2">
        <label className="font-FiraSans block text-lg">
          User for tag followers
        </label>
        <input
          type="text"
          placeholder="Instagram username"
          value={userToTag}
          onChange={onChange}
          name="userToTag"
          className="w-full py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
        />
      </div>
      <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-10 flex justify-center">
        <Button
          type="submit"
          title={loading ? "Submitting..." : "Submit"}
          variant="primary"
          size="sm"
          onClick={onSubmit}
          disabled={
            !link || !userToTag || !quantity || loading || !isAuthenticated
          }
        />
      </div>
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />
      {/* Info Modal */}
      <InfoModal
        visible={infoModalVisible}
        onClose={() => setInfoModalVisible(false)}
        message={message}
      />
    </div>
  );
};

export default Mention;
