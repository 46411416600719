import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TbCircleXFilled } from "react-icons/tb";
import Button from "../../../../components/shared/Button";
const StripeCancel = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(-1);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [navigate]);

  return (
    <div className="flex gap-3 justify-center my-28 items-center font-FiraSans px-3 md:px-2 sm:px-1 xs:px-1 sm:my-48 xs:my-48">
      <div className="shadow-lg rounded-sm p-5 flex flex-col gap-6 items-center bg-yellow-500 text-white">
        <TbCircleXFilled size={60} className="text-red-700" />
        <h4>Oops! Your payment have encountered an error.</h4>
        <p>
          We appreciate your business! If you have any complains, please email
          us at
          <a href="mailto:" className="decoration-0 text-blue-400"></a>.
        </p>
        <Link to="/">
          <Button title="Go to home" />
        </Link>
      </div>
    </div>
  );
};

export default StripeCancel;
