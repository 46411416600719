import React, { useEffect } from "react";
import Button from "../../components/shared/Button";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import { ErrorModal, SuccessModal } from "../../components/shared/ToastModals";
import { useOutletContext } from "react-router-dom";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";

const TiktokComment = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [data, setData] = useState({
    link: [],
    quantity: [],
    service_type: window.location.pathname,
  });
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { link, quantity } = data;

  const onChange = (e) => {
    const updated = { [e.target.name]: [e.target.value] };
    setData({ ...data, ...updated });
    const value = { ...data, ...updated };
    const { quantity } = value;
    setQuantity(quantity / 10);
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData([]);
    setAccordionHeading("");
    setReviews([]);
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(data));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setData({
          link: [],
          quantity: [],
          service_type: window.location.pathname,
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };
  return (
    <div className="font-FiraSans py-4 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Want More TikTok Comments?
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Add TikTok Comments
      </h3>
      <div className="p-3 bg-blue-200 text-blue-500 flex flex-col gap-8 rounded-lg">
        <div>
          <p className="text-sm sm:text-xs xs:text-xs">
            Experience the power of our exclusive real comments for TikTok,
            delivered within 4 hours.
          </p>
          <p className="text-sm sm:text-xs xs:text-xs">
            Stand out from the competition with custom, post-specific comments
            that engage your audience like never before.
          </p>
          <p className="text-sm sm:text-xs xs:text-xs">
            Don't settle for generic comments – unlock the secret to viral
            success on TikTok today.
          </p>
        </div>
        <h4 className="text-sm sm:text-xs xs:text-xs pb-5">
          ENTER YOUR USERNAME AND SELECT THE NUMBER OF COMMENTS IN THE DROP DOWN
          MENU
        </h4>
      </div>
      <form onSubmit={onSubmit}>
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-6 pb-3 flex flex-col gap-1">
          <label className="block text-lg font-FiraSans">
            TikTok Post Link
          </label>
          <input
            type="text"
            placeholder="Enter the post link..."
            name="link"
            value={link}
            onChange={onChange}
            className="py-2 px-2 font-FiraSans outline-blue-300 shadow-md ring-transparent border-2 border-gray-400 sm:w-full xs:w-full rounded-md"
          />
        </div>
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1">
          <label className="block">Package:</label>
          <select
            name="quantity"
            onChange={onChange}
            value={quantity}
            className="w-2/3 sm:w-full xs:w-full px-2 py-3 rounded-lg shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
          >
            <option>Select number of comment</option>
            <option value="10">10 Real Comments</option>
            <option value="15">15 Real Comments</option>
            <option value="20">20 Real Comments</option>
            <option value="25">25 Real Comments</option>
            <option value="30">30 Real Comments</option>
            <option value="50">50 Real Comments</option>
            <option value="75">75 Real Comments</option>
            <option value="100">100 Real Comments</option>
          </select>
        </div>
        <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-10 flex justify-center">
          <Button
            title={loading ? "Sending Order..." : "Send Order"}
            variant="primary"
            size="sm"
            disabled={!link || !quantity || loading || !isAuthenticated}
          />
        </div>
      </form>
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />
    </div>
  );
};

export default TiktokComment;
