import React, { useEffect, useState } from "react";
import Table from "../../../../components/shared/Table";
import Button from "../../../../components/shared/Button";
import { ApiService } from "../../../../services/apiService";
import { formatTimestamp } from "../../../../utils";

const UserOrders = ({ user, onCancel }) => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await ApiService.adminindividualUserOrders();

        // Extract orders from the response data
        const userOrders = response.data.data
          .filter((userData) => userData.user_name === user.username)
          .flatMap((userData) =>
            userData.orders.map((order) => ({
              ...order,
              created_at: formatTimestamp(order.created_at),
            }))
          );

        setOrders(userOrders);
      } catch (error) {
        alert("An error occurred while fetching orders. Please try again!");
      }
    };

    fetchOrders();
  }, [user.username]);
  const customStyles = {
    header: {
      style: {
        minHeight: "50px",
        backgroundColor: "white",
        color: "#001F68",
        textAlign: "center",
      },
    },
    pagination: {
      style: {
        display: "flex",
        justifyContent: "center",
        padding: "10px 0",
      },
    },
  };
  const userorders = [
    {
      name: "Service Type",
      selector: (row) => row.service_type,
    },
    { name: "Link/Username Ordered For", selector: (row) => row.link },
    { name: "Order Time", selector: (row) => row.created_at },
    { name: "Order Type", selector: (row) => row.order },
    { name: "Quantity", selector: (row) => row.quantity },
    { name: "Price", selector: (row) => row.price },
    { name: "Social Media", selector: (row) => row.social_media },
  ];
  return (
    <div>
      <h2 className="text-center font-black pb-3 text-[#001f68]">
        Orders for {user.username}
      </h2>
      <Table
        customStyles={customStyles}
        title="User Orders"
        column={userorders}
        data={orders}
      />
      <div className="flex justify-center pt-2">
        <Button onClick={onCancel} title="Back" variant="secondary" />
      </div>
    </div>
  );
};

export default UserOrders;
