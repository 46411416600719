import React, { useEffect, useState } from "react";
import Button from "../../components/shared/Button";
import TwoRowForm from "../../components/shared/TwoRowForm";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetails } from "../../store/Slice/loginSlice";
import { orderSubmission } from "../../store/Slice/submitOrderSlice";
import {
  ErrorModal,
  InfoModal,
  SuccessModal,
} from "../../components/shared/ToastModals";
import { useOutletContext } from "react-router-dom";
import responseMessages from "../../utils/responseMessages";
import { useAccordionContext } from "../../SEO/AccordionContext";
import {
  autoDailyFollowersSeoAccordion,
  autoDailyFollowersSeoAccordionHeading,
} from "../../SEO/components/pagesAccordion";
import { autoDailyFollowersReview } from "../../SEO/components/customerReviews";

const AutoDailyFollowers = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.user !== null);
  const { setQuantity } = useOutletContext();
  const { setAccordionData, setAccordionHeading, setReviews } =
    useAccordionContext();
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState({
    link: [],
    quantity: [],
    numberOfDays: "",
    peopleCategory: "",
    service_type: window.location.pathname,
  });
  const { link, quantity, numberOfDays, peopleCategory } = data;

  const onChange = (e) => {
    let updated = {};
    if (e.target.name === "numberOfDays") {
      updated = { [e.target.name]: e.target.value };
    } else if (e.target.name === "peopleCategory") {
      updated = { [e.target.name]: e.target.value };
    } else {
      updated = { [e.target.name]: [e.target.value] };
    }
    setData((prevData) => ({
      ...prevData,
      ...updated,
    }));
    const value = { ...data, ...updated };
    const { quantity } = value;
    setQuantity(quantity);
  };
  useEffect(() => {
    setQuantity(0);
    setAccordionData(autoDailyFollowersSeoAccordion);
    setAccordionHeading(autoDailyFollowersSeoAccordionHeading);
    setReviews(autoDailyFollowersReview);
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    if (quantity < 100) {
      setInfoModalVisible(true);
      setMessage(responseMessages.minimumQuantityOf100);
      return;
    }
    try {
      setLoading(true);
      const response = await dispatch(orderSubmission(data));
      if (response.payload.code === 200 || response.payload.code === 201) {
        setQuantity(0);
        setSuccessModalVisible(true);
        setMessage(responseMessages.notAvailableOrderMessage);
        await dispatch(updateUserDetails());
        setData({
          link: [],
          quantity: [],
          numberOfDays: "",
          peopleCategory: "",
          service_type: window.location.pathname,
        });
      } else {
        setQuantity(0);
        setErrorModalVisible(true);
        if (response.payload.message === "insufficient wallet balance") {
          setMessage(responseMessages.insufficientBalance);
        } else {
          setMessage(responseMessages.orderFailure);
        }
      }
      setLoading(false);
    } catch (error) {
      setQuantity(0);
      setLoading(false);
      setErrorModalVisible(true);
      setMessage(responseMessages.errorMessageForCatchBlock);
    }
  };

  return (
    <div className="font-FiraSans py-8 px-20 lg:px-4 md:px-3 sm:px-2 xs:px-1">
      <p className="text-2xl text-center sm:text-lg xs:text-lg pb-5">
        Automatic Instagram Followers – No Login Required!
      </p>
      <h3 className="font-bold pb-9 text-5xl text-center sm:text-2xl xs:text-2xl md:text-3xl">
        Buy Instagram Auto Followers for Consistent Growth
      </h3>
      <div className="p-3 bg-blue-200 text-blue-500 rounded-lg">
        <p className="text-sm sm:text-xs font-semibold xs:text-xs">
          Experience seamless profile growth with our Instagram auto followers
          service. We deliver real, active, and engaging ig followers
          automatically—no login required! Our service is designed to help you
          gain Instagram auto real followers without any hassle or need for
          continuous logins.
        </p>
        <ul className="text-sm sm:text-xs font-semibold xs:text-xs list-disc pl-3 mt-2">
          <li>Real Auto Followers Instagram Users Trust</li>
          <li>Add your Instagram username and total followers</li>
          <li>
            Then, choose for how many days you want your IG followers to be
            delivered
          </li>
          <li>
            Your Auto Instagram Followers will be delivered Consistently and
            Securely
          </li>
        </ul>
      </div>
      <TwoRowForm
        labelOne="Instagram Username"
        labelTwo="Total Followers"
        placeholderOne="Enter your instagram username"
        placeholderTwo="Total Followers"
        onChangeOne={onChange}
        valueOne={link}
        nameOne="link"
        onChangeTwo={onChange}
        valueTwo={quantity}
        nameTwo="quantity"
      />
      <div className="flex flex-col gap-1 px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1">
        <label className="font-FiraSans text-lg">Number of Days</label>
        <select
          id="numberOfDays"
          value={numberOfDays}
          onChange={onChange}
          name="numberOfDays"
          className="w-full px-2 py-3 rounded-md shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
        >
          <option>Select the number of days you want to add followers</option>
          {Array.from({ length: 50 }, (_, index) => index + 1).map((count) => (
            <option key={count} value={count}>
              {count} - ({count} day{count !== 1 ? "s" : ""})
            </option>
          ))}
        </select>
      </div>
      <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-8">
        <label className="block font-FiraSans text-lg">Female / Male:</label>
        <select
          id="peopleCategory"
          name="peopleCategory"
          value={peopleCategory}
          onChange={onChange}
          className="px-2 w-full py-3 rounded-lg shadow-md outline-blue-300 border-2 border-gray-400 text-gray-600"
        >
          <option value="" disabled selected>
            Select gender
          </option>
          <option value="mixed">Mixed</option>
          <option value="Female">Female</option>
          <option value="Male">Male</option>
        </select>
      </div>
      <div className="px-16 lg:px-4 md:px-3 sm:px-2 xs:px-1 pt-10 flex justify-center">
        <Button
          type="submit"
          title={loading ? "Sending" : "Order"}
          variant="primary"
          size="sm"
          disabled={
            !link || !quantity || !numberOfDays || loading || !isAuthenticated
          }
          onClick={onSubmit}
        />
      </div>
      {/* Success Modal */}
      <SuccessModal
        visible={successModalVisible}
        onClose={() => setSuccessModalVisible(false)}
        message={message}
      />
      {/* Error Modal */}
      <ErrorModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        message={message}
      />
      {/* Info Modal */}
      <InfoModal
        visible={infoModalVisible}
        onClose={() => setInfoModalVisible(false)}
        message={message}
      />
    </div>
  );
};

export default AutoDailyFollowers;
